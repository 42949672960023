import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { useQuery } from "@shane32/graphql";
import { SalesDbGraphQLClient } from "../../..";

const SalesProjectionBySkuQuery = `
  query SaleProjection($sku: String!) {
    saleProjection(sku: $sku) {
      projections
    }
  }
`;

const StockQuery = `
    query($sku: String!) {
        product (sku: $sku) {
            warehouseProducts {
                stockOnHand
                allocated
                onOrder
                available
                warehouse {
                    id
                    name
                }
            }
        }
    }
`;

const LastYearSalesQuery = `
    mutation ($params: Complex) {
        api {
            importExport {
                export {
                    report(name: "Daily Sales Report for Sku", parameters: $params) {
                        data
                    }
                }
            }
        }
    }
`;

interface ISalesProjectionQueryResult {
    saleProjection: {
        projections: string;
    };
}

interface IProductWarehouseQueryResult {
    product: {
        warehouseProducts: [
            {
                stockOnHand: number;
                allocated: number;
                onOrder: number;
                available: number;
                warehouse: {
                    id: string;
                    name: string;
                };
            }
        ];
    };
}

interface ILastYearSalesResult {
    api: {
        importExport: {
            export: {
                report: {
                    data: {
                        Table: [
                            {
                                SaleDate: string;
                                NumberOfSales: number;
                            }
                        ];
                    };
                };
            };
        };
    };
}

const SalesProjectionIndex = () => {
    const [sku, setSku] = useState<string>("LGT100BL-A&I-A&I");
    const [leadTime, setLeadTime] = useState<number>(0);
    const [projection, setProjection] = useState<number>(90);
    const [stockInfo, setStockInfo] = useState<string>("");
    const [salesInfo, setSalesInfo] = useState<string>("");
    const [firstOrder, setFirstOrder] = useState<number>(0);
    const [orderleadtime, setOrderleadtime] = useState<number>(10);
    const [orderquantity, setOrderquantity] = useState<number>(20);
    const [orderfrequency, setOrderFrequency] = useState<number>(0);
    const [yAxisScale, setYAxisScale] = useState<number>(1.5);

    const currentDate = new Date();
    const lastyear = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());

    const { data: projections } = useQuery<ISalesProjectionQueryResult>(SalesProjectionBySkuQuery, {
        variables: { sku },
        client: SalesDbGraphQLClient,
        fetchPolicy: "no-cache",
    });

    const { data: stock } = useQuery<IProductWarehouseQueryResult>(StockQuery, {
        variables: { sku },
    });

    const { data: lastyearsales } = useQuery<ILastYearSalesResult>(LastYearSalesQuery, {
        variables: {
            params: {
                startingDate: lastyear.toLocaleDateString(),
                endingDate: currentDate.toLocaleDateString(),
                sku: sku,
            },
        },
    });

    const projectionsArrayString: string[] = projections?.saleProjection?.projections?.split(",") || [];
    const data: number[] = projectionsArrayString.map((str) => parseFloat(str));

    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const width = 800;
        const height = 400;
        const margin = { top: 20, right: 50, bottom: 30, left: 50 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current).attr("width", width).attr("height", height);
        svg.selectAll("*").remove();

        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0, ${innerHeight + margin.top})`);

        svg.append("g").attr("class", "y-axis-left").attr("transform", `translate(${margin.left}, 0)`);

        svg.append("g")
            .attr("class", "y-axis-right")
            .attr("transform", `translate(${innerWidth + margin.left}, 0)`);

        svg.append("path").attr("class", "line-projected");
        svg.append("path").attr("class", "line-sales");
        svg.append("path").attr("class", "area-highlighted");
        svg.append("path").attr("class", "line-stock");
        svg.append("path").attr("class", "line-stock-simulated");
    }, []);

    useEffect(() => {
        if (!projections || !stock || !lastyearsales) return;

        const svg = d3.select(svgRef.current);
        const dateData: Date[] = [new Date()];
        const width = 800;
        const height = 400;
        const margin = { top: 20, right: 50, bottom: 30, left: 50 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        const formatDate = (d: Date): string => {
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        };

        function getNumberOfSales(date: Date) {
            const formattedDate = formatDate(date);
            const sale = lastyearsales?.api.importExport.export.report.data.Table.find((item) => item.SaleDate === formattedDate);
            return sale ? sale.NumberOfSales : 0;
        }

        const salesData: [Date, number][] = [];
        const now = new Date();
        let dateIterator = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        for (let i = 1; i < data.length; i++) {
            const nextDate = new Date();
            nextDate.setDate(now.getDate() + i);
            salesData.push([nextDate, getNumberOfSales(dateIterator)]);
            dateIterator.setDate(dateIterator.getDate() + 1);
        }

        const maxSale = d3.max(salesData.map((d) => d[1])) || 0;
        const maxProjectedSale = d3.max(data) || 0;

        for (let i = 1; i < data.length; i++) {
            const nextDate = new Date();
            nextDate.setDate(now.getDate() + i);
            dateData.push(nextDate);
        }

        const xScale = d3
            .scaleTime()
            .domain(d3.extent(dateData) as [Date, Date])
            .range([margin.left, innerWidth + margin.left]);

        const yScale = d3
            .scaleLinear()
            .domain([0, Math.max(maxSale, maxProjectedSale) * yAxisScale] as [number, number])
            .range([innerHeight + margin.top, margin.top]);

        let remainingStock = 0;
        var warehouseMissant;

        if (stock?.product != null) {
            warehouseMissant = stock.product.warehouseProducts.find((warehouse) => warehouse.warehouse.name === "Zbox Missant");
        }
        if (warehouseMissant) {
            remainingStock = warehouseMissant.stockOnHand - warehouseMissant.allocated;
        }

        let dateStockZero = new Date();
        let stockLastsForDays = 0;
        let orderRemainingStock = remainingStock;
        let skip = firstOrder;
        let frequency = orderfrequency;
        let ordersPlaced: number[] = [];

        const stockData: [Date, number][] = [];
        const stockDataSimulated: [Date, number][] = [];

        for (let i = 0; i < data.length; i++) {
            const stockValue = remainingStock > 0 ? remainingStock : 0;
            stockData.push([dateData[i], stockValue]);
            remainingStock -= data[i];
            if (remainingStock >= 0) {
                stockLastsForDays++;
                dateStockZero.setDate(dateStockZero.getDate() + 1);
            }

            const orderStockValue = orderRemainingStock > 0 ? orderRemainingStock : 0;
            orderRemainingStock -= data[i];
            stockDataSimulated.push([dateData[i], orderStockValue]);

            if (skip > 0) {
                skip -= 1;
                continue;
            }

            if (ordersPlaced.length > 0) {
                for (let order = 0; order < ordersPlaced.length; order++) {
                    if (ordersPlaced[order]-- === 0) {
                        orderRemainingStock += orderquantity;
                    }
                }
            }

            if (frequency > 0 && i % frequency === 0) {
                ordersPlaced.push(orderleadtime);
            }
        }

        const maxStock = d3.max(stockData.map((d) => d[1])) || 0;

        const yScaleStock = d3
            .scaleLinear()
            .domain([0, maxStock * yAxisScale])
            .range([innerHeight + margin.top, margin.top]);

        svg.select(".x-axis")
            .call(d3.axisBottom(xScale).ticks(5) as any)
            .selectAll("text")
            .style("font-size", "12px");
        svg.select(".y-axis-left")
            .call(d3.axisLeft(yScale) as any)
            .selectAll("text")
            .style("font-size", "12px");
        svg.select(".y-axis-right")
            .call(d3.axisRight(yScaleStock) as any)
            .selectAll("text")
            .style("font-size", "12px");

        const line = d3
            .line<[Date, number]>()
            .x((d) => xScale(d[0]) || 0)
            .y((d) => yScale(d[1]) || 0);

        const lineStock = d3
            .line<[Date, number]>()
            .x((d) => xScale(d[0]) || 0)
            .y((d) => yScaleStock(d[1]) || 0);

        const areaGenerator = d3
            .area<[Date, number]>()
            .x((d) => xScale(d[0]) || 0)
            .y0(innerHeight + margin.top)
            .y1((d) => yScale(d[1]) || 0);

        const dataWithDates: [Date, number][] = dateData.map((date, i) => [date, data[i]]);

        svg.select(".line-projected")
            .datum(dataWithDates)
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 2)
            .attr("d", line);

        svg.select(".line-sales").datum(salesData).attr("fill", "none").attr("stroke", "lightgrey").attr("stroke-width", 1).attr("d", line);

        const leadTimeDate = new Date();
        leadTimeDate.setDate(leadTimeDate.getDate() + leadTime);

        const projectionDate = new Date();
        projectionDate.setDate(projectionDate.getDate() + projection);
        const highlightedData = dataWithDates.filter((d) => d[0] >= leadTimeDate && d[0] <= projectionDate);
        const totalAreaUnderOrange = d3.sum(highlightedData.map((d) => d[1]));

        svg.select(".area-highlighted").datum(highlightedData).attr("fill", "orange").attr("fill-opacity", 0.5).attr("d", areaGenerator);

        svg.select(".line-stock").datum(stockData).attr("fill", "none").attr("stroke", "red").attr("stroke-width", 2).attr("d", lineStock);

        svg.select(".line-stock-simulated")
            .datum(stockDataSimulated)
            .attr("fill", "none")
            .attr("stroke", "blue")
            .attr("stroke-width", 2)
            .attr("d", lineStock);

        setStockInfo(
            `Current stock will last ${stockLastsForDays} days. You will run out of stock on ${dateStockZero.toLocaleDateString()}`
        );
        setSalesInfo(
            `${Math.round(totalAreaUnderOrange)} sales from ${leadTimeDate.toLocaleDateString()} to ${projectionDate.toLocaleDateString()}`
        );
    }, [
        data,
        stock,
        projections,
        leadTime,
        projection,
        firstOrder,
        orderleadtime,
        orderquantity,
        orderfrequency,
        yAxisScale,
        lastyearsales,
    ]);

    const handleLeadTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setLeadTime(0);
        } else if (value >= 0) {
            setLeadTime(value);
        }
    };

    const handleProjectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setProjection(0);
        } else if (value >= 0) {
            setProjection(value);
        }
    };

    const handleSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSku(event.target.value);
    };

    const handleOrderLeadTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setOrderleadtime(0);
        } else if (value >= 0) {
            setOrderleadtime(value);
        }
    };

    const handleOrderQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setOrderquantity(0);
        } else if (value >= 0) {
            setOrderquantity(value);
        }
    };

    const handleOrderFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setOrderFrequency(0);
        } else if (value >= 0) {
            setOrderFrequency(value);
        }
    };

    const handleFirstOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setFirstOrder(0);
        } else if (value >= 0) {
            setFirstOrder(value);
        }
    };

    return (
        <div>
            <h4>Sku Sales Projection</h4>
            <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: 10 }}>
                <h5>Input</h5>
                <label htmlFor="sku" style={{ padding: "0px 5px" }}>
                    SKU{" "}
                </label>
                <input type="text" id="sku" name="sku" value={sku} onChange={handleSkuChange} />
                <label htmlFor="leadTime" style={{ padding: "0px 5px" }}>
                    Lead Time
                </label>
                <input type="number" id="leadTime" name="leadTime" value={leadTime} onChange={handleLeadTimeChange} />
                <label htmlFor="projection" style={{ padding: "0px 5px" }}>
                    Projection{" "}
                </label>
                <input type="number" id="projection" name="projection" value={projection} onChange={handleProjectionChange} />
            </div>

            <svg ref={svgRef}></svg>
            <input
                type="range"
                min="1"
                max="5"
                step="0.1"
                value={yAxisScale}
                onChange={(e) => setYAxisScale(parseFloat(e.target.value))}
                style={{ width: "50%" }}
            />
            {projections && (
                <>
                    <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: 10 }}>
                        <h5>Sale Projection</h5>
                        <p>{salesInfo}</p>
                    </div>
                    <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: 10 }}>
                        <h5>Stock Projection</h5>
                        <p>{stockInfo}</p>
                    </div>
                    <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: 10 }}>
                        <h5>Restock Simulation</h5>
                        <label htmlFor="firstorder" style={{ padding: "0px 5px" }}>
                            When to place order?
                        </label>
                        <input
                            type="number"
                            id="firstorder"
                            name="firstorder"
                            style={{ width: "50px", border: "none", textAlign: "center" }}
                            value={firstOrder}
                            onChange={handleFirstOrder}
                        />{" "}
                        days
                        <br />
                        <label htmlFor="orderleadtime" style={{ padding: "0px 5px" }}>
                            What lead time? (days)
                        </label>
                        <input
                            type="number"
                            id="orderleadtime"
                            name="orderleadtime"
                            style={{ width: "50px", border: "none", textAlign: "center" }}
                            value={orderleadtime}
                            onChange={handleOrderLeadTime}
                        />{" "}
                        days
                        <br />
                        <label htmlFor="projection" style={{ padding: "0px 5px" }}>
                            How many?{" "}
                        </label>
                        <input
                            type="number"
                            id="orderquantity"
                            name="orderquantity"
                            style={{ width: "50px", border: "none", textAlign: "center" }}
                            value={orderquantity}
                            onChange={handleOrderQuantity}
                        />{" "}
                        items
                        <br />
                        <label htmlFor="projection" style={{ padding: "0px 5px" }}>
                            At what interval?{" "}
                        </label>
                        <input
                            type="number"
                            id="orderquantity"
                            name="orderquantity"
                            style={{ width: "50px", border: "none", textAlign: "center" }}
                            value={orderfrequency}
                            onChange={handleOrderFrequency}
                        />{" "}
                        days
                    </div>
                </>
            )}
        </div>
    );
};

export default SalesProjectionIndex;
