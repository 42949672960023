import { useQuery } from "@shane32/graphql";

const settingsQuery = `
query($name: String){
    setting(name: $name){
        name
        value
    }
}
`;
interface ISettingsQueryResponse {
    setting: {
        name: string;
        value: string | null;
    };
}

/** Custom hook used to fetch an individual settings value. Returns the first matching value. */
export const useSettingsNumberValue = (settingName: string) => {
    const res = useSettingsValue(settingName ?? "");
    return {
        ...res,
        settingsValue: res.settingsValue ? Number(res.settingsValue) : null,
    } as const;
};

export const useSettingsValue = (settingName: string) => {
    const { data, error, loading, refetch } = useQuery<ISettingsQueryResponse>(settingsQuery, {
        variables: { name: settingName },
        fetchPolicy: "no-cache",
    });

    return {
        settingsValue: data?.setting?.value,
        settingRecord: data?.setting,
        error: error,
        loading: loading,
        refetch: refetch,
    } as const;
};
