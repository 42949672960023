import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { AuthContext } from "@zboxglobal/zboxauth";
import useQueryString from "../../hooks/useQueryString";
//import MessageContext from '../../contexts/MessageContext';

const SignIn = () => {
    const authContext = React.useContext(AuthContext);
    const [pleaseWait, setPleaseWait] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [rememberMe, setRememberMe] = React.useState<boolean>(false);
    const history = useHistory();
    const queryString = useQueryString();
    let returnPath = queryString.getString("p") || "";
    if (!returnPath.startsWith("/")) returnPath = "/";
    //const messageContext = React.useContext(MessageContext);
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setMessage] = React.useState("");

    const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPleaseWait(true);
        authContext.client.Login(email, password, rememberMe).then(
            () => {
                setPleaseWait(false);
                history.push(returnPath);
            },
            () => {
                //messageContext.display('Error signing in', 'Email and Password do not match; please try again.');
                setMessage("Email and Password do not match; please try again.");
                handleShow();
                setPleaseWait(false);
            }
        );
    };

    return (
        <>
            <Row className="mt-lg-4">
                <Col md={{ offset: 3, span: 6 }} xl={{ offset: 4, span: 4 }}>
                    <Card border="primary">
                        <Form onSubmit={onLogin}>
                            <Card.Header className="bg-primary text-white">
                                <h4 className="mb-1">Sign In</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.currentTarget.value);
                                        }}
                                        disabled={pleaseWait}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.currentTarget.value);
                                        }}
                                        disabled={pleaseWait}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-1" controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Remember Me"
                                        checked={rememberMe}
                                        value="true"
                                        onChange={(e) => {
                                            setRememberMe(e.currentTarget.checked);
                                        }}
                                        disabled={pleaseWait}
                                    />
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" type="submit" disabled={pleaseWait}>
                                    Sign In
                                </Button>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    disabled={pleaseWait}
                                    onClick={() => {
                                        authContext.client.InteractiveLogin(returnPath);
                                    }}
                                    style={{ float: "right" }}
                                >
                                    Use interactive login
                                </Button>
                            </Card.Footer>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error signing in</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SignIn;
