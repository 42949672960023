import * as React from "react";

const useTimer = (handler?: () => void, initialTime?: number, repeat?: boolean) => {
    // ensure that the callback always can run the current handler
    const handlerRef = React.useRef<(() => void) | undefined>(handler);
    handlerRef.current = handler;
    // store the timer id
    const timerId = React.useRef<{ interval: boolean; id: number } | null>(null);
    // set up the timer once
    const initialized = React.useRef<boolean>(false);
    if (!initialized.current) {
        initialized.current = true;
        updateHandler(
            timerId,
            () => {
                if (handlerRef.current) handlerRef.current();
            },
            initialTime,
            repeat
        );
    }
    // set up the cleanup code
    React.useEffect(() => {
        // run this on unload
        return () => updateHandler(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // return an boolean indicating if the timer is running, and a stable callback to set the timer interval
    return [
        timerId.current !== null,
        React.useCallback((time?: number, repeat?: boolean) => {
            updateHandler(
                timerId,
                () => {
                    if (handlerRef.current) handlerRef.current();
                },
                time,
                repeat
            );
        }, []),
    ] as [boolean, (time?: number, repeat?: boolean, newHandler?: () => void) => void];
};

function updateHandler(
    timerId: React.MutableRefObject<{ interval: boolean; id: number } | null>,
    newHandler?: () => void,
    time?: number,
    repeat?: boolean
) {
    if (timerId.current !== null) {
        if (timerId.current.interval) clearInterval(timerId.current.id);
        else clearTimeout(timerId.current.id);
        timerId.current = null;
    }
    if (newHandler && time && time > 0) {
        if (repeat) {
            timerId.current = {
                interval: true,
                id: window.setInterval(newHandler, time),
            };
        } else {
            timerId.current = {
                interval: false,
                id: window.setTimeout(newHandler, time),
            };
        }
    }
}

export default useTimer;
