import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { VArray, VButton, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { XButton } from "../../../components/misc/XButton";
import PurchaseOrderStatus from "../../../enums/PurchaseOrderStatus";
import StateCode from "../../../enums/StatesCode";
import { useMutation, useQuery } from "@shane32/graphql";
import FormatDate from "../../../helpers/FormatDate";
import Rounding from "../../../helpers/Rounding";
import StringHelper from "../../../helpers/StringHelper";

interface IPurchaseOrderQueryResult {
    purchaseOrder: IPurchaseOrder;
    suppliers: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            contacts: Array<{
                id: string;
                name: string;
            }>;
            addresses: Array<{
                sortOrder: number;
                address: {
                    id: string;
                    city: string | null;
                    state: string | null;
                    countryId: string | null;
                };
            }>;
        }>;
    };
    warehouses: {
        items: Array<{
            id: string;
            name: string;
            addressId: string;
            active: string;
        }>;
    };
    unitOfMeasures: {
        items: Array<{
            id: string;
            abbreviation: string;
        }>;
    };
}

interface ILineItem {
    id: string;
    description: string;
    isAdditionalCharge: boolean;
    partNumber: string | null;
    product: {
        id: string;
        sku: string;
    } | null;
    quantity: number;
    unitOfMeasureId: string;
    unitPrice: number;
    discount: number;
    total: number;
    comment: string;
    sortOrder: number;
    supplierSku: string | null;
}

interface IPurchaseOrderLineItemModel {
    id: string;
    description: string;
    supplierSku: string;
    partNumber: string;
    isAdditionalCharge: boolean;
    sku: string;
    quantity: string;
    sortOrder: string;
    unitOfMeasureId: string;
    unitPrice: string;
    discount: string;
    total: string;
}

interface IPurchaseOrderLineItemMutation {
    id: string;
    description: string;
    supplierSku: string | null;
    partNumber: string | null;
    isAdditionalCharge: boolean;
    sku: string | null;
    quantity: number;
    unitOfMeasureId: string;
    unitPrice: number;
    discount: number;
    sortOrder: number;
    total: number;
}

interface IPurchaseOrder {
    id: string;
    subtotal: number;
    tax: number;
    total: number;
    blindReceipt: boolean;
    status: PurchaseOrderStatus;
    orderDate: string | null;
    supplierId: string | null;
    supplierContactId: string | null;
    supplierAddressId: string | null;
    billingAddress: {
        id: string;
    };
    shippingAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        description: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        countryId: string | null;
        phone: string | null;
    };
    lineItems: Array<ILineItem>;
    reference: string | null;
    notes: string | null;
    isReturn: boolean;
    isDropShip: boolean;
}

interface IPurchaseOrderModel {
    id: string;
    supplierId: string | null;
    supplierContactId: string | null;
    supplierAddressId: string | null;
    billingAddressId: string;
    shippingAddressId: string;
    reference: string;
    status: PurchaseOrderStatus;
    notes: string;
    firstName: string | null;
    lastName: string | null;
    description: string | null;
    company: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    countryId: string | null;
    phone: string | null;
    subtotal: string;
    total: string;
    orderDate: string | null;
    lineItems: IPurchaseOrderLineItemModel[];
    additionalCharges: IPurchaseOrderLineItemModel[];
    isReturn: boolean;
    isDropShip: boolean;
}

interface IPurchaseOrderMutation {
    id: string;
    subtotal: number;
    tax: number;
    total: number;
    supplierId: string | null;
    blindReceipt: boolean;
    status: string;
    supplierContactId: string | null;
    supplierAddressId: string | null;
    billingAddress: {
        id: string;
    };
    shippingAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        description: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        countryId: string | null;
        phone: string | null;
    };
    lineItems: Array<IPurchaseOrderLineItemMutation>;
    reference: string | null;
    notes: string | null;
    orderDate: string | null;
    isReturn: boolean;
    isDropShip: boolean;
}

interface ICountryQueryResult {
    countries: {
        items: Array<ICountry>;
    };
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

interface IEditResult {
    purchaseOrder: {
        edit: IPurchaseOrder;
    };
}

interface IEditVariables {
    original: IPurchaseOrderMutation;
    modified: IPurchaseOrderMutation;
}
interface IModal {
    show: boolean;
    original?: IPurchaseOrder;
}

const hiddenModal: IModal = {
    show: false,
};

interface IImportResult {
    api: {
        importExport: {
            import: {
                purchaseOrderLineItems: IPurchaseOrder;
            };
        };
    };
}

interface IImportVariables {
    purchaseOrderId: string;
    base64: string;
}

interface IExportResult {
    api: {
        importExport: {
            export: {
                purchaseOrderLineItems: string;
            };
        };
    };
}

interface IExportVariables {
    purchaseOrderId: string;
}

const innerQuery = `
      id
      subtotal
      tax
      total
      status
      blindReceipt
      supplierId
      supplierContactId
      supplierAddressId
      notes
      orderDate
      reference
      lineItems {
        id
        description
        supplierSku
        partNumber
        quantity
        unitOfMeasureId
        unitPrice
        discount
        total
        comment
        sortOrder
        isAdditionalCharge
        product {
            id
            sku
        }
      }
      shippingAddress {
          id
          description
          firstName
          lastName
          company
          address1
          address2
          city
          state
          zip
          phone
          countryId
      }
      billingAddress {
        id
        description
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        countryId
      }
      isReturn
      isDropShip
`;

const ImportCSV = `
mutation ($purchaseOrderId: ID!, $base64: String!) {
  api {
    importExport {
      import {
        purchaseOrderLineItems(purchaseOrderId: $purchaseOrderId, base64: $base64) {
          ${innerQuery}
        }
      }
    }
  }
}
`;

const ExportCSV = `
mutation ($purchaseOrderId: ID!) {
  api {
    importExport {
      export {
        purchaseOrderLineItems(purchaseOrderId: $purchaseOrderId)
      }
    }
  }
}
`;

const PurchaseOrderQuery = `
query ($id: ID!) {
  purchaseOrder(id: $id) {
    ${innerQuery}
  }
  suppliers {
    items {
      id
      name
      active
      contacts {
        id
        name
      }
      addresses {
        sortOrder
        address {
          id
          city
          state
          countryId
        }
      }
    }
  }
  warehouses {
    items {
      id
      name
      addressId
    }
  }
  unitOfMeasures {
    items {
      id
      abbreviation
    }
  }
}
`;

const purchaseOrderMutation = `
mutation ($original: PurchaseOrderInput!, $modified: PurchaseOrderInput!) {
  purchaseOrder {
    edit(original: $original, modified: $modified) {
      ${innerQuery}
    }
  }
}
`;

const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

const PurchaseOrdersEdit = () => {
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<IPurchaseOrderQueryResult, { id: string }>(PurchaseOrderQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<ICountryQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });
    const [saving, setSaving] = React.useState(false);
    const [supplierContactId, setSupplierContactId] = React.useState<string | null>(null);
    const [supplierAddressId, setSupplierAddressId] = React.useState<string | null>(null);
    const [runEdit] = useMutation<IEditResult, IEditVariables>(purchaseOrderMutation);
    const history = useHistory();
    const [supplierId, setSupplierId] = React.useState<string | null>(null);
    const [runExport] = useMutation<IExportResult, IExportVariables>(ExportCSV);
    const [runImport] = useMutation<IImportResult, IImportVariables>(ImportCSV);
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);
    const [inputFile, setInputFile] = React.useState<File | null>(null);
    const [fileError, setFileError] = React.useState<string>("");

    //=== set up local functions ===
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;
    if (!data || !dataCountry) return <Loading />;
    if (!data.purchaseOrder) return <ErrorDisplay onClick={refetch}>PurchaseOrder ID #{id} cannot be found</ErrorDisplay>;

    //run when the add button is pressed
    const onShowModal = () => {
        setModal({ ...hiddenModal, show: true });
    };
    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        setModal(hiddenModal);
        clearModal();
    };

    //clear the modal for the file import
    const clearModal = () => {
        if (inputFileRef && inputFileRef?.current) inputFileRef.current.value = "";
        setInputFile(null);
        setFileError("");
        setSaving(false);
    };

    //convert file to base64
    const convertBase64 = async (file: Blob) => {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                if (fileReader.result) {
                    //remove the metadata at the beginning
                    let encoded = fileReader.result.toString().replace(/^data:(.*,)?/, "");
                    if (encoded.length % 4 > 0) {
                        encoded += "=".repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onChangeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputFile(e.currentTarget.files[0]);
        }
    };

    //send Id to the server and return the base64 String
    const onImport = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputFile) {
            convertBase64(inputFile).then(
                (ret) => {
                    runImport({
                        variables: {
                            purchaseOrderId: id,
                            base64: ret,
                        },
                    }).then(
                        (ret) => {
                            data.purchaseOrder = ret.data?.api.importExport.import.purchaseOrderLineItems;
                            onHideModal();
                            setSaving(false);
                            setInputFile(null);
                        },
                        (err) => {
                            console.error(`Error uploading purchaseOrder`, err);
                            setFileError(err.message);
                            setSaving(false);
                            setInputFile(null);
                        }
                    );
                },
                (err) => {
                    console.log(err.message);
                    setSaving(false);
                    setInputFile(null);
                }
            );
        }
    };

    const onExport = () => {
        runExport({
            variables: {
                purchaseOrderId: id,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.importExport.export.purchaseOrderLineItems;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `ZPO-${id} LineItems`;
                fileLink.click();
            },
            (err) => {
                console.error(`Error trying to download purchaseOrder`, err);
                alert("Error trying to export data");
            }
        );
    };

    const lineItemTotal = (obj: IPurchaseOrderLineItemModel) => {
        let quantityMultiplied = parseFloat(obj.quantity) * parseFloat(obj.unitPrice);
        let discountConverted = quantityMultiplied * (parseFloat(obj.discount ? obj.discount : "0") / 100);
        return quantityMultiplied - discountConverted;
    };

    const total = (obj: IPurchaseOrderModel) => {
        return Rounding.EvenRound(
            obj.lineItems.map((lineItem) => lineItemTotal(lineItem)).reduce((sum, current) => sum + current, 0) +
                obj.additionalCharges.map((lineItem) => lineItemTotal(lineItem)).reduce((sum, current) => sum + current, 0)
        ).toFixed(2);
    };

    const usCountry = dataCountry.countries.items.filter((x) => x.isoCode === "US");
    let usCountryId = null;
    if (usCountry && usCountry.length > 0) usCountryId = usCountry[0].id;

    const purchaseOrder = data.purchaseOrder;
    const originalModel: IPurchaseOrderModel = {
        id: data.purchaseOrder.id,
        supplierId: data.purchaseOrder.supplierId,
        supplierAddressId: data.purchaseOrder.supplierAddressId || "",
        supplierContactId: data.purchaseOrder.supplierContactId || "",
        billingAddressId: data.purchaseOrder.billingAddress.id,
        shippingAddressId: data.purchaseOrder.shippingAddress.id,
        notes: data.purchaseOrder.notes || "",
        orderDate: StringHelper.IsNullOrWhitespace(data.purchaseOrder.orderDate)
            ? null
            : FormatDate.ConvertDateToLocalDatePicker(data.purchaseOrder.orderDate),
        reference: data.purchaseOrder.reference || "",
        firstName: data.purchaseOrder.shippingAddress.firstName || "",
        lastName: data.purchaseOrder.shippingAddress.lastName || "",
        description: data.purchaseOrder.shippingAddress.description || "",
        company: data.purchaseOrder.shippingAddress.company || "",
        address1: data.purchaseOrder.shippingAddress.address1 || "",
        status: data.purchaseOrder.status,
        address2: data.purchaseOrder.shippingAddress.address2 || "",
        city: data.purchaseOrder.shippingAddress.city || "",
        state: data.purchaseOrder.shippingAddress.state || "",
        zip: data.purchaseOrder.shippingAddress.zip || "",
        countryId: data.purchaseOrder.shippingAddress.countryId || usCountryId || "",
        phone: data.purchaseOrder.shippingAddress.phone || "",
        subtotal: data.purchaseOrder.subtotal.toString(),
        total: data.purchaseOrder.total.toString(),
        lineItems: data.purchaseOrder.lineItems
            .filter((x) => !x.isAdditionalCharge)
            .map((lineItem) => ({
                id: lineItem.id,
                description: lineItem.description,
                partNumber: lineItem.partNumber || "",
                isAdditionalCharge: lineItem.isAdditionalCharge,
                sku: lineItem.product?.sku || "",
                quantity: lineItem.quantity.toString(),
                unitOfMeasureId: lineItem.unitOfMeasureId,
                unitPrice: lineItem.unitPrice.toString(),
                discount: lineItem.discount.toString() || "0",
                supplierSku: lineItem.supplierSku || "",
                total: lineItem.total.toString(),
                sortOrder: lineItem.sortOrder.toString(),
            })),
        additionalCharges: data.purchaseOrder.lineItems
            .filter((x) => x.isAdditionalCharge)
            .map((lineItem) => ({
                id: lineItem.id,
                description: lineItem.description,
                partNumber: lineItem.partNumber || "",
                isAdditionalCharge: lineItem.isAdditionalCharge,
                sku: lineItem.product?.sku || "",
                quantity: lineItem.quantity.toString(),
                unitOfMeasureId: lineItem.unitOfMeasureId,
                unitPrice: lineItem.unitPrice.toString(),
                discount: lineItem.discount.toString() || "0",
                supplierSku: lineItem.supplierSku || "",
                total: lineItem.total.toString(),
                sortOrder: lineItem.sortOrder.toString(),
            })),
        isDropShip: data.purchaseOrder.isDropShip,
        isReturn: data.purchaseOrder.isReturn,
    };
    //Filter list of suppliers
    const filteredSupplierList = data.suppliers.items.filter((x) => x.id === (supplierId ?? data.purchaseOrder.supplierId));
    //if (filteredSupplierList.length === 0) return <ErrorDisplay>Could not find supplier with id {supplierId}</ErrorDisplay>;
    const supplier = filteredSupplierList.find((x) => x);
    const onSaveChanges = (modified: IPurchaseOrderModel) => {
        if (modified.status === PurchaseOrderStatus.Void) {
            if (
                !window.confirm(
                    "Are you sure you want to switch the purchase order status to Void? Please consult with Montana before doing so."
                )
            ) {
                return;
            }
        }
        setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: originalModel.id,
                    subtotal: purchaseOrder.subtotal,
                    tax: purchaseOrder.tax,
                    status: purchaseOrder.status,
                    blindReceipt: purchaseOrder.blindReceipt,
                    total: purchaseOrder.total,
                    supplierId: purchaseOrder.supplierId,
                    supplierContactId: purchaseOrder.supplierContactId,
                    supplierAddressId: purchaseOrder.supplierAddressId,
                    reference: purchaseOrder.reference,
                    notes: purchaseOrder.notes,
                    orderDate: purchaseOrder.orderDate,
                    billingAddress: purchaseOrder.billingAddress,
                    shippingAddress: purchaseOrder.shippingAddress,
                    lineItems: purchaseOrder.lineItems.map((lineItem) => ({
                        id: lineItem.id,
                        supplierSku: lineItem.supplierSku,
                        partNumber: lineItem.partNumber,
                        description: lineItem.description ? lineItem.description : "",
                        isAdditionalCharge: lineItem.isAdditionalCharge,
                        sku: lineItem.product?.sku || null,
                        quantity: lineItem.quantity,
                        unitOfMeasureId: lineItem.unitOfMeasureId,
                        unitPrice: lineItem.unitPrice,
                        discount: lineItem.discount,
                        total: lineItem.total,
                        sortOrder: lineItem.sortOrder,
                    })),
                    isDropShip: purchaseOrder.isDropShip,
                    isReturn: purchaseOrder.isReturn,
                },
                modified: {
                    id: purchaseOrder.id,
                    subtotal: parseFloat(total(modified)),
                    tax: purchaseOrder.tax,
                    status: modified.status,
                    blindReceipt: purchaseOrder.blindReceipt,
                    total: parseFloat(total(modified)),
                    supplierId: modified.supplierId,
                    supplierContactId:
                        modified.supplierId !== purchaseOrder.supplierId
                            ? StringHelper.IsNullOrWhitespace(supplierContactId)
                                ? null
                                : supplierContactId
                            : StringHelper.IsNullOrWhitespace(modified.supplierContactId)
                            ? null
                            : modified.supplierContactId,
                    supplierAddressId:
                        modified.supplierId !== purchaseOrder.supplierId
                            ? StringHelper.IsNullOrWhitespace(supplierAddressId)
                                ? null
                                : supplierAddressId
                            : StringHelper.IsNullOrWhitespace(modified.supplierAddressId)
                            ? null
                            : modified.supplierAddressId,
                    reference: StringHelper.IsNullOrWhitespace(modified.reference) ? null : modified.reference,
                    orderDate: StringHelper.IsNullOrWhitespace(modified.orderDate) ? null : FormatDate.ConvertDateToUTC(modified.orderDate),
                    notes: StringHelper.IsNullOrWhitespace(modified.notes) ? null : modified.notes,
                    billingAddress: {
                        id: modified.billingAddressId,
                    },
                    shippingAddress: {
                        id: modified.shippingAddressId,
                        firstName: StringHelper.IsNullOrWhitespace(modified.firstName) ? null : modified.firstName,
                        lastName: StringHelper.IsNullOrWhitespace(modified.lastName) ? null : modified.lastName,
                        description: StringHelper.IsNullOrWhitespace(modified.description) ? null : modified.description,
                        company: StringHelper.IsNullOrWhitespace(modified.company) ? null : modified.company,
                        address1: StringHelper.IsNullOrWhitespace(modified.address1) ? null : modified.address1,
                        address2: StringHelper.IsNullOrWhitespace(modified.address2) ? null : modified.address2,
                        city: StringHelper.IsNullOrWhitespace(modified.city) ? null : modified.city,
                        state: StringHelper.IsNullOrWhitespace(modified.state) ? null : modified.state,
                        zip: StringHelper.IsNullOrWhitespace(modified.zip) ? null : modified.zip,
                        countryId: StringHelper.IsNullOrWhitespace(modified.countryId) ? null : modified.countryId,
                        phone: StringHelper.IsNullOrWhitespace(modified.phone) ? null : modified.phone,
                    },
                    lineItems: [
                        ...modified.lineItems.map((lineItem) => ({
                            id: lineItem.id || "0",
                            supplierSku: lineItem.supplierSku || null,
                            description: lineItem.description ? lineItem.description : "",
                            partNumber: lineItem.partNumber || null,
                            isAdditionalCharge: false,
                            sku: lineItem.sku || null,
                            quantity: parseFloat(lineItem.quantity),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            unitPrice: Rounding.EvenRound(parseFloat(lineItem.unitPrice)),
                            discount: parseFloat(lineItem.discount || "0"),
                            total: lineItemTotal(lineItem),
                            sortOrder: parseFloat(lineItem.sortOrder) || 1,
                        })),
                        ...modified.additionalCharges.map((lineItem) => ({
                            id: lineItem.id || "0",
                            supplierSku: lineItem.supplierSku || null,
                            description: lineItem.description ? lineItem.description : "",
                            partNumber: lineItem.partNumber || null,
                            isAdditionalCharge: true,
                            sku: lineItem.sku || null,
                            quantity: parseFloat(lineItem.quantity),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            unitPrice: Rounding.EvenRound(parseFloat(lineItem.unitPrice)),
                            discount: parseFloat(lineItem.discount || "0"),
                            total: lineItemTotal(lineItem),
                            sortOrder: parseFloat(lineItem.sortOrder) || 1,
                        })),
                    ],
                    isDropShip: purchaseOrder.isDropShip,
                    isReturn: purchaseOrder.isReturn,
                },
            },
        }).then(
            (ret) => {
                if (data && ret.data) {
                    const newValue: IPurchaseOrder = {
                        id: id,
                        billingAddress: ret.data.purchaseOrder.edit.billingAddress,
                        blindReceipt: ret.data.purchaseOrder.edit.blindReceipt,
                        notes: ret.data.purchaseOrder.edit.notes,
                        reference: ret.data.purchaseOrder.edit.reference,
                        status: ret.data.purchaseOrder.edit.status,
                        subtotal: ret.data.purchaseOrder.edit.subtotal,
                        total: ret.data.purchaseOrder.edit.total,
                        shippingAddress: ret.data.purchaseOrder.edit.shippingAddress,
                        supplierId: ret.data.purchaseOrder.edit.supplierId,
                        supplierContactId: ret.data.purchaseOrder.edit.supplierContactId,
                        supplierAddressId: ret.data.purchaseOrder.edit.supplierAddressId,
                        tax: ret.data.purchaseOrder.edit.tax,
                        orderDate: ret.data.purchaseOrder.edit.orderDate,
                        lineItems: [
                            ...ret.data.purchaseOrder.edit.lineItems.map((lineItem) => ({
                                id: lineItem.id,
                                supplierSku: lineItem.supplierSku,
                                description: lineItem.description,
                                partNumber: lineItem.partNumber,
                                isAdditionalCharge: lineItem.isAdditionalCharge,
                                quantity: lineItem.quantity,
                                unitOfMeasureId: lineItem.unitOfMeasureId,
                                product: lineItem.product,
                                unitPrice: lineItem.unitPrice,
                                discount: lineItem.discount,
                                total: lineItem.total,
                                comment: lineItem.comment,
                                sortOrder: lineItem.sortOrder || 1,
                            })),
                        ],
                        isDropShip: ret.data.purchaseOrder.edit.isDropShip,
                        isReturn: ret.data.purchaseOrder.edit.isReturn,
                    };
                    data.purchaseOrder = newValue;
                }
                setSupplierAddressId(null);
                setSupplierContactId(null);
                setSaving(false);
            },
            (err) => {
                setSaving(false);
                console.error("Error editing purchase order", err);
                alert(err.message);
            }
        );
    };

    const onVoid = () => {
        if (!window.confirm("Are you sure you want to void this purchase order?")) return;
        setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: purchaseOrder.id,
                    subtotal: purchaseOrder.subtotal,
                    tax: purchaseOrder.tax,
                    status: purchaseOrder.status,
                    blindReceipt: purchaseOrder.blindReceipt,
                    total: purchaseOrder.total,
                    supplierId: purchaseOrder.supplierId,
                    supplierContactId: purchaseOrder.supplierContactId,
                    supplierAddressId: purchaseOrder.supplierAddressId,
                    reference: purchaseOrder.reference,
                    notes: purchaseOrder.notes,
                    billingAddress: purchaseOrder.billingAddress,
                    shippingAddress: purchaseOrder.shippingAddress,
                    orderDate: purchaseOrder.orderDate,
                    lineItems: [
                        ...originalModel.lineItems.map((lineItem) => ({
                            id: lineItem.id || "0",
                            supplierSku: "",
                            description: "",
                            partNumber: lineItem.partNumber,
                            isAdditionalCharge: false,
                            sku: lineItem.sku,
                            quantity: parseFloat(lineItem.quantity),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            unitPrice: parseFloat(lineItem.unitPrice),
                            discount: parseFloat(lineItem.discount),
                            total: parseFloat(lineItem.total),
                            sortOrder: parseFloat(lineItem.sortOrder),
                        })),
                        ...originalModel.additionalCharges.map((lineItem) => ({
                            id: lineItem.id || "0",
                            supplierSku: "",
                            description: "",
                            partNumber: lineItem.partNumber,
                            isAdditionalCharge: true,
                            sku: lineItem.sku,
                            quantity: parseFloat(lineItem.quantity),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            unitPrice: parseFloat(lineItem.unitPrice),
                            discount: parseFloat(lineItem.discount),
                            total: parseFloat(lineItem.total),
                            sortOrder: parseFloat(lineItem.sortOrder),
                        })),
                    ],
                    isDropShip: purchaseOrder.isDropShip,
                    isReturn: purchaseOrder.isReturn,
                },
                modified: {
                    id: originalModel.id,
                    subtotal: purchaseOrder.subtotal,
                    tax: purchaseOrder.tax,
                    status: "VOID",
                    blindReceipt: purchaseOrder.blindReceipt,
                    total: purchaseOrder.total,
                    supplierId: purchaseOrder.supplierId,
                    supplierContactId: purchaseOrder.supplierContactId,
                    supplierAddressId: purchaseOrder.supplierAddressId,
                    reference: purchaseOrder.reference,
                    notes: purchaseOrder.notes,
                    billingAddress: purchaseOrder.billingAddress,
                    shippingAddress: purchaseOrder.shippingAddress,
                    orderDate: purchaseOrder.orderDate,
                    lineItems: [
                        ...originalModel.lineItems.map((lineItem) => ({
                            id: lineItem.id || "0",
                            supplierSku: "",
                            partNumber: lineItem.partNumber,
                            description: "",
                            isAdditionalCharge: false,
                            sku: lineItem.sku,
                            quantity: parseFloat(lineItem.quantity),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            unitPrice: parseFloat(lineItem.unitPrice),
                            discount: parseFloat(lineItem.discount),
                            total: parseFloat(lineItem.total),
                            sortOrder: parseFloat(lineItem.sortOrder),
                        })),
                        ...originalModel.additionalCharges.map((lineItem) => ({
                            id: lineItem.id || "0",
                            supplierSku: "",
                            partNumber: lineItem.partNumber,
                            description: "",
                            isAdditionalCharge: true,
                            sku: lineItem.sku,
                            quantity: parseFloat(lineItem.quantity),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            unitPrice: parseFloat(lineItem.unitPrice),
                            discount: parseFloat(lineItem.discount),
                            total: parseFloat(lineItem.total),
                            sortOrder: parseFloat(lineItem.sortOrder),
                        })),
                    ],
                    isDropShip: purchaseOrder.isDropShip,
                    isReturn: purchaseOrder.isReturn,
                },
            },
        }).then(
            (ret) => {
                if (data && ret.data) {
                    data.purchaseOrder.status = ret.data.purchaseOrder.edit.status;
                    let path = `/purchaseOrders`;
                    history.push(path);
                    setSaving(false);
                }
            },
            (err) => {
                setSaving(false);
                console.error("Error editing purchase order", err);
                alert(err.message);
            }
        );
    };

    const setSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSupplierId(e.currentTarget.value);
    };

    const purchaseOrderStatus = Object.values(PurchaseOrderStatus).filter((v) => isNaN(Number(v)));
    const states = Object.values<string>(StateCode);
    states.unshift("");

    return (
        <>
            <div>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={data.purchaseOrder.id}>
                    <Row>
                        <Col xxl={8}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    <div>General</div>
                                    <div>
                                        <VSelect fieldName="status">
                                            {purchaseOrderStatus.map((s) => (
                                                <option key={s} value={s}>
                                                    {s}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="supplierId">
                                                <VLabel column sm={4}>
                                                    Supplier
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect onChange={(e) => setSupplier(e)}>
                                                        {data.suppliers.items
                                                            .filter((x) => x.active === true)
                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((supplier) => (
                                                                <option key={supplier.id} value={supplier.id}>
                                                                    {supplier.name}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="supplierContactId">
                                                <VLabel column sm={4}>
                                                    Contact
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect onChange={(e) => setSupplierContactId(e.currentTarget.value)}>
                                                        <option value=""> </option>
                                                        {(supplier?.contacts ?? []).map((contact) => (
                                                            <option key={contact.id} value={contact.id}>
                                                                {contact.name}
                                                            </option>
                                                        )) || null}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="supplierAddressId">
                                                <VLabel column sm={4}>
                                                    Vendor Address
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect onChange={(e) => setSupplierAddressId(e.currentTarget.value)}>
                                                        <option value=""> </option>
                                                        {(supplier?.addresses ?? []).map((address) => {
                                                            const countryName = dataCountry.countries.items.find(
                                                                (x) => x.id === address.address.countryId
                                                            );
                                                            return (
                                                                (
                                                                    <option key={address.address.id} value={address.address.id}>
                                                                        {address.address.city} {address.address.state}{" "}
                                                                        {countryName?.isoCode}
                                                                    </option>
                                                                ) || null
                                                            );
                                                        })}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="orderDate">
                                                <VLabel column sm={4}>
                                                    Order Date
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="date" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="reference">
                                                <VLabel column sm={4}>
                                                    Reference
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingAddressId">
                                                <VLabel column sm={4}>
                                                    Warehouse
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {data.warehouses.items.map((warehouse) => (
                                                            <option key={warehouse.id} value={warehouse.addressId}>
                                                                {warehouse.name}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <VGroup as={Row} className="mb-2" fieldName="notes">
                                                <VLabel column sm={4} lg={2}>
                                                    Notes
                                                </VLabel>
                                                <Col sm={8} lg={10}>
                                                    <VControl type="textarea" rows={3} />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Shipping Address</Card.Header>
                                <Card.Body>
                                    <VGroup as={Row} className="mb-2" fieldName="firstName">
                                        <VLabel column sm={4}>
                                            First Name
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="lastName">
                                        <VLabel column sm={4}>
                                            Last Name
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="company">
                                        <VLabel column sm={4}>
                                            Company
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="address1">
                                        <VLabel column sm={4}>
                                            Address 1
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="address2">
                                        <VLabel column sm={4}>
                                            Address 2
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="city">
                                        <VLabel column sm={4}>
                                            City
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="state">
                                        <VLabel column sm={4}>
                                            State
                                        </VLabel>
                                        <Col sm={8}>
                                            <VSelect>
                                                {states.map((state) => (
                                                    <option key={state} value={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </VSelect>
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="zip">
                                        <VLabel column sm={4}>
                                            Zip
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="phone">
                                        <VLabel column sm={4}>
                                            Phone
                                        </VLabel>
                                        <Col sm={8}>
                                            <VControl type="text" />
                                        </Col>
                                    </VGroup>
                                    <VGroup as={Row} className="mb-2" fieldName="countryId">
                                        <VLabel column sm={4}>
                                            Country
                                        </VLabel>
                                        <Col sm={8}>
                                            <VSelect>
                                                <option value=""></option>
                                                {dataCountry.countries.items
                                                    .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                                    .map((country) => (
                                                        <option key={`PurchaseOrder-${country.id}`} value={country.id}>
                                                            {country.isoCode}
                                                        </option>
                                                    ))}
                                            </VSelect>
                                        </Col>
                                    </VGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    Summary
                                </Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Subtotal</td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        fieldName="subtotal"
                                                        dynamicValue={(obj: IPurchaseOrderModel) => total(obj)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tax</td>
                                                <td>{Rounding.EvenRound(data.purchaseOrder.tax).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Total</b>
                                                </td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        fieldName="total"
                                                        dynamicValue={(obj: IPurchaseOrderModel) => total(obj)}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <p>
                                <VButton variant="primary" className="me-2" type="submit">
                                    Save Changes
                                </VButton>
                            </p>
                            <p>
                                <VButton
                                    type="button"
                                    variant="danger"
                                    className="me-2"
                                    onClick={onVoid}
                                    disabled={data.purchaseOrder.status === "VOID"}
                                >
                                    Void
                                </VButton>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    <div>Products & Services</div>
                                    <div>
                                        <Button variant="success">Authorize</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Products</p>
                                    <div>
                                        <Button variant="outline-secondary" onClick={onShowModal}>
                                            Import
                                        </Button>
                                        <Button variant="outline-secondary" className="mx-3" onClick={onExport}>
                                            Export
                                        </Button>
                                    </div>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Product Sku</th>
                                                <th>Description</th>
                                                <th>Supplier Sku</th>
                                                <th>Quantity</th>
                                                <th>Unit</th>
                                                <th>Price</th>
                                                <th>Discount</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray
                                                fieldName="lineItems"
                                                blankRow={{
                                                    sku: "",
                                                    description: "",
                                                    supplierSku: "",
                                                    quantity: "",
                                                    unitOfMeasureId: "",
                                                    unitPrice: "",
                                                    discount: "",
                                                    total: "",
                                                }}
                                            >
                                                <tr>
                                                    <td>
                                                        <VControl type="text" required fieldName="sku" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="description" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="supplierSku" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" required pattern="^\d+$" fieldName="quantity" />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="unitOfMeasureId" required>
                                                            <option value=""></option>
                                                            {data.unitOfMeasures.items.map((unit) => (
                                                                <option key={unit.id} value={unit.id}>
                                                                    {unit.abbreviation}
                                                                </option>
                                                            ))}
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            required
                                                            pattern="^[0-9]*(\.[0-9]{0,4})?$"
                                                            fieldName="unitPrice"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            pattern="^([0-9][0-9]?|)(\.[0-9]{0,2})?$"
                                                            fieldName="discount"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="total"
                                                            dynamicValue={(obj: IPurchaseOrderLineItemModel) =>
                                                                Rounding.EvenRound(lineItemTotal(obj)).toFixed(2)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <XButton />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                    <p>Additional Charges and Services</p>
                                    <div className="gap-2">
                                        <Button variant="outline-success" className="me-2">
                                            +
                                        </Button>
                                        <Button variant="outline-secondary">Get Shipping Rates</Button>
                                    </div>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Sku</th>
                                                <th>Description</th>
                                                <th>Supplier Sku</th>
                                                <th>Quantity</th>
                                                <th>Unit</th>
                                                <th>Price</th>
                                                <th>Discount</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray
                                                fieldName="additionalCharges"
                                                blankRow={{
                                                    sku: "",
                                                    description: "",
                                                    supplierSku: "",
                                                    quantity: "",
                                                    unitOfMeasureId: "",
                                                    unitPrice: "",
                                                    discount: "",
                                                    total: "",
                                                }}
                                            >
                                                <tr>
                                                    <td>
                                                        <VControl type="text" fieldName="sku" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="description" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="supplierSku" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" required pattern="^\d+$" fieldName="quantity" />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="unitOfMeasureId" required>
                                                            <option value=""></option>
                                                            {data.unitOfMeasures.items.map((unit) => (
                                                                <option key={unit.id} value={unit.id}>
                                                                    {unit.abbreviation}
                                                                </option>
                                                            ))}
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            required
                                                            pattern="^[0-9]*(\.[0-9]{0,4})?$"
                                                            fieldName="unitPrice"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            required
                                                            pattern="^([0-9][0-9]?|)(\.[0-9]{0,2})?$"
                                                            fieldName="discount"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="total"
                                                            dynamicValue={(obj: IPurchaseOrderLineItemModel) =>
                                                                Rounding.EvenRound(lineItemTotal(obj)).toFixed(2)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <XButton />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </VForm>
            </div>
            <Modal show={modal.show} onHide={onHideModal}>
                <form onSubmit={onImport}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="csvFile" className="mb-2">
                            <Form.Label>Import CSV</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xls, .xlsx"
                                required
                                className="primary"
                                onChange={onChangeImportFile}
                                ref={inputFileRef}
                            />
                        </Form.Group>
                        <Button variant="primary" className="mb-2" type="submit" disabled={saving || inputFile === null}>
                            Upload
                        </Button>
                        <Form.Group className="mb-2">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" readOnly value={fileError} rows={5} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={clearModal}>
                            Clear
                        </Button>
                        <Button variant="secondary" onClick={onHideModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default PurchaseOrdersEdit;
