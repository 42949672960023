import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel } from "@shane32/vform";
import React from "react";
import { useMutation, useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";

//define query and mutation models
interface IQueryResult {
    shippingCarrier: IShippingCarrier;
}

interface IShippingCarrier {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    services: Array<IShippingService>;
}

interface IShippingServiceModel {
    id: string;
    name: string;
    sortOrder: string;
    active: boolean;
    dimensionalWeightDivisor: string;
    isReturnService: boolean;
}

interface IShippingService {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    dimensionalWeightDivisor: number | null;
    isReturnService: boolean;
}

interface IEditResult {
    shippingCarrier: {
        edit: IShippingCarrier;
    };
}
interface IEditVariables {
    original: IShippingCarrier;
    modified: IShippingCarrier;
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: IShippingService;
}
const hiddenModal: IModal = {
    show: false,
};

const ShippingCarrierQuery = `
query ($id: ID!) {
    shippingCarrier (id: $id) {
        id
        name
        sortOrder
        active
        services {
            id
            name
            sortOrder
            active
            dimensionalWeightDivisor
            isReturnService
        }
    }
}
`;

const ShippingCarrierMutation = `
mutation ($original: ShippingCarrierInput!, $modified: ShippingCarrierInput!) {
    shippingCarrier {
        edit (original: $original, modified: $modified) {
            id
            name
            sortOrder
            active
            services {
                id
                name 
                sortOrder 
                active
                dimensionalWeightDivisor
                isReturnService
            }
        }
    }
}`;

const ShippingCarrierDetails = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();

    //=== set up queries and mutations ===
    //Shipping Carrier list
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(ShippingCarrierQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(ShippingCarrierMutation);

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;
    if (!data.shippingCarrier) return <ErrorDisplay onClick={refetch}>Shipping Carrier ID #{id}</ErrorDisplay>;

    //=== set up local functions ===
    //run when the edit button is pressed
    const onShowEditModal = (value: IShippingService) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    //=== set up any other variables needed for page render ===
    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IShippingServiceModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              sortOrder: modal.original.sortOrder.toString(),
              active: modal.original.active,
              dimensionalWeightDivisor: modal.original.dimensionalWeightDivisor ? modal.original.dimensionalWeightDivisor + "" : "",
              isReturnService: modal.original.isReturnService,
          }
        : {
              id: "0",
              name: "",
              sortOrder: "",
              active: true,
              dimensionalWeightDivisor: "",
              isReturnService: false,
          };

    const runSave = (original: IShippingService | null, modified: IShippingService | null) => {
        setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: data.shippingCarrier.id,
                    name: data.shippingCarrier.name,
                    sortOrder: data.shippingCarrier.sortOrder,
                    active: data.shippingCarrier.active,
                    services: original ? [original] : [],
                },
                modified: {
                    id: data.shippingCarrier.id,
                    name: data.shippingCarrier.name,
                    sortOrder: data.shippingCarrier.sortOrder,
                    active: data.shippingCarrier.active,
                    services: modified ? [modified] : [],
                },
            },
        }).then(
            (ret) => {
                setSaving(false);
                data.shippingCarrier = ret.data.shippingCarrier.edit;
                setModal(hiddenModal);
            },
            (err) => {
                setSaving(false);
                console.error("Error updating shipping service", err);
                alert(err.message);
            }
        );
    };

    const onSaveChanges = (modified: IShippingServiceModel) => {
        runSave(
            //send original, or null if adding
            modal.original || null,
            //send modified shipping service with original id if editing
            {
                id: modified.id,
                name: modified.name,
                sortOrder: parseFloat(modified.sortOrder),
                active: modified.active,
                dimensionalWeightDivisor: modified.dimensionalWeightDivisor ? parseFloat(modified.dimensionalWeightDivisor) : null,
                isReturnService: modified.isReturnService,
            }
            //do not modify
        );
    };

    const onDelete = () => {
        //=== DELETE ===
        //ensure we are editing
        if (!modal.original) return;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this shipping service?")) return;
        //delete the shipping service
        runSave(
            //send original shipping service
            modal.original,
            //and null to delete it
            null
        );
    };

    const card = (active: boolean) => {
        const shippingServices = data!.shippingCarrier.services
            .filter((service) => service.active === active)
            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name));
        // search data where active or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} Shipping Services</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Shipping Service</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {shippingServices.map((service) => (
                                <tr key={service.id}>
                                    <td>{service.name}</td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditModal(service)}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new shipping service
                </Button>
            </p>

            {/* Active Shipping Services */}
            {card(true)}

            {/* Inactive Shipping Services */}
            {card(false)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Shipping Service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel>Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="dimensionalWeightDivisor">
                                    <VLabel>Dimensional Weight Divisor</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="sortOrder">
                                    <VLabel>Sort Order</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" />
                                </VGroup>
                                <div className="d-flex">
                                    <VGroup className="mb-3" fieldName="isReturnService">
                                        <VCheck label="Return Service" />
                                    </VGroup>
                                    <VGroup className="mb-3 ms-3" fieldName="active">
                                        <VCheck label="Active" />
                                    </VGroup>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default ShippingCarrierDetails;
