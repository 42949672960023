import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
const tzLocal = dayjs.tz.guess();

export default class FormatDate {
    static ConvertStringToDate(dateString: string) {
        const date = new Date(dateString);
        return date;
    }
    static ConvertDateTimeTo12HrLocalDateTime(date: Date | string | null) {
        return dayjs(date).tz(tzLocal).format("MM/DD/YYYY hh:mma");
    }
    static ConvertDateToLocalDateMMDDYYYY(date: Date | string | null) {
        return dayjs(date).tz(tzLocal).format("MM/DD/YYYY");
    }
    static ConvertDateToUTC(date: Date | string | null) {
        return dayjs(date).utc().format();
    }
    static ConvertDateToLocalDatePicker(date: Date | string | null) {
        return dayjs(date).tz(tzLocal).format("YYYY-MM-DD");
    }
    static ConvertToDateType(date: Date | string | null) {
        return dayjs(date).format("YYYY-MM-DD");
    }
    static ConvertDateToDateTimePicker(date: Date | string | null) {
        return dayjs(date).tz(tzLocal).format(`YYYY-MM-DD[T]HH:mm`);
    }
}
