import { GraphQLContext, useMutation, useQuery } from "@shane32/graphql";
import { GridApi, GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { VCheck, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import { useSettingsValue } from "../../hooks/useSettingsValue";
import StringHelper from "../../helpers/StringHelper";

interface IUnitOfMeasureQueryResult {
    unitOfMeasures: {
        items: Array<IUnitOfMeasure>;
    };
}

interface IWarehouseQuery {
    warehouses: {
        items: Array<IWarehouse>;
    };
}

interface ISupplierQuery {
    suppliers: {
        items: Array<ISupplier>;
    };
}

interface IProductModel {
    id: string;
    type: string;
    unitOfMeasureId: string;
    description: string | null;
    dropShipMode: string;
    active: boolean;
    partNumbers: Array<{
        partNumber: string;
        sortOrder: number;
    }> | null;
    sku: string;
    barcodes: Array<{
        barcode: string;
        sortOrder: number;
    }> | null;
    warehouseProducts: Array<{
        warehouseId: string;
    }>;
}

interface IUnitOfMeasure {
    id: string;
    name: string;
}

interface IWarehouse {
    id: string;
    name: string;
}

interface ISupplier {
    id: string;
    name: string;
    active: boolean;
    deleted: boolean;
}

interface IAddVariables {
    value: IProductModel;
}

interface IAddResult {
    product: {
        add: IProductModel;
    };
}

//create a new object for mutation
//must contain all variables in mutation
interface IProductObj {
    id: string;
    type: string;
    unitOfMeasure: string;
    sku: string;
    partNumber: string;
    description: string;
    barcode: string;
    dropShipMode: string;
    active: boolean;
    warehouseId: string;
}

interface IProduct {
    description: string;
    sku: string;
}

interface IProductByWarehouse {
    productId: number;
    product: IProduct;
    warehouse: IWarehouse;
    minimumBeforeReorder: number;
    reorderQuantity: number;
    available: number;
    stockOnHand: number;
    onOrder: number;
    allocated: number;
}

interface IProductsByWarehouse {
    totalCount: number;
    items: Array<IProductByWarehouse>;
}

interface IProductsByWarehouseResult {
    productsByWarehouse: IProductsByWarehouse;
}

//warehouse query
const WarehousesQuery = `
{
  warehouses {
    items {
      id
      name
    }
  }
}`;

//suppliers query
const SuppliersQuery = `
{
    suppliers {
      items {
        id
        name
        active
        deleted
      }
    }
}`;

//mutation query
const ProductMutation = `
mutation ($value: ProductInput!) {
  product {
    add(value: $value) {
      id
      description
      type
      unitOfMeasureId
      dropShipMode
      active
      sku
      partNumbers {
        partNumber
        sortOrder
      }
      barcodes {
        barcode
        sortOrder
      }
      warehouseProducts {
        warehouse {
          id
        }
      }
    }
  }
}`;

const ProductsByWarehouseQuery = `
query ($after: String, $search: String, $supplierId: ID, $warehouseId: ID, $onlyUnassignedProducts: Boolean!, $excludeUnassignedProducts: Boolean!, $sortOrder: ProductsByWarehouseSortOrder!) {
    productsByWarehouse(first: 100, after: $after, search: $search, supplierId: $supplierId, warehouseId: $warehouseId, onlyUnassignedProducts: $onlyUnassignedProducts, excludeUnassignedProducts: $excludeUnassignedProducts, sortOrder: $sortOrder) {
      totalCount
      items {
        productId
        product {
          description
          sku
        }
        warehouse {
          id
          name
        }
        minimumBeforeReorder
        reorderQuantity
        available
        stockOnHand
        onOrder
        allocated
      }
    }
}`;

interface IProductsByWarehouseVariables {
    after: string | null;
    supplierId: string | null;
    warehouseId: string | null;
    search: string | null;
    onlyUnassignedProducts: boolean;
    excludeUnassignedProducts: boolean;
    sortOrder: string;
}

const ProductsIndex = () => {
    //=== set up queries and mutations ===
    //UnitOfMeasures
    const {
        data: unitOfMeasuresData,
        error: unitOfMeasuresError,
        refetch: unitOfMeasuresRefetch,
    } = useQuery<IUnitOfMeasureQueryResult, {}>("{unitOfMeasures { items { id name } }}", { fetchPolicy: "no-cache" });
    //Warehouses
    const {
        data: warehouseData,
        error: warehouseError,
        refetch: warehouseRefetch,
    } = useQuery<IWarehouseQuery, {}>(WarehousesQuery, { fetchPolicy: "no-cache" });

    const {
        data: supplierData,
        error: supplierError,
        refetch: supplierRefetch,
    } = useQuery<ISupplierQuery, {}>(SuppliersQuery, { fetchPolicy: "no-cache" });

    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");

    //=== set up state variables ===
    const [showModal, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [runAdd] = useMutation<IAddResult, IAddVariables>(ProductMutation);
    const [saving, setSaving] = React.useState(false);
    const history = useHistory();

    const locationFilter = React.useRef<string>("all");
    const supplierFilter = React.useRef<string | null>(null);
    const search = React.useRef<string>("");

    //AG Grid
    const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

    //GraphQL handle
    const graphQLContext = React.useContext(GraphQLContext);

    //display message if failed to retrieve data
    if (unitOfMeasuresError) return <ErrorDisplay onClick={unitOfMeasuresRefetch}>{unitOfMeasuresError.message}</ErrorDisplay>;
    if (warehouseError) return <ErrorDisplay onClick={warehouseRefetch}>{warehouseError.message}</ErrorDisplay>;
    if (supplierError) return <ErrorDisplay onClick={supplierRefetch}>{supplierError.message}</ErrorDisplay>;
    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!unitOfMeasuresData || !warehouseData || !supplierData) return <Loading />;

    //called when AG Grid renders
    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);

        //used by AG Grid as a server handle to lazy load data
        const dataSource: IDatasource = {
            //called whenever AG Grid needs more data
            getRows: (params: IGetRowsParams) => {
                let warehouseId: string | null = null;
                let onlyUnassignedProducts = false;
                let excludeUnassignedProducts = false;
                let supplierId: string | null = supplierFilter.current === "all" ? null : supplierFilter.current;

                //configure what locations to filter
                switch (locationFilter.current) {
                    case "missant":
                        warehouseId = "1";
                        break;
                    case "west":
                        warehouseId = "2";
                        break;
                    case "assigned":
                        excludeUnassignedProducts = true;
                        break;
                    case "unassigned":
                        onlyUnassignedProducts = true;
                        break;
                }

                //construct the sortOrder string so it matches an enum on the server
                let sortOrder = String(null);

                if (params.sortModel.length > 0) {
                    if (params.sortModel[0].sort === "asc") {
                        sortOrder = "_ASCENDING";
                    } else if (params.sortModel[0].sort === "desc") {
                        sortOrder = "_DESCENDING";
                    }

                    switch (params.sortModel[0].colId) {
                        case "product.description":
                            sortOrder = `DESCRIPTION${sortOrder}`;
                            break;
                        case "product.sku":
                            sortOrder = `SKU${sortOrder}`;
                            break;
                        case "warehouse.name":
                            sortOrder = `LOCATION${sortOrder}`;
                            break;
                        case "minimumBeforeReorder":
                            sortOrder = `MIN_BEFORE_REORDER${sortOrder}`;
                            break;
                        case "reorderQuantity":
                            sortOrder = `REORDER_QUANTITY${sortOrder}`;
                            break;
                        case "available":
                            sortOrder = `AVAILABLE${sortOrder}`;
                            break;
                        case "stockOnHand":
                            sortOrder = `ON_HAND${sortOrder}`;
                            break;
                        case "onOrder":
                            sortOrder = `ON_ORDER${sortOrder}`;
                            break;
                        case "allocated":
                            sortOrder = `ALLOCATED${sortOrder}`;
                            break;
                    }
                }
                //default
                else {
                    sortOrder = "ID_ASCENDING";
                }

                //ask for 100 rows after this index
                const after = params.startRow === 0 ? null : (params.startRow - 1).toString();
                //query the server for data
                const ret = graphQLContext.client.ExecuteQueryRaw<IProductsByWarehouseResult, IProductsByWarehouseVariables>({
                    query: ProductsByWarehouseQuery,
                    variables: {
                        after: after,
                        warehouseId: warehouseId,
                        supplierId: supplierId,
                        search: search.current,
                        onlyUnassignedProducts: onlyUnassignedProducts,
                        excludeUnassignedProducts: excludeUnassignedProducts,
                        sortOrder: sortOrder,
                    },
                });

                ret.result.then(
                    //success
                    (result) => {
                        //failure
                        if (result.errors) {
                            console.log("Error fetching data: 1", result.errors);
                            params.failCallback();
                            return;
                        }

                        //feed data into AG Grid
                        params.successCallback(result.data!.productsByWarehouse.items, result.data!.productsByWarehouse.totalCount);
                    },
                    //failure
                    (result) => {
                        params.failCallback();
                        console.log("Error fetching data: 2", result);
                    }
                );
            },
        };

        //bind the server handle ('dataSource') to AG Grid
        params.api.setDatasource(dataSource);
    };

    //called when the 'Choose a location:' dropdown changes
    const externalLocationFilterChanged = (event: React.BaseSyntheticEvent) => {
        locationFilter.current = event.target.value;
        gridApi!.onFilterChanged();
    };

    const externalSupplierFilterChanged = (event: React.BaseSyntheticEvent) => {
        supplierFilter.current = event.target.value;
        gridApi!.onFilterChanged();
    };

    //AG Grid column structure
    const columns = [
        {
            field: "product.description",
            headerName: "Description",
            flex: 1.5,
            minWidth: 150,
            cellRenderer: "loadingRenderer",
        },
        {
            field: "product.sku",
            headerName: "SKU",
            minWidth: 180,
            sortable: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data) {
                    const link = `/products/${params.data.productId}`;
                    if (params.data.product.sku && params.data.product.sku !== "") {
                        return <Link to={link}>{params.data.product.sku}</Link>;
                    } else {
                        return <Link to={link}>{params.data.productId}</Link>;
                    }
                } else return <></>;
            },
        },
        {
            field: "warehouse.name",
            headerName: "Location",
            flex: 0.5,
            minWidth: 130,
        },
        {
            field: "minimumBeforeReorder",
            headerName: "Min. Before Reorder",
            flex: 0.5,
            minWidth: 182,
        },
        {
            field: "reorderQuantity",
            flex: 0.5,
            minWidth: 164,
        },
        {
            field: "available",
            flex: 0.5,
            minWidth: 117,
        },
        {
            field: "stockOnHand",
            headerName: "On Hand",
            flex: 0.5,
            minWidth: 117,
        },
        {
            field: "onOrder",
            headerName: "On Order",
            flex: 0.5,
            minWidth: 117,
        },
        {
            field: "allocated",
            flex: 0.5,
            minWidth: 117,
        },
    ];

    //setting all data to empty for the original modal
    const originalProductObj: IProductObj = {
        id: "0",
        description: "",
        sku: "",
        partNumber: "",
        type: "PRODUCT",
        dropShipMode: "NEVER",
        unitOfMeasure: "1",
        barcode: "",
        active: true,
        warehouseId: defaultWarehouseId ?? "",
    };

    const onSaveChanges = (value: IProductObj) => {
        //set form controls
        setSaving(true);
        //start add operation via graphql mutation
        runAdd({
            variables: {
                value: {
                    id: "0",
                    /*
                     Value type set to default value
                     VCheck Must me fixed first
                     */
                    description: value.description,
                    type: value.type,
                    unitOfMeasureId: value.unitOfMeasure,
                    dropShipMode: value.dropShipMode,
                    active: true,
                    partNumbers: StringHelper.IsNullOrWhitespace(value.partNumber)
                        ? null
                        : [
                              {
                                  partNumber: value.partNumber,
                                  sortOrder: 0,
                              },
                          ],
                    sku: value.sku,
                    barcodes: StringHelper.IsNullOrWhitespace(value.barcode)
                        ? null
                        : [
                              {
                                  barcode: value.barcode,
                                  sortOrder: 0,
                              },
                          ],
                    warehouseProducts: [
                        {
                            warehouseId: value.warehouseId,
                        },
                    ],
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                //leave the dialog open with buttons disabled, and perform redirect instead
                //setSaving(false);
                //refetch();
                //set path to new product ID that is created
                let path = `/products/` + ret.data.product.add.id + `/edit`;
                //push ID to history to redirect to new product page
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding product", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const suppliers = supplierData.suppliers.items.filter((x) => x.active && !x.deleted).sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            <h2>Products</h2>
            <Row>
                <Col>
                    <Form.Label>Search</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Form
                        onSubmit={(event: React.BaseSyntheticEvent) => {
                            event.preventDefault();
                            search.current = event.currentTarget.querySelector("#searchValue").value;
                            gridApi!.onFilterChanged();
                        }}
                    >
                        <InputGroup>
                            <Form.Control id="searchValue" type="text" />
                            <Button type="submit" variant="primary">
                                Search
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md="2">
                    <Button onClick={handleOpen} variant="white">
                        Add product
                    </Button>
                </Col>
                <Col md="3" className="d-flex justify-content-end align-items-center">
                    <Form.Label className="mb-0 me-3 text-nowrap">Supplier:</Form.Label>
                    <Form.Select onChange={externalSupplierFilterChanged}>
                        <option value="all">All</option>
                        {suppliers.map((supplier) => (
                            <option key={`supplier-${supplier.id}`} value={supplier.id}>
                                {supplier.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md="3" className="d-flex justify-content-end align-items-center">
                    <Form.Label className="mb-0 me-3 text-nowrap">Location:</Form.Label>
                    <Form.Select onChange={externalLocationFilterChanged}>
                        <option value="all">All</option>
                        <option value="missant">Zbox Missant</option>
                        <option value="west">Zbox West</option>
                        <option value="assigned">Assigned</option>
                        <option value="unassigned">Unassigned</option>
                    </Form.Select>
                </Col>
            </Row>

            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: (params: ICellRendererParams) => {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                            }
                        },
                    }}
                    onGridReady={onGridReady}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                ></AgGridReact>
            </div>

            <Modal show={showModal} onHide={handleClose} size={"lg" as any}>
                <VForm onSubmit={onSaveChanges} initialValue={originalProductObj} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <VGroup as={Row} className="mb-3" fieldName="description">
                            <VLabel title="Desc/Part Type" column sm={3} />
                            <Col sm={9}>
                                <VControl type="text" required />
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="sku">
                            <VLabel title="Sku" column sm={3} />
                            <Col sm={9}>
                                <VControl type="text" required />
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="partNumber">
                            <VLabel title="Part Number" column sm={3} />
                            <Col sm={9}>
                                <VControl rows={3} type="text" />
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="barcode">
                            <VLabel title="Barcode" column sm={3} />
                            <Col sm={9}>
                                <VControl rows={3} type="text" />
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="type">
                            <VLabel title="Type" column sm={3} />
                            <Col sm={9}>
                                <VSelect>
                                    <option value="PRODUCT">Product</option>
                                    <option value="SERVICE">Service</option>
                                </VSelect>
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="dropShipMode">
                            <VLabel title="Drop Ship Mode" column sm={3} />
                            <Col sm={9}>
                                <VSelect>
                                    <option value="NEVER">Never</option>
                                    <option value="OPTIONAL">Optional</option>
                                    <option value="ALWAYS">Always</option>
                                </VSelect>
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="unitOfMeasure">
                            <VLabel title="Unit of Measure" column sm={3} />
                            <Col sm={9}>
                                <VSelect>
                                    {/*Query from unitOfMeasures by id to get value and display by name*/}
                                    {unitOfMeasuresData.unitOfMeasures.items.map((units) => (
                                        <option key={`UnitOfMeasure-${units.id}`} value={units.id}>
                                            {units.name}
                                        </option>
                                    ))}
                                </VSelect>
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="warehouseId">
                            <VLabel title="Warehouse" column sm={3} />
                            <Col sm={9}>
                                <VSelect>
                                    {/*Query from unitOfMeasures by id to get value and display by name*/}
                                    {warehouseData.warehouses.items.map((warehouse) => (
                                        <option key={`UnitOfMeasure-${warehouse.id}`} value={warehouse.id}>
                                            {warehouse.name}
                                        </option>
                                    ))}
                                </VSelect>
                            </Col>
                        </VGroup>
                        <VGroup as={Row} className="mb-3" fieldName="active">
                            <Col sm={{ span: 9, offset: 3 }}>
                                <VCheck label="Active" />
                            </Col>
                        </VGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save Changes
                        </Button>
                        <Button variant="secondary" onClick={handleClose} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};
export default ProductsIndex;
