import { Badge, Button } from "react-bootstrap";
import StringHelper from "../../helpers/StringHelper";

const PageHeader = (props: React.PropsWithChildren<{ badge?: string; badges?: string[]; onEdit?: () => void }>) => {
    const badges: string[] = props.badges || [];
    if (props.badge) badges.push(props.badge);
    return (
        <table>
            <tbody>
                <tr>
                    <td>
                        <h2 className="mb-3">{props.children}</h2>
                    </td>
                    {badges.map((s, i) => (
                        <td key={i} valign="top">
                            <Badge bg="secondary" className="ms-4">
                                {StringHelper.SnakeCaseToTitleCase(s)}
                            </Badge>
                        </td>
                    ))}
                    {props.onEdit ? (
                        <td valign="top">
                            <Button
                                variant="white"
                                onClick={() => {
                                    props.onEdit!();
                                }}
                                size="sm"
                                className="ms-4"
                                style={{ padding: "0.125rem 0.4rem", float: "right" }}
                            >
                                Edit
                            </Button>
                        </td>
                    ) : null}
                </tr>
            </tbody>
        </table>
    );
};

export default PageHeader;
