import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";
import IAisleEquipment from "../../models/AisleEquipment";
import ScanBin from "./ScanBin";
import scanBinQueryBase from "./scanBinQueryBase";
import ScanSku from "./ScanSku";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";
import { sortPickList } from "../../helpers/aisle_sorting/aisleSortOrder";
import { IFilters } from "./SelectList";

export interface IDisplayPicksModel {
    location: {
        id: string;
        name: string;
        aisle: string | null;
        rack: string | null;
        side: string | null;
        shelf: string | null;
        bin: string | null;
    };
    product: {
        id: string;
        sku: string;
        description: string;
        barcodes: Array<{
            barcode: string;
        }>;
    };
    pickSaleModels: Array<{
        saleId: string;
        quantityToPick: number;
    }>;
    direction: number | null;
}

interface IDisplayPicksInput {
    aisle: string;
    pickEquipmentId: string;
}

export interface IPickZoneLocation {
    id: string;
    name: string;
    products: Array<{
        stockOnHand: number;
        product: {
            id: string;
            sku: string;
        };
    }>;
}

export interface IScanBinQueryResult {
    mobile: {
        displayPicks: IDisplayPicksModel[];
    };
    pickZone: {
        name: string;
        locations: IPickZoneLocation[];
    };
}

export interface IScanBinQueryVariables {
    pickZoneId: string;
    displayPicks: IDisplayPicksInput[];
    warehouseId: string;
    displayPicksFiltersInput: IFilters;
}

const scanBinQuery = `
query ($warehouseId: ID!, $displayPicksFiltersInput: DisplayPicksFiltersInput!, $displayPicks: [DisplayPicksInput!]!, $pickZoneId: ID!) {
  ${scanBinQueryBase}
}
`;

export interface IClickedTableRow {
    sku: string;
    locationName: string;
}

const ScanBinController = () => {
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(decodeURIComponent(history.location.search));
    const cartId = urlSearchParams.get("cartId");
    const sortSku = urlSearchParams.get("sortSku");
    const sortLocationName = urlSearchParams.get("sortLocationName");
    const aisleEquipments = urlSearchParams.getAll("aisleEquipment").map((aisleEquipment) => JSON.parse(aisleEquipment) as IAisleEquipment);
    const activeSideFilters = urlSearchParams.getAll("activeSideFilters");
    const activeShelfFilters = urlSearchParams.getAll("activeShelfFilters");
    const dpf = urlSearchParams.get("displayPicksFilters");
    const displayPicksFilters = JSON.parse(dpf ?? "") as IFilters;
    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        loading: defaultWarehouseIdLoading,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");
    const scanBinQueryVariables: IScanBinQueryVariables | undefined =
        !cartId || aisleEquipments.length < 1 || !defaultWarehouseId
            ? undefined
            : ({
                  pickZoneId: cartId,
                  displayPicks: aisleEquipments.map((ae) => {
                      const displayPickVariable: IDisplayPicksInput = {
                          aisle: ae.aisle,
                          pickEquipmentId: ae.equipmentId,
                      };
                      return displayPickVariable;
                  }),
                  warehouseId: defaultWarehouseId,
                  displayPicksFiltersInput: displayPicksFilters,
              } as IScanBinQueryVariables);
    const {
        loading: isScanBinLoading,
        refetch: scanBinRefetch,
        data: scanBinData,
        error: scanBinError,
    } = useQuery<IScanBinQueryResult, IScanBinQueryVariables>(scanBinQuery, {
        variables: scanBinQueryVariables,
        fetchPolicy: "no-cache",
        skip: !scanBinQueryVariables,
    });

    if (scanBinError) return <ErrorDisplay onClick={scanBinRefetch}>{scanBinError.message}</ErrorDisplay>;

    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;

    if (aisleEquipments.length < 1) return <Redirect to="/mobile/pick/selectlist" />;

    if (!cartId) return <Redirect to={`/mobile/pick/scancart?${urlSearchParams.toString()}`} />;

    const isLoading = [isScanBinLoading, defaultWarehouseIdLoading].some((x) => x === true);

    //every time the scan bin query runs, we make sure we still have items to pick.
    //if there is nothing left to pick, we need to go to the cart process.
    if (!isLoading && scanBinData && scanBinData.mobile.displayPicks.length < 1)
        return <Redirect to={`/mobile/pick/${cartId}/returncart`} />;

    let displayPicks = scanBinData?.mobile.displayPicks ?? [];
    let displayPicksCopy = [...displayPicks];

    // Apply side filters
    if (activeSideFilters.length > 0) {
        displayPicksCopy = displayPicksCopy.filter((x) => {
            const side = x.location?.side;
            return side && activeSideFilters.includes(side);
        });
    }

    // Apply shelf filters
    if (activeShelfFilters.length > 0) {
        displayPicksCopy = displayPicksCopy.filter((x) => {
            const shelf = x.location.shelf;
            return shelf && activeShelfFilters.includes(shelf);
        });
    }

    displayPicksCopy = sortPickList(displayPicksCopy, { groupAndSortSides: true });

    //get index of where to split array
    const splitIndex = displayPicksCopy.findIndex((x) => x.location.name === sortLocationName);
    //backArray includes the locations we want
    const backArray = splitIndex > -1 ? displayPicksCopy.splice(splitIndex) : [];
    //get all values that equal the selected location name
    let displayPicksInLocation: IDisplayPicksModel[] = [];
    for (let l = backArray.length - 1; l >= 0; l -= 1)
        if (backArray[l].location.name === sortLocationName) displayPicksInLocation.push(backArray.splice(l, 1)[0]);
    //get sku in selected location
    const displayPicksBySkuInSelectedLocation = displayPicksInLocation.filter((x) => x.product.sku === sortSku);

    //get all other skus in selected location, and sort by sku
    const otherSkusInSelectedLocation = displayPicksInLocation
        .filter((x) => x.product.sku !== sortSku)
        .sort((a, b) => a.product.sku.localeCompare(b.product.sku));

    displayPicks = [...displayPicksBySkuInSelectedLocation, ...otherSkusInSelectedLocation, ...backArray, ...displayPicksCopy];

    return (
        <Switch>
            <Route exact path="/mobile/pick/scanbin/scanbin">
                <ScanBin
                    locationsOnCart={scanBinData?.pickZone.locations ?? []}
                    isScanBinLoading={isLoading}
                    scanBinRefetch={scanBinRefetch}
                    displayPicks={displayPicks}
                    scanBinError={scanBinError}
                    cartId={cartId}
                    sortSku={sortSku}
                    urlSearchParams={urlSearchParams}
                    activeSideFilters={activeSideFilters}
                    activeShelfFilters={activeShelfFilters}
                />
            </Route>
            <Route path={`/mobile/pick/scanbin/scansku/:locationId/:sku?`}>
                <ScanSku
                    scanBinData={scanBinData}
                    cartName={scanBinData?.pickZone.name ?? "N/A"}
                    scanBinRefetch={scanBinRefetch}
                    isScanBinLoading={isLoading}
                    scanBinQueryVariables={scanBinQueryVariables}
                    sortedDisplayPicks={displayPicks}
                    cartId={cartId}
                />
            </Route>
        </Switch>
    );
};

export default ScanBinController;
