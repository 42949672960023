import React from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";

interface IQueryResult {
    warehouse: IWarehouse;
}

interface IWarehouse {
    stationSets: Array<{
        id: string;
        name: string;
        warehouseId: string;
        active: boolean;
        type: string;
        stashPickZoneId: string | null;
        preferredPickZoneId: string | null;
        stations: Array<{
            id: string;
            type: string;
            targetCarts: number;
            maxCarts: number;
        }>;
    }>;
}

interface IStationSetModel {
    id: string;
    name: string;
    warehouseId: string;
    stashPickZoneId: string | null;
    preferredPickZoneId: string | null;
    active: boolean;
    type: string;
    inputTarget: string;
    inputMax: string;
    outputTarget: string;
    outputMax: string;
}

interface IPickZones {
    pickZones: {
        items: Array<{
            id: string;
            name: string;
            isCart: boolean;
            active: boolean;
            pickable: boolean;
        }>;
    };
}
interface IStationSet {
    id: string;
    name: string;
    warehouseId: string;
    active: boolean;
    type: string;
    stashPickZoneId: string | null;
    preferredPickZoneId: string | null;
    stations: Array<{
        id: string;
        type: string;
        targetCarts: number;
        maxCarts: number;
    }>;
}

interface IEditResult {
    stationSet: {
        edit: IStationSet;
    };
}

interface IEditVariables {
    original: IStationSet;
    modified: IStationSet;
}

interface IAddResult {
    stationSet: {
        add: IStationSet;
    };
}

interface IAddVariables {
    value: IStationSet;
}

interface IDeleteResult {
    stationSet: {
        delete: string;
    };
}

interface IModal {
    show: boolean;
    original?: IStationSet;
}

const hiddenModal: IModal = {
    show: false,
};
const PickZonesQuery = `
query ($warehouseId: ID) {
  pickZones(warehouseId: $warehouseId) {
    items {
      id
      name
      isCart
      active
      pickable
    }
  }
}
`;

const StationSetQuery = `
query ($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      stationSets {
        id
        name
        type
        active
        warehouseId
        stashPickZoneId
        preferredPickZoneId
        stations {
          id
          type
          targetCarts
          maxCarts
        }
      }
    }
  }
`;

const EditMutation = `
mutation ($original: StationSetInput!, $modified: StationSetInput!) {
  stationSet {
    edit(original: $original, modified: $modified) {
      id
      name
      type
      active
      warehouseId
      stashPickZoneId
      preferredPickZoneId
      stations {
        id
        type
        targetCarts
        maxCarts
      }
    }
  }
}
`;

const AddMutation = `
mutation ($value: StationSetInput!) {
  stationSet {
    add(value: $value) {
      id
      name
      type
      active
      warehouseId
      stashPickZoneId
      preferredPickZoneId
      stations {
        id
        type
        targetCarts
        maxCarts
      }
    }
  }
}
`;

const DeleteMutation = `
mutation ($id: ID!) {
    stationSet{
        delete (id: $id)
    }
}
`;

const WarehouseStationSets = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id: warehouseId } = useParams<{ id: string }>();
    //const [type, setType] = React.useState<string>();

    const { data, error, refetch } = useQuery<IQueryResult, { warehouseId: string }>(StationSetQuery, {
        variables: { warehouseId: warehouseId },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataPickZones,
        error: errorPickZones,
        refetch: refetchPickZones,
    } = useQuery<IPickZones, { warehouseId: string }>(PickZonesQuery, { variables: { warehouseId: warehouseId }, fetchPolicy: "no-cache" });

    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditMutation);
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteMutation);

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorPickZones) return <ErrorDisplay onClick={refetchPickZones}>{errorPickZones.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data || !dataPickZones) return <Loading />;

    //=== set up local functions ===
    //run when the edit button is pressed
    const onShowEditModal = (value: IStationSet) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };
    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const originalModel: IStationSetModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              type: modal.original.type,
              active: modal.original.active,
              warehouseId: warehouseId,
              inputTarget: modal?.original?.stations?.filter((x) => x.type === "INPUT")[0]?.targetCarts.toString(),
              inputMax: modal.original.stations.filter((x) => x.type === "INPUT")[0]?.maxCarts.toString(),
              outputMax: modal.original.stations.filter((x) => x.type === "OUTPUT")[0]?.targetCarts.toString(),
              outputTarget: modal.original.stations.filter((x) => x.type === "OUTPUT")[0]?.maxCarts.toString(),
              preferredPickZoneId: modal.original.preferredPickZoneId || null,
              stashPickZoneId: modal.original.stashPickZoneId || null,
          }
        : {
              id: "0",
              name: "",
              type: "",
              active: true,
              warehouseId: warehouseId,
              inputTarget: "",
              inputMax: "",
              outputMax: "",
              outputTarget: "",
              preferredPickZoneId: null,
              stashPickZoneId: null,
          };
    //run when the save button is pressed
    const onSaveChanges = (modified: IStationSetModel) => {
        if (modified.preferredPickZoneId === modified.stashPickZoneId) {
            alert("Preferred pick zone and stash pick zone cannot be the same!");
        } else {
            if (!modal.original) {
                //=== ADD ===
                //disable form controls
                setSaving(true);
                //start add operation via graphql mutation

                runAdd({
                    variables: {
                        value: {
                            id: "0",
                            active: true,
                            name: modified.name,
                            warehouseId: modified.warehouseId,
                            type: modified.type,
                            preferredPickZoneId: modified.preferredPickZoneId || null,
                            stashPickZoneId: modified.stashPickZoneId || null,
                            stations:
                                modified.type === "SPARE"
                                    ? [
                                          {
                                              id: "0",
                                              type: "OUTPUT",
                                              targetCarts: parseFloat(modified.outputTarget),
                                              maxCarts: parseFloat(modified.outputMax),
                                          },
                                      ]
                                    : [
                                          {
                                              id: "0",
                                              type: "INPUT",
                                              targetCarts: parseFloat(modified.inputTarget),
                                              maxCarts: parseFloat(modified.inputMax),
                                          },
                                          {
                                              id: "0",
                                              type: "PROCESS",
                                              targetCarts: 1,
                                              maxCarts: 1,
                                          },
                                          {
                                              id: "0",
                                              type: "OUTPUT",
                                              targetCarts: parseFloat(modified.outputTarget),
                                              maxCarts: parseFloat(modified.outputMax),
                                          },
                                      ],
                        },
                    },
                }).then(
                    //success
                    (ret) => {
                        //add the entry to the local list
                        var newValue = ret.data.stationSet.add;
                        data.warehouse.stationSets.push(newValue);
                        //enable form controls and hide the modal
                        setSaving(false);
                        setModal(hiddenModal);
                    },
                    //failure
                    (err) => {
                        //enable form controls
                        setSaving(false);
                        //log the error to the console including all details
                        console.error("Error adding station set", err);
                        //display the error message
                        alert(err.message);
                    }
                );
            } else {
                //=== EDIT ===
                //disable form controls
                setSaving(true);
                //start edit operation via graphql mutation
                runEdit({
                    variables: {
                        //pass in original data
                        original: modal.original,
                        //pass in modified data
                        modified: {
                            id: modal.original.id,
                            name: modified.name,
                            type: modified.type,
                            active: modified.active,
                            warehouseId: modified.warehouseId,
                            preferredPickZoneId: modified.preferredPickZoneId || null,
                            stashPickZoneId: modified.stashPickZoneId || null,
                            stations:
                                modified.type === "SPARE"
                                    ? [
                                          {
                                              id: modal.original.stations.filter((x) => x.type === "OUTPUT")[0].id,
                                              type: "OUTPUT",
                                              targetCarts: parseFloat(modified.outputTarget),
                                              maxCarts: parseFloat(modified.outputMax),
                                          },
                                      ]
                                    : [
                                          {
                                              id: modal.original.stations.filter((x) => x.type === "INPUT")[0].id,
                                              type: "INPUT",
                                              targetCarts: parseFloat(modified.inputTarget),
                                              maxCarts: parseFloat(modified.inputMax),
                                          },
                                          {
                                              id: modal.original.stations.filter((x) => x.type === "PROCESS")[0].id,
                                              type: "PROCESS",
                                              targetCarts: 1,
                                              maxCarts: 1,
                                          },
                                          {
                                              id: modal.original.stations.filter((x) => x.type === "OUTPUT")[0].id,
                                              type: "OUTPUT",
                                              targetCarts: parseFloat(modified.outputTarget),
                                              maxCarts: parseFloat(modified.outputMax),
                                          },
                                      ],
                        },
                    },
                }).then(
                    //success
                    (ret) => {
                        //update the local list with the modified entry
                        if (modal.original) {
                            const newValue = ret.data.stationSet.edit;
                            const originalId = modal.original.id;
                            const oldIndex = data.warehouse.stationSets.findIndex((x) => x.id === originalId);
                            if (oldIndex >= 0) data.warehouse.stationSets[oldIndex] = newValue;
                        }
                        //enable form controls and hide the modal
                        setSaving(false);
                        setModal(hiddenModal);
                    },
                    //failure
                    (err) => {
                        //enable form controls
                        setSaving(false);
                        //log the error to the console including all details
                        console.error("Error editing Station Set", err);
                        //display the error message
                        alert(err.message);
                    }
                );
            }
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Station Set?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.warehouse.stationSets.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.warehouse.stationSets.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Station Sets", err);
                //display the error message
                alert(err.message);
            }
        );
    };
    const filteredPickZones = dataPickZones.pickZones.items.filter((x) => x.active === true && x.isCart === false && x.pickable === true);
    const card = (active: boolean) => {
        // search data where setting or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        const filteredStationSets = data?.warehouse.stationSets
            ?.filter((stations) => stations.active === active)
            .sort((a, b) => a.name.localeCompare(b.name));
        return (
            <Card className="border-primary">
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} Station Sets</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredStationSets?.map((stationSet) => (
                                <tr key={stationSet.id}>
                                    <td>{stationSet.name}</td>
                                    <td>{stationSet.type}</td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditModal(stationSet)}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    //render
    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new station set
                </Button>
            </p>

            {/* Active Station Sets */}
            {card(true)}
            {/* Inactive Station Sets */}
            {card(false)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Station Set</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel>Name</VLabel>
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="type" hidden={modal.original ? true : false}>
                                    <VLabel>Type</VLabel>
                                    {/* onChange={(e) => { setType(e.currentTarget.value); }*/}
                                    <VSelect required>
                                        <option value="" hidden={!modal.original}></option>
                                        <option value="INTAKE">Intake</option>
                                        <option value="PACK">Pack</option>
                                        <option value="SPARE">Spare</option>
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-4" fieldName="inputTarget">
                                    <VLabel>Input Target</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="inputMax">
                                    <VLabel>Input Max</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="outputTarget">
                                    <VLabel>Output Target</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="outputMax">
                                    <VLabel>Output Max</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="preferredPickZoneId">
                                    <VLabel>Preferred Pick Zone</VLabel>
                                    <VSelect>
                                        <option value="" hidden={!modal.original}></option>
                                        {filteredPickZones.map((filteredPickZone) => (
                                            <option value={filteredPickZone.id}>{filteredPickZone.name}</option>
                                        ))}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-4" fieldName="stashPickZoneId">
                                    <VLabel>Stash Pick Zone</VLabel>
                                    <VSelect>
                                        <option value="" hidden={!modal.original}></option>
                                        {filteredPickZones.map((filteredPickZone) => (
                                            <option value={filteredPickZone.id}>{filteredPickZone.name}</option>
                                        ))}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-3" fieldName="active">
                                    <VCheck label="Active" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default WarehouseStationSets;
