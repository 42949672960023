import { useContext, useState } from "react";
import { GraphQLContext, GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { GridApi, GridReadyEvent, ICellRendererParams, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Link, useParams } from "react-router-dom";
import FormatDate from "../../../helpers/FormatDate";
import StrictOmit from "../../../types/StrictOmit";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { VArray, VControl, VForm, VLabel, VSelect, ValidationFormContext, VGroup } from "@shane32/vform";
import ReturnFault from "../../../enums/ReturnFault";
import ReturnTo from "../../../enums/ReturnTo";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import ProductCondition from "../../../enums/ProductCondition";
import ProductType from "../../../enums/ProductType";
import GroupBy from "../../../helpers/GroupBy";
import PackingType from "../../../enums/PackingType";
import PackageType from "../../../enums/PackageType";
import StringHelper from "../../../helpers/StringHelper";
import ReturnStatus from "../../../enums/ReturnStatus";
import styles from "./SalesReturns.module.scss";

//#region Routing/Misc React
interface ISalesReturnsRouteParams {
    id: string;
}
type IStrictOmitCellRendererParams = StrictOmit<ICellRendererParams, "data">;

interface ICustomCellRendererParams extends IStrictOmitCellRendererParams {
    data?: IReturn;
}

interface ICustomAgGridColumn {
    cellRenderer: (params: ICustomCellRendererParams) => JSX.Element;
    flex: number;
    headerName: string;
}
//#endregion
//#region Queries and Mutations
//#region Returns Query
const ReturnsQuery = `
query($relatedSaleId: ID) {
    returns(relatedSaleId: $relatedSaleId) {
      totalCount
      items {
          id
          status
          totalRefund
          createdAt
        lineItems {
            product {
                id
                sku
            }
            quantityReturn
        }
        originalSale {
            id
            orderDate
            total
            shippingAddress {
                fullName
            }
            salesChannel {
                name
            }
      }
    }
    }
  }
`;

interface IReturnsQueryResult {
    returns: {
        totalCount: number;
        items: Array<IReturn>;
    };
}

interface IReturn {
    id: string;
    status: ReturnStatus;
    createdAt: string;
    totalRefund: number;
    lineItems: Array<IReturnLineItem>;
    originalSale: {
        id: string;
        orderDate: string;
        total: number;
        shippingAddress: {
            fullName: string;
        };
        salesChannel: {
            name: string;
        };
    };
}

interface IReturnLineItem {
    product: {
        id: string;
        sku: string;
    } | null;
    quantityReturn: number;
}

interface IReturnsQueryVariables {
    relatedSaleId: string;
}
//#endregion
//#region Sale Query

const saleQuery = `
query ($saleId: ID!) {
    sale(id: $saleId) {
      id
      email
      shippingServiceId
      salesChannel {
        id
        name
      }
      lineItems {
        id
        product {
          id
          sku
          description
          type
        }
        quantity
        quantityShipped
        unitPrice
        tax
        total
      }
      dropShip
    }
}
`;
interface ISaleQueryVariables {
    saleId: string;
}
interface ISaleQueryResult {
    sale: {
        id: string;
        email: string | null;
        shippingServiceId: string | null;
        salesChannel: {
            id: string;
            name: string;
        };
        lineItems: Array<ISaleLineItem>;
        dropShip: boolean;
    };
}

interface ISaleLineItem {
    id: string;
    product: {
        id: string;
        sku: string;
        description: string;
        type: ProductType;
    };
    quantity: number;
    quantityShipped: number;
    unitPrice: number;
    tax: number;
    total: number;
}
//#endregion
//#region Return Reason Query
const returnReasonQuery = `
{
    returnReasons {
      items {
        id
        description
      }
    }
  }
`;

interface IReturnReasonQueryResult {
    returnReasons: {
        items: Array<{
            id: string;
            description: string;
        }>;
    };
}
//#endregion
//#region Shipping Service Query
const shippingServiceQuery = `
{
  shippingServices {
    items {
      id
      shippingCarrier {
        id
        name
      }
      name
      isReturnService
      active
    }
  }
}
`;

interface IShippingServiceQueryResult {
    shippingServices: {
        items: Array<{
            id: string;
            shippingCarrier: {
                id: string;
                name: string;
            };
            name: string;
            isReturnService: boolean;
            active: boolean;
        }>;
    };
}
//#endregion
//#region Create Return Mutation
const createReturnMutation = `
mutation ($createReturnInputModel: CreateReturnInput!) {
    return {
      createReturn(createReturnInputModel: $createReturnInputModel)
    }
  }
`;
interface ICreateReturnMutationVariables {
    createReturnInputModel: ICreateReturnModel;
}

interface ICreateReturnMutationResult {
    return: {
        createReturn: string;
    };
}

interface ICreateReturnModel {
    returnSalesChannelReference: string | null;
    originalSaleId: string;
    returnReasonId: string;
    atFault: string;
    internalNotes: string | null;
    returnLineItemInputModels: ICreateReturnLineItemModel[];
    individualLabels: boolean;
    reshipShippingServiceId: string | null;
    returnShippingServiceId: string | null;
    shippingLabelSendTo: string | null;
    shippingLabelCost: number | null;
    trackingNumber: string | null;
    attemptedMachineMake: string | null;
    attemptedMachineModel: string | null;
    conditionDetails: string | null;
    packageType: string | null;
    packingType: string | null;
    lastShipmentMoveDate: string | null;
}

interface ICreateReturnLineItemModel {
    /**The original SaleLineItemId of this item */
    originalSaleLineItemId: string;
    /**Id of the product. */
    productId: string;
    /**The sku of the product. Only needs to be provided if the product id is not known.*/
    sku: string | null;
    //The number of products the customer wants to be reshipped.
    quantityReship: number;
    /**The number of products the customer is requesting to return. */
    quantityReturn: number;
    /**Where the item should be returned to */
    returnTo: ReturnTo;
    /**The expected condition of the product(s) that is being returned. */
    expectedCondition: string;
    //Refund expected?
    refundExpected: boolean;
    //The amount of money the customer will get refunded for this item.
    refundAmount: number;
}
//#endregion
//#region Return Input Model Client
interface IReturnPurchaseOrderInputModelClient {
    originalSaleId: string;
    returnSalesChannelReference?: string;
    returnReasonId?: string;
    atFault?: string;
    internalNotes?: string;
    returnLineItemInputModels: IReturnLineItemInputModelClient[];
    individualLabels: string;
    reshipShippingServiceId?: string;
    returnShippingServiceId?: string;
    shippingLabelSendTo?: string;
    shippingLabelCost?: string;
    trackingNumber?: string;
    attemptedMachineMake?: string;
    attemptedMachineModel?: string;
    defectType?: string;
    packageType?: string;
    packingType?: string;
    lastShipmentMoveDate?: string;
}

interface IReturnLineItemInputModelClient {
    //Normally represented by the "Description" property on a product.
    partType: string;
    //The SKU of the product.
    productSku: string;
    //The original amount of product the customer ordered.
    quantityOrdered: string;
    //The amount that the customer is actually able to return.
    availableForReturn: string;
    //Id of the product.
    productId: string;
    //The number of products the customer is requesting to return.
    quantityReturn?: string;
    //The number of products the customer wants to be reshipped.
    quantityReship?: string;
    //The expected condition of the product(s) that is being returned.
    expectedCondition?: string;
    //The original SaleLineItemId of this item.
    originalSaleLineItemId: string;
    //The original sale price of the item.
    salePrice: string;
    //Where the item should be returned to.
    returnTo: string;
    //Refund expected?
    refundExpected?: string;
    //The amount of money the customer will get refunded for this item.
    refundAmount?: string;
    //The amount of tax that was applied to the sale line item.
    saleLineItemTax: string;
    //The amount that each sale line item costs divided by the quantity before tax
    saleLineItemUnitPrice?: string;
}
//#endregion
//#endregion

const SalesReturns = () => {
    //#region Hooks
    const graphQLContext = useContext(GraphQLContext);
    const routeParams = useParams<ISalesReturnsRouteParams>();
    const [showAuthorizeReturnModal, setShowAuthorizeReturnModal] = useState(false);
    const [saveAuthorizeReturnLoading, setSaveAuthorizeReturnLoading] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    //const [shippingFieldsActive, setShippingFieldsActive] = useState(false);
    const [runCreateReturnMutation] = useMutation<ICreateReturnMutationResult, ICreateReturnMutationVariables>(createReturnMutation);
    const [returns, setReturns] = useState<IReturn[]>([]);
    //#endregion
    //#region Queries
    const {
        loading: saleLoading,
        refetch: saleRefetch,
        data: saleData,
        error: saleError,
    } = useQuery<ISaleQueryResult, ISaleQueryVariables>(saleQuery, {
        fetchPolicy: "no-cache",
        variables: { saleId: routeParams.id },
    });
    const {
        loading: returnReasonLoading,
        refetch: returnReasonRefetch,
        data: returnReasonData,
        error: returnReasonError,
    } = useQuery<IReturnReasonQueryResult>(returnReasonQuery, {
        fetchPolicy: "no-cache",
    });
    const {
        loading: shippingServiceLoading,
        refetch: shippingServiceRefetch,
        data: shippingServiceData,
        error: shippingServiceError,
    } = useQuery<IShippingServiceQueryResult>(shippingServiceQuery, {
        fetchPolicy: "no-cache",
    });

    if (saleError) return <ErrorDisplay onClick={saleRefetch}>{saleError.message}</ErrorDisplay>;
    if (returnReasonError) return <ErrorDisplay onClick={returnReasonRefetch}>{returnReasonError.message}</ErrorDisplay>;
    if (shippingServiceError) return <ErrorDisplay onClick={shippingServiceRefetch}>{shippingServiceError.message}</ErrorDisplay>;
    //#endregion
    //#region Methods
    const onSaveAuthorizeReturn = (valueClient: IReturnPurchaseOrderInputModelClient) => {
        if (loading) return;
        //check the main return properties
        if (!gridApi) return alert("GridAPI is null.");
        if (!valueClient.originalSaleId || valueClient.originalSaleId === "0") return alert("'OriginalSaleId' is invalid.");
        if (!valueClient.returnReasonId) return alert("Please provide a 'Return Reason' value.");
        if (!valueClient.atFault) return alert("Please provide a 'At Fault' value.");
        //check the return line items for missing values
        for (let lineItem of valueClient.returnLineItemInputModels) {
            if (!lineItem.quantityReturn) return alert(`Please provide an 'Amount for RMA' value for SKU '${lineItem.productSku}'.`);
            if (isNaN(parseInt(lineItem.quantityReturn ?? "", 10)))
                return alert(`Please provide an 'Amount for RMA' value for SKU '${lineItem.productSku}'.`);
            if (!lineItem.expectedCondition) return alert(`Please provide an 'Expected Condition' value for SKU '${lineItem.productSku}'.`);
        }
        let shippingLabelCost: ICreateReturnModel["shippingLabelCost"] = null;
        if (valueClient.shippingLabelCost) {
            const parsedShippingLabelCost = parseFloat(valueClient.shippingLabelCost);
            if (isNaN(parsedShippingLabelCost)) return alert("Please provide a valid 'shipping label cost' value.");
            if (parsedShippingLabelCost < 0)
                return alert("Please provide a 'shipping label cost' value that is equal to or greater than '0'.");
            shippingLabelCost = parsedShippingLabelCost;
        }
        var valueServer: ICreateReturnModel = {
            returnSalesChannelReference: valueClient.returnSalesChannelReference || null,
            originalSaleId: valueClient.originalSaleId,
            individualLabels: valueClient.individualLabels === "Yes" ? true : false,
            returnReasonId: valueClient.returnReasonId,
            atFault: valueClient.atFault,
            internalNotes: valueClient.internalNotes || null,
            conditionDetails: valueClient.defectType || null,
            packageType: valueClient.packageType || null,
            lastShipmentMoveDate: valueClient.lastShipmentMoveDate || null,
            packingType: valueClient.packingType || null,
            returnLineItemInputModels: valueClient.returnLineItemInputModels
                .filter((x) => !(x.quantityReship === "0" && x.quantityReturn === "0"))
                .map((lineItemClient) => {
                    var quantityReturn = lineItemClient.quantityReturn ? parseInt(lineItemClient.quantityReturn ?? "", 10) : 0;
                    var quantityReship = lineItemClient.quantityReship ? parseInt(lineItemClient.quantityReship ?? "", 10) : 0;
                    return {
                        productId: lineItemClient.productId ? lineItemClient.productId : "0",
                        quantityReturn: quantityReturn,
                        quantityReship: quantityReship,
                        sku: lineItemClient.productSku,
                        originalSaleLineItemId: lineItemClient.originalSaleLineItemId,
                        returnTo: lineItemClient.returnTo || "NONE",
                        expectedCondition: lineItemClient.expectedCondition,
                        refundExpected: lineItemClient.refundExpected === "Yes",
                        refundAmount: lineItemClient.refundAmount ? parseFloat(lineItemClient.refundAmount) : 0,
                    } as ICreateReturnLineItemModel;
                }),
            reshipShippingServiceId: valueClient.reshipShippingServiceId || null,
            returnShippingServiceId: valueClient.returnShippingServiceId || null,
            shippingLabelSendTo: valueClient.shippingLabelSendTo || null,
            trackingNumber: valueClient.trackingNumber || null,
            shippingLabelCost: shippingLabelCost,
            attemptedMachineMake: valueClient.attemptedMachineMake || null,
            attemptedMachineModel: valueClient.attemptedMachineModel || null,
        };

        setSaveAuthorizeReturnLoading(true);
        runCreateReturnMutation({ variables: { createReturnInputModel: valueServer } }).then(
            (res) => {
                setSaveAuthorizeReturnLoading(false);
                setShowAuthorizeReturnModal(false);
                gridApi.setDatasource({
                    getRows: (params: IGetRowsParams) => getRows(params),
                });
                if (res.data.return.createReturn)
                    window.open(`${window.location.origin}/returns/${res.data.return.createReturn}`, "_blank");
            },
            (error: GraphQLError) => {
                alert(error.message);
                setSaveAuthorizeReturnLoading(false);
            }
        );
    };

    // const onChangingVForm = (propName: string, newFormValue: IReturnPurchaseOrderInputModelClient) => {
    //     if (propName === "returnReasonId") {
    //         switch (newFormValue.returnReasonId) {
    //         }
    //     }
    //     return newFormValue;
    // };

    const onChangingVArray = (rowIndex: number, propName: string, newRowValue: {}) => {
        const vArrayRow = newRowValue as IReturnLineItemInputModelClient;
        if (propName === "quantityReturn") {
            const quantityReturn = isNaN(parseInt(vArrayRow.quantityReturn ?? "0", 10)) ? 0 : parseInt(vArrayRow.quantityReturn ?? "0", 10);
            const quantityOrdered = isNaN(parseInt(vArrayRow.quantityOrdered ?? "0", 10))
                ? 0
                : parseInt(vArrayRow.quantityOrdered ?? "0", 10);
            const saleLineItemTax = isNaN(parseFloat(vArrayRow.saleLineItemTax ?? "0")) ? 0 : parseFloat(vArrayRow.saleLineItemTax ?? "0");
            const salePrice = isNaN(parseFloat(vArrayRow.salePrice ?? "0")) ? 0 : parseFloat(vArrayRow.salePrice ?? "0");
            const individualLineItemTax = saleLineItemTax / quantityOrdered === 0 ? 1 : quantityOrdered;
            const unitPrice = (salePrice - saleLineItemTax) / quantityOrdered;
            const refundAmount = unitPrice * quantityReturn + individualLineItemTax * quantityReturn;
            vArrayRow.refundAmount = isNaN(refundAmount) ? `0` : `${refundAmount}`;
        }
        return newRowValue;
    };
    //#endregion
    //#region Ag-Grid
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const columns: ICustomAgGridColumn[] = [
        {
            headerName: "Status",
            cellRenderer: (params) => <>{params.data?.status ?? "'Data' is null"}</>,
            flex: 1,
        },
        {
            headerName: "Return Reference",
            cellRenderer: (params) => <Link to={`/returns/${params.data?.id}`}>RN-{params.data?.id}</Link>,
            flex: 1,
        },
        {
            headerName: "Product(s)",
            cellRenderer: (params) => <>{params.data?.lineItems?.map((x) => x.product?.sku ?? "").join("; ")}</>,
            flex: 1,
        },
        {
            headerName: "Refund Opened (Order Date)",
            cellRenderer: (params) => (
                <>{!params.data?.createdAt ? <>Nothing!</> : FormatDate.ConvertDateToLocalDateMMDDYYYY(params.data.createdAt)}</>
            ),
            flex: 1,
        },
        {
            headerName: "Refund Total",
            cellRenderer: (params) => <>{!params.data ? "'Data' is null" : formatter.format(params.data.totalRefund)}</>,
            flex: 1,
        },
    ];

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.setDatasource({
            getRows: (params: IGetRowsParams) => getRows(params),
        });
    };

    const getRows = (params: IGetRowsParams) => {
        let ret = graphQLContext.client.ExecuteQueryRaw<IReturnsQueryResult, IReturnsQueryVariables>({
            query: ReturnsQuery,
            variables: {
                relatedSaleId: routeParams.id,
            },
        });

        ret.result.then(
            (result) => {
                if (result.errors) return;
                params.successCallback(result.data?.returns?.items ?? [], result.data?.returns?.totalCount);
                setReturns(result.data?.returns?.items ?? []);
            },
            () => params.failCallback()
        );
    };
    //#endregion
    //#region Display Prep
    //get the amount of products already returned for a sale, and make sure the quantity for the "availableForReturn" property is correct
    const existingReturns = returns.filter((r) => r.status !== ReturnStatus.Void);
    const existingReturnedProducts = GroupBy(
        existingReturns.flatMap((r) => r.lineItems).filter((x) => x.product),
        (li) => li.product!.id
    );
    let existingReturnedProductsMap = new Map<string, number>(
        Array.from(existingReturnedProducts.entries()).map(([key, poli]) => [key, poli.reduce((a, b) => a + b.quantityReturn, 0)])
    );

    let initialValue: IReturnPurchaseOrderInputModelClient = {
        returnLineItemInputModels: (saleData?.sale?.lineItems ?? [])
            .filter((sli) => sli.product.type === ProductType.Product)
            .map((sli) => {
                const existingReturned = existingReturnedProductsMap.get(sli.product.id) ?? 0;
                let availableForReturn = saleData?.sale?.dropShip ?? false ? sli.quantity : sli.quantityShipped;
                availableForReturn = availableForReturn - existingReturned;
                const individualLineItemTax = sli.tax / sli.quantity;
                const refundAmount = sli.unitPrice * availableForReturn + individualLineItemTax * availableForReturn;
                return {
                    availableForReturn: `${availableForReturn}`,
                    quantityOrdered: `${sli.quantity}`,
                    partType: sli.product.description,
                    productSku: sli.product.sku,
                    expectedCondition: ProductCondition.New,
                    productId: sli.product.id,
                    originalSaleLineItemId: sli.id,
                    salePrice: `${sli.total}`,
                    returnTo: "",
                    quantityReship: "0",
                    quantityReturn: "0",
                    refundAmount: `${refundAmount}`,
                    refundExpected: "Yes",
                    saleLineItemTax: `${sli.tax}`,
                    saleLineItemUnitPrice: `${sli.unitPrice}`,
                } as IReturnLineItemInputModelClient;
            }),
        originalSaleId: saleData?.sale?.id ?? "0",
        individualLabels: "No",
        shippingLabelSendTo: "",
        trackingNumber: "",
        attemptedMachineMake: "",
        attemptedMachineModel: "",
        defectType: "",
        lastShipmentMoveDate: "",
        internalNotes: "",
        packageType: "",
        packingType: "",
        atFault: "",
        returnReasonId: "",
        shippingLabelCost: "",
        reshipShippingServiceId: saleData?.sale?.shippingServiceId ?? "",
        returnShippingServiceId: saleData?.sale?.shippingServiceId ?? "",
    };

    let loading = false;
    if ([saleLoading, returnReasonLoading, shippingServiceLoading].some((x) => x === true)) {
        loading = true;
        return <Loading />;
    }
    //#endregion
    return (
        <>
            <Col md="3">
                <Button onClick={() => setShowAuthorizeReturnModal(true)} variant="white">
                    Authorize a return
                </Button>
            </Col>

            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: function (params: ICellRendererParams) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                            }
                        },
                    }}
                    onGridReady={onGridReady}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={30}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    loadingOverlayComponentParams={{ loadingMessage: "Loading" }}
                />
            </div>
            <Modal
                show={showAuthorizeReturnModal}
                onHide={() => setShowAuthorizeReturnModal(false)}
                fullscreen
                scrollable
                className={styles["modal-container"]}
            >
                <VForm
                    onSubmit={onSaveAuthorizeReturn}
                    initialValue={initialValue}
                    saving={saveAuthorizeReturnLoading}
                    //onChanging={onChangingVForm}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Authorize a Return</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Form.Group as={Row} className="mb-3" controlId="originalSaleId">
                                <Form.Label title="Sales Order Number" column sm={3} />
                                <Col className="d-flex align-items-center" sm={9}>
                                    <div>SO-{saleData?.sale?.id}</div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="salesChannel">
                                <Form.Label title="Sales Channel" column sm={3}>
                                    Sales Channel
                                </Form.Label>
                                <Col className="d-flex align-items-center" sm={9}>
                                    <div>{saleData?.sale?.salesChannel?.name}</div>
                                </Col>
                            </Form.Group>
                            <VGroup as={Row} className="mb-3" fieldName="returnSalesChannelReference">
                                <VLabel title="Return Sales Channel Reference" column sm={3} />
                                <Col className="d-flex align-items-center" sm={9}>
                                    <VControl />
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="returnReasonId">
                                <VLabel title="Return Reason" column sm={3} />
                                <Col className="d-flex align-items-center" sm={9}>
                                    <VSelect>
                                        <option value=""></option>
                                        {returnReasonData?.returnReasons?.items?.map((returnReason) => {
                                            return (
                                                <option key={returnReason.id} value={returnReason.id}>
                                                    {returnReason.description}
                                                </option>
                                            );
                                        })}
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="atFault">
                                <VLabel title="Who is at fault" column sm={3} />
                                <Col sm={9} className="d-flex align-items-center">
                                    <VSelect>
                                        <option value=""></option>
                                        {Object.values<string>(ReturnFault).map((returnFault) => {
                                            return (
                                                <option key={returnFault} value={returnFault}>
                                                    {StringHelper.SnakeCaseToTitleCase(returnFault)}
                                                </option>
                                            );
                                        })}
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="internalNotes">
                                <VLabel title="Internal Notes" column sm={3} />
                                <Col sm={9} className="d-flex align-items-center">
                                    <VControl type="textarea" rows={3} />
                                </Col>
                            </VGroup>
                            <Row>
                                <Col sm={12}>
                                    <Table hover className="table-borderless">
                                        <thead>
                                            <tr className="border-bottom border-dark">
                                                <th>Product Sku</th>
                                                <th>Part Type</th>
                                                <th>Sale Price</th>
                                                <th># Ordered</th>
                                                <th>Available for Return</th>
                                                <th className="border border-top-0 border-dark"># to Reship</th>
                                                <th># to Return</th>
                                                <th>Return To</th>
                                                <th>Expected Condition</th>
                                                <th>Refund Expected?</th>
                                                <th>Refund Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray
                                                fieldName="returnLineItemInputModels"
                                                onChanging={onChangingVArray}
                                                blankRow={{
                                                    productId: "",
                                                    partType: "",
                                                    productSku: "",
                                                    salePrice: "",
                                                    quantityOrdered: "",
                                                    availableForReturn: "",
                                                    quantityReship: "0",
                                                    quantityReturn: "0",
                                                    refundAmount: "0.00",
                                                    returnTo: "",
                                                    expectedCondition: "NEW",
                                                    refundExpected: "Yes",
                                                }}
                                            >
                                                <tr>
                                                    <td>
                                                        <VControl type="text" fieldName="productSku" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="partType" disabled readOnly />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="salePrice" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityOrdered" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="availableForReturn" disabled readOnly />
                                                    </td>
                                                    <td className="border border-dark border-bottom-0 border-top-0">
                                                        <VSpecialSelect
                                                            fieldName="quantityReship"
                                                            formContextValue="availableForReturn"
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <VSpecialSelect
                                                            fieldName="quantityReturn"
                                                            formContextValue="availableForReturn"
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="returnTo">
                                                            <option value=""></option>
                                                            {Object.values<string>(ReturnTo).map((rt) => {
                                                                return (
                                                                    <option key={rt} value={rt}>
                                                                        {StringHelper.SnakeCaseToTitleCase(rt)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="expectedCondition" required>
                                                            <option value=""></option>
                                                            {Object.values<string>(ProductCondition).map((rc) => {
                                                                return (
                                                                    <option key={rc} value={rc}>
                                                                        {StringHelper.SnakeCaseToTitleCase(rc)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="refundExpected">
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="refundAmount" />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <VGroup as={Row} className="mb-3" fieldName="reshipShippingServiceId">
                                <VLabel title="Reship Shipping Method" column sm={4} />
                                <Col className="d-flex align-items-center" sm={8}>
                                    <VSelect disabled={!shippingServiceData}>
                                        <option value=""></option>
                                        {(shippingServiceData?.shippingServices?.items ?? [])
                                            .filter((x) => x.active && x.isReturnService)
                                            .map((ssi) => {
                                                return (
                                                    <option key={ssi.id} value={ssi.id}>
                                                        {ssi.shippingCarrier.name} | {ssi.name}
                                                    </option>
                                                );
                                            })}
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="returnShippingServiceId">
                                <VLabel title="Return Shipping Method" column sm={4} />
                                <Col className="d-flex align-items-center" sm={8}>
                                    <VSelect disabled={!shippingServiceData}>
                                        <option value=""></option>
                                        {(shippingServiceData?.shippingServices?.items ?? [])
                                            .filter((x) => x.active && x.isReturnService)
                                            .map((ssi) => {
                                                return (
                                                    <option key={ssi.id} value={ssi.id}>
                                                        {ssi.shippingCarrier.name} | {ssi.name}
                                                    </option>
                                                );
                                            })}
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="shippingLabelSendTo">
                                <VLabel title="Shipping Label" column sm={2} />
                                <div
                                    className="d-flex align-items-center col-sm-2"
                                    style={{ fontWeight: "bold", display: "flex", justifyContent: "end" }}
                                >
                                    Send to:{" "}
                                </div>
                                <Col className="d-flex align-items-center" sm={8}>
                                    <VControl />
                                    <Button variant="primary" className="ms-2">
                                        Send
                                    </Button>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="individualLabels">
                                <VLabel title="Individual Labels?" column sm={4} />
                                <Col className="d-flex align-items-center" sm={8}>
                                    <VSelect disabled={!shippingServiceData /*|| !shippingFieldsActive*/}>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="trackingNumber">
                                <VLabel title="3rd Party Tracking #" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VControl />
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="shippingLabelCost">
                                <VLabel title="Shipping Label Cost" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VControl />
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="attemptedMachineMake">
                                <VLabel title="Attempted Make" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VControl />
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="attemptedMachineModel">
                                <VLabel title="Attempted Model" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VControl />
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="defectType">
                                <VLabel title="Defect Type" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VControl />
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="packageType">
                                <VLabel title="Package Type" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VSelect>
                                        <option value=""></option>
                                        {Object.values<string>(PackageType).map((packageType) => {
                                            return (
                                                <option key={packageType} value={packageType}>
                                                    {StringHelper.SnakeCaseToTitleCase(packageType)}
                                                </option>
                                            );
                                        })}
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="packingType">
                                <VLabel title="Packing Type" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VSelect>
                                        <option value=""></option>
                                        {Object.values<string>(PackingType).map((packingType) => {
                                            return (
                                                <option key={packingType} value={packingType}>
                                                    {StringHelper.SnakeCaseToTitleCase(packingType)}
                                                </option>
                                            );
                                        })}
                                    </VSelect>
                                </Col>
                            </VGroup>
                            <VGroup as={Row} className="mb-3" fieldName="lastShipmentMoveDate">
                                <VLabel title="Last Shipment Move Date" column sm={4} />
                                <Col sm={8} className="d-flex align-items-center">
                                    <VControl type="date" />
                                </Col>
                            </VGroup>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary" disabled={saveAuthorizeReturnLoading}>
                            Save
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setShowAuthorizeReturnModal(false)}
                            className="me-auto"
                            disabled={saveAuthorizeReturnLoading}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

function VSpecialSelect(props: { required?: boolean; fieldName: string; formContextValue: string }) {
    const validationFormContext = useContext(ValidationFormContext);
    let maxValue = validationFormContext.currentValue[props.formContextValue];
    var maxReturn = (() => {
        switch (maxValue) {
            case "":
                return 99; //arbitrarily high number
            default:
                return parseInt(maxValue, 10);
        }
    })();
    return (
        <VSelect fieldName={props.fieldName} required={props.required}>
            {["0"].concat(Array.from({ length: maxReturn }, (x, i) => (i + 1).toString())).map((qty) => {
                return (
                    <option key={qty} value={qty}>
                        {qty}
                    </option>
                );
            })}
        </VSelect>
    );
}

export default SalesReturns;
