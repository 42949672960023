import * as React from "react";
import Loading from "./Loading";

const DefaultSuspense = (props: { simulate?: boolean; children: any }) => {
    const loadingDiv = (
        <div style={{ width: "100%" }}>
            <Loading />
        </div>
    );
    return props.simulate ? loadingDiv : <React.Suspense fallback={loadingDiv}>{props.children}</React.Suspense>;
};

export default DefaultSuspense;
