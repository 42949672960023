import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { useRouteMatch } from "react-router";
import { AuthContext } from "@zboxglobal/zboxauth";
import { useContext } from "react";
import GlobalContext from "../../contexts/GlobalContext";

const SiteLayout = (props: { children: JSX.Element | null }) => {
    const auth = useContext(AuthContext);
    const global = useContext(GlobalContext);

    const onClickSignout = () => {
        auth.client.InteractiveLogout();
        return false;
    };

    const onToggleFluid = () => {
        global.setFluid(!global.fluid);
        return false;
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="md">
                <Container fluid={global.fluid}>
                    <LinkContainer to="/">
                        <Navbar.Brand>HIVE</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink to="/products">Products</NavLink>
                            <NavLink to="/sales">Sales</NavLink>
                            <NavLink to="/purchaseorders">Purchase Orders</NavLink>
                            <NavLink to="/returns">Returns</NavLink>
                            <NavLink to="/suppliers">Suppliers</NavLink>
                            <NavLink to="/warehouses">Warehouses</NavLink>
                            <NavLink to="/reports">Reports</NavLink>
                            <NavLink to="/setup">Setup</NavLink>
                            {auth.status.loggedIn ? (
                                <Nav.Link href="#" onClick={onClickSignout}>
                                    Sign Out
                                </Nav.Link>
                            ) : (
                                <NavLink to="/signin">Sign In</NavLink>
                            )}
                        </Nav>
                        <Nav>
                            <Nav.Link href="#" onClick={onToggleFluid}>
                                <i className={`bi bi-arrows-angle-${global.fluid ? "contract" : "expand"}`}></i>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="mt-2" fluid={global.fluid}>
                {props.children}
            </Container>
        </>
    );
};

function NavLink(props: { to: string; children: any }) {
    const matches = !!useRouteMatch(props.to);
    return (
        <LinkContainer to={props.to}>
            <Nav.Link active={matches}>{props.children}</Nav.Link>
        </LinkContainer>
    );
}

export default SiteLayout;
