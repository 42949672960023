import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ZBreadcrumb from "../../../../components/breadcrumb/ZBreadcrumb";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../../components/loading/Loading";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import PageHeader from "../../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";
import PickFilters from "./PickZoneSummary";

interface IQueryResult {
    pickZone: IPickZone;
}

interface IWarehouseQueryResult {
    warehouse: {
        name: string;
    };
}

interface IStationSetsQueryResult {
    stationSets: {
        items: Array<IStationSet>;
    };
}

interface IStationSet {
    id: string;
    name: string;
    stations: Array<IStation>;
}

interface IStation {
    id: string;
    type: string;
    targetCarts: number;
    maxCarts: number;
}

interface IPickZone {
    id: string;
    warehouseId: string;
    name: string;
    active: boolean;
    pickable: boolean;
    isCart: boolean;
    priority: number;
    stationId: string | null;
}

interface IPickZoneModel {
    id: string;
    warehouseId: string;
    name: string;
    active: boolean;
    pickable: boolean;
    isCart: boolean;
    priority: number;
    stationId: string | null;
}

interface IEditResult {
    pickZone: {
        edit: IPickZone;
    };
}

interface IEditVariables {
    original: IPickZone;
    modified: IPickZone;
}

interface IDeleteResult {
    pickZone: {
        delete: string;
    };
}

interface IModal {
    show: boolean;
    original?: IPickZone;
}

const hiddenModal: IModal = {
    show: false,
};

const WarehouseQuery = `
query($id: ID!) {
  warehouse(id: $id) {
    name
  }
}
`;

const StationSetsQuery = `
query {
  stationSets {
    items {
      id
      name
      stations {
        id
        type
        targetCarts
        maxCarts
      }
    }
  }
}
`;

const PickZoneQuery = `
query ($id: ID!) {
  pickZone(id: $id) {
    id
    warehouseId
    name
    active
    pickable
    isCart
    priority
    stationId
  }
}
`;

const PickZoneMutation = `
mutation ($original: PickZoneInput!, $modified: PickZoneInput!) {
  pickZone {
    edit (original: $original, modified: $modified) {
      id
      warehouseId
      name
      active
      pickable
      isCart
      priority
      stationId
    }
  }
}
`;

const PickZoneDetails = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { pickZoneId } = useParams<{ pickZoneId: string }>();
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(PickZoneQuery, {
        variables: { id: pickZoneId },
        fetchPolicy: "no-cache",
    });
    const {
        data: warehouseData,
        error: warehouseError,
        refetch: warehouseRefetch,
    } = useQuery<IWarehouseQueryResult, { id: string }>(WarehouseQuery, { variables: { id: id }, fetchPolicy: "no-cache" });
    const {
        data: stationSetData,
        error: stationSetError,
        refetch: stationSetRefetch,
    } = useQuery<IStationSetsQueryResult>(StationSetsQuery, { fetchPolicy: "no-cache" });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(PickZoneMutation);
    const history = useHistory();

    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { pickZone { delete (id: $id) } }");

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (warehouseError) return <ErrorDisplay onClick={warehouseRefetch}>{warehouseError.message}</ErrorDisplay>;
    if (stationSetError) return <ErrorDisplay onClick={stationSetRefetch}>{stationSetError.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data || !warehouseData || !stationSetData) return <Loading />;
    if (!data.pickZone) return <ErrorDisplay onClick={refetch}>Pick Zone ID #{pickZoneId}</ErrorDisplay>;
    if (!warehouseData.warehouse) return <ErrorDisplay onClick={warehouseRefetch}>Warehouse</ErrorDisplay>;
    if (!stationSetData.stationSets) return <ErrorDisplay onClick={stationSetRefetch}>Warehouse</ErrorDisplay>;

    const onShowModal = () => {
        setModal({
            show: true,
        });
    };

    //hide modal
    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };

    const originalModel: IPickZoneModel = {
        id: data.pickZone.id,
        warehouseId: data.pickZone.warehouseId,
        name: data.pickZone.name,
        active: data.pickZone.active,
        pickable: data.pickZone.pickable,
        isCart: data.pickZone.isCart,
        priority: data.pickZone.priority,
        stationId: data.pickZone.stationId,
    };

    const onSaveChanges = (modified: IPickZoneModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalModel.id,
                    warehouseId: originalModel.warehouseId,
                    name: originalModel.name,
                    active: originalModel.active,
                    pickable: originalModel.pickable,
                    isCart: originalModel.isCart,
                    priority: originalModel.priority,
                    stationId: originalModel.stationId || null,
                },
                //pass in modified data
                modified: {
                    id: modified.id,
                    warehouseId: modified.warehouseId,
                    name: modified.name,
                    active: modified.active,
                    pickable: modified.pickable,
                    isCart: modified.isCart,
                    priority: modified.priority,
                    stationId: modified.stationId || null,
                },
            },
        }).then(
            (ret) => {
                data.pickZone = ret.data.pickZone.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing pickZone", err);
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== DELETE ===
        const pickZoneIdToDelete = originalModel.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this pick zone?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: pickZoneIdToDelete } }).then(
            //success
            () => {
                //redirects to the main pick zone page
                setSaving(false);
                let path = `/warehouses/${id}/pickzones`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting pick zone", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    return (
        <>
            <ZBreadcrumb url={`/warehouses/${id}/pickzones`} name={warehouseData.warehouse.name}>
                <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                    {data.pickZone.name}
                </PageHeader>
            </ZBreadcrumb>
            <Switch>
                <Route path="/warehouses/:id/pickzones/:pickZoneId" exact component={PickFilters} />
            </Switch>

            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Pick Zone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel title="Name" column sm={3} />
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="priority">
                                    <VLabel title="Priority" column sm={3} />
                                    <VControl type="text" pattern="^\d+$" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="stationId">
                                    <VLabel title="StationId" column sm={3} />
                                    <VSelect>
                                        <option value=""></option>
                                        {stationSetData.stationSets.items.map((stationSet) => {
                                            return stationSet.stations.map((station) => (
                                                <option value={station.id}>{stationSet.name + "-" + station.type}</option>
                                            ));
                                        })}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-3" fieldName="active">
                                    <VCheck label="Active" />
                                </VGroup>
                                <VGroup className="mb-3" fieldName="pickable">
                                    <VCheck label="Pickable" />
                                </VGroup>
                                <VGroup className="mb-3" fieldName="isCart">
                                    <VCheck label="IsCart" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default PickZoneDetails;
