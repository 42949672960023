import React from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { VButton, VControl, VForm, VGroup, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

interface IQueryResult {
    pickEquipments: {
        items: Array<IPickEquipment>;
    };
}

interface IPickEquipment {
    id: string;
    name: string;
}

interface IEditResult {
    pickEquipment: {
        edit: IPickEquipment;
    };
}

interface IEditVariables {
    original: IPickEquipment;
    modified: IPickEquipment;
}

interface IAddResult {
    pickEquipment: {
        add: IPickEquipment;
    };
}

interface IAddVariables {
    value: IPickEquipment;
}

interface IDeleteResult {
    pickEquipment: {
        delete: string;
    };
}

interface IModal {
    show: boolean;
    original?: IPickEquipment;
}

const hiddenModal: IModal = {
    show: false,
};

//define global functions
function sortPickEquipment(a: IPickEquipment, b: IPickEquipment) {
    return a.name > b.name ? 1 : -1;
}

const PickEquipmentQeury = `
{
    pickEquipments {
        items{
            id
            name
        }
    }
}
`;

const EditMutation = `
mutation ($original: PickEquipmentInput!, $modified: PickEquipmentInput!) {
    pickEquipment {
        edit(original: $original, modified: $modified) {
            id
            name
        }
    }
}
`;

const AddMutation = `
mutation ($value: PickEquipmentInput!) {
    pickEquipment {
        add(value: $value) {
            id
            name
        }
    }
}
`;

const DeleteMutation = `
mutation ($id: ID!) {
    pickEquipment{
        delete (id: $id)
    }
}
`;

const PickEquipmentIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    //=== set up queries and mutations ===
    //pick equipment list
    const { data, error, refetch } = useQuery<IQueryResult, {}>(PickEquipmentQeury, { fetchPolicy: "no-cache" });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditMutation);
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteMutation);
    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    //=== set up local functions ===

    //run when the edit button is pressed
    const onShowEditModal = (value: IPickEquipment) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const onSaveChanges = (modified: IPickEquipment) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        name: modified.name,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.pickEquipment.add;
                    data.pickEquipments.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding pick equipment", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: modal.original,
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        name: modified.name,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    const newValue = ret.data.pickEquipment.edit;
                    const oldIndex = data.pickEquipments.items.findIndex((x) => x.id === newValue.id);
                    if (oldIndex >= 0) data.pickEquipments.items[oldIndex] = newValue;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing pick equipment", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this pick equipment?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.pickEquipments.items.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.pickEquipments.items.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting pick equipment", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== set up any other variables needed for page render ===

    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IPickEquipment = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
          }
        : {
              id: "0",
              name: "",
          };

    const card = (active: boolean) => {
        // sort by sortorder, then name -- see function defined above
        const pickEquipments = data!.pickEquipments.items.filter((pickEquipment) => pickEquipment.name).sort(sortPickEquipment);

        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">Pick Equipment</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Name</th>

                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pickEquipments.map((pickEquipment) => (
                                <tr key={pickEquipment.id}>
                                    <td>{pickEquipment.name}</td>

                                    <td>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditModal(pickEquipment)}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };
    //render
    return (
        <>
            <PageHeader>Pick Equipment</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new pick equipment
                </Button>
            </p>
            {/* Active Station Sets */}
            {card(true)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Pick Equipment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel>Name</VLabel>
                                    <VControl type="text" required />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};
export default PickEquipmentIndex;
