import * as React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../components/pageheader/PageHeader";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import { useMutation, useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import PackageSummary from "./PackageSummary";
import ZBreadcrumb from "../../../components/breadcrumb/ZBreadcrumb";
import { IPackage } from "./PackageIndex";
import PackageType from "../../../enums/PackageType";
import StringHelper from "../../../helpers/StringHelper";

interface IQueryResult {
    package: IPackage;
}

interface IPackageModel {
    id: string;
    barcode: string | null;
    length: string;
    width: string;
    height: string;
    weight: string;
    type: string;
    active: boolean;
}

interface IEditResult {
    package: {
        edit: IPackage;
    };
}

interface IEditVariables {
    original: IPackage;
    modified: IPackage;
}

interface IDeleteResult {
    package: {
        delete: string;
    };
}
interface IModal {
    show: boolean;
    original?: IPackage;
}
const hiddenModal: IModal = {
    show: false,
};

const PackageQuery = `
query($id: ID!) {
    package(id: $id) {
      id
      barcode
      active
      height
      length
      weight
      width
      type
    }
  }
`;

const PackageMutation = `
mutation ($original: PackageInput!, $modified: PackageInput!) {
    package {
        edit (original: $original, modified: $modified) {
            id 
            barcode
            length
            width
            height
            weight
            type
            active
        }
    }
}`;

const PackageDetails = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(PackageQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(PackageMutation);
    const history = useHistory();

    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { package { delete (id: $id) } }");

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;
    if (!data.package) return <ErrorDisplay onClick={refetch}>Package ID #{id}</ErrorDisplay>;

    const onShowModal = () => {
        setModal({
            show: true,
        });
    };

    //hide modal
    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };
    // const history = useHistory();
    const originalModel: IPackageModel = {
        id: data.package.id,
        barcode: data.package.barcode,
        height: data.package.height?.toString(),
        length: data.package.length?.toString(),
        width: data.package.width?.toString(),
        weight: data.package.weight?.toString(),
        type: data.package.type,
        active: data.package.active,
    };

    const onSaveChanges = (modified: IPackageModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalModel.id,
                    barcode: originalModel.barcode,
                    length: parseFloat(originalModel.length),
                    width: parseFloat(originalModel.width),
                    height: parseFloat(originalModel.height),
                    weight: parseFloat(originalModel.weight),
                    type: originalModel.type,
                    active: originalModel.active,
                },
                //pass in modified data
                modified: {
                    id: modified.id,
                    barcode: modified.barcode,
                    length: parseFloat(modified.length),
                    width: parseFloat(modified.width),
                    height: parseFloat(modified.height),
                    weight: parseFloat(modified.weight),
                    type: modified.type,
                    active: modified.active,
                },
            },
        }).then(
            (ret) => {
                data.package = ret.data.package.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing package", err);
                alert(err.message);
            }
        );
    };
    const onDelete = () => {
        //=== DELETE ===
        const id = originalModel.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this package?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //redirects to the main package page
                setSaving(false);
                let path = `/setup/packages`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting package", err);
                //display the error message
                alert(err.message);
            }
        );
    };
    return (
        <>
            <ZBreadcrumb url={`/setup/packages/`} name="Packages">
                <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                    {data.package.length}x{data.package.width}x{data.package.height}-{data.package.type} - Barcode: {data.package.barcode}
                </PageHeader>
            </ZBreadcrumb>
            <Switch>
                <Route path="/setup/packages/:id" exact component={PackageSummary} />
            </Switch>

            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Packages</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="barcode">
                                    <VLabel title="Barcode" column sm={3} />
                                    <VControl type="text" />
                                </VGroup>
                                <Form.Group as={Row} className="mb-4">
                                    <VLabel title="Dims (in.)" column sm={3} fieldName="length,width,height" />
                                    <Col sm={9}>
                                        <Row>
                                            <Col xs={4} className="d-flex align-items-center">
                                                <span className="me-2">L</span>
                                                <VControl fieldName="length" />
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-center">
                                                <span className="me-2">W</span>
                                                <VControl fieldName="width" />
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-center">
                                                <span className="me-2">H</span>
                                                <VControl fieldName="height" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <VGroup className="mb-4" fieldName="weight">
                                    <Row>
                                        <VLabel title="Weight (lb.)" column sm={3} />
                                        <Col sm={9}>
                                            <VControl type="text" />
                                        </Col>
                                    </Row>
                                </VGroup>
                                <VGroup className="mb-4" fieldName="type">
                                    <VLabel title="Package Type" />
                                    <VSelect>
                                        {Object.values(PackageType)
                                            .filter((x) => x.toUpperCase() !== "PREPACKAGED")
                                            .map((x, index) => (
                                                <option value={x} key={index}>
                                                    {StringHelper.SnakeCaseToTitleCase(x)}
                                                </option>
                                            ))}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-3" fieldName="active">
                                    <VCheck label="Active" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default PackageDetails;
