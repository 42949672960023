import * as React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";
import PageHeader from "../../../components/pageheader/PageHeader";
import ZBreadcrumb from "../../../components/breadcrumb/ZBreadcrumb";
import LabelProviderServiceSummary from "./LabelProviderServiceSummary";

//define query and mutation models
interface IQueryResult {
    labelProvider: ILabelProvider;
}

interface ILabelProvider {
    id: string;
    name: string;
    isDefault: boolean;
}

interface ILabelProviderModel {
    id: string;
    name: string;
    isDefault: boolean;
}

interface IEditResult {
    labelProvider: {
        edit: ILabelProvider;
    };
}

interface IEditVariables {
    original: ILabelProvider;
    modified: ILabelProvider;
}

interface IDeleteResult {
    labelProvider: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: ILabelProvider;
}

const hiddenModal: IModal = {
    show: false,
};

const EditLabelProviderMutation = `
mutation ($original: LabelProviderInput!, $modified: LabelProviderInput!) {
  labelProvider {
    edit(original: $original, modified: $modified) {
      id
      name
      isDefault
    }
  }
}
`;

const DeleteLabelProviderMutation = `
mutation ($id: ID!) {
  labelProvider {
    delete(id: $id)
  }
}
`;

const LabelProviderQuery = `
query ($id: ID!) {
  labelProvider(id: $id) {
    id
    name
    isDefault
  }
}
`;

const LabelProviderDetail = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    //=== set up queries and mutations ===
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(LabelProviderQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditLabelProviderMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteLabelProviderMutation);

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data) return <Loading />;
    if (!data.labelProvider) return <ErrorDisplay onClick={refetch}>Label Provider ID #{id}</ErrorDisplay>;
    //=== set up local functions ===
    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const originalModel: ILabelProviderModel = {
        id: data.labelProvider.id,
        name: data.labelProvider.name,
        isDefault: data.labelProvider.isDefault,
    };

    //run when the save button is pressed
    const onSaveChanges = (modified: ILabelProviderModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalModel.id,
                    name: originalModel.name,
                    isDefault: originalModel.isDefault,
                },
                //pass in modified data
                modified: {
                    id: modified.id,
                    name: modified.name,
                    isDefault: modified.isDefault,
                },
            },
        }).then(
            (ret) => {
                data.labelProvider = ret.data.labelProvider.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing label provider", err);
                alert(err.message);
            }
        );
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = originalModel.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this label provider?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //redirects to the main supplier page
                setSaving(false);
                let path = `/setup/labelproviders`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting label provider", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== display page ===
    //render
    return (
        <>
            <ZBreadcrumb url={`/setup/labelproviders/`} name="Label Provider">
                <PageHeader onEdit={onShowAddModal}>{data.labelProvider.name}</PageHeader>
            </ZBreadcrumb>
            <Switch>
                <Route path="/setup/labelproviders/:id/" exact component={LabelProviderServiceSummary} />
            </Switch>
            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Edit Label Provider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel>Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required />
                                </VGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="isDefault">
                                    <VCheck label="Default" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default LabelProviderDetail;
