import styles from "./Info.module.scss";

export const Info = (props: { label: string; children?: JSX.Element | string | Array<JSX.Element | string> }) => (
    <>
        <p className="mb-2">
            <b>{props.label}</b>
        </p>
        <p className={styles.value}>{props.children}</p>
    </>
);
