import { useMutation, useQuery } from "@shane32/graphql";
import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { VButton, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";

//Product Interface
interface IProductQueryResult {
    product: IProduct | null;
    unitOfMeasures: {
        items: IUnitOfMeasure[];
    };
    suppliers: {
        items: ISupplier[];
    };
    packages: {
        items: Array<{
            id: string;
            length: number;
            width: number;
            height: number;
            type: string;
            active: boolean;
        }>;
    };
}

interface IProduct {
    id: string;
    type: string;
    unitOfMeasureId: string;
    description: string | null;
    dropShipMode: string;
    notes: string | null;
    purchaseNotes: string | null;
    supplierId: string | null;
    supplierSku: string | null;
    supplierLatestPrice: number | null;
    packageType: string | null;
    storageLength: number | null;
    storageWidth: number | null;
    storageHeight: number | null;
    storageWeight: number | null;
    packageId: string | null;
    active: boolean;
    sku: string;
    partNumbers: Array<{
        partNumber: string;
        sortOrder: number;
    }> | null;
    barcodes: Array<{
        barcode: string;
        sortOrder: number;
    }> | null;
}

interface IProductInputModel {
    id: string;
    type: string;
    unitOfMeasureId: string;
    description: string | null;
    dropShipMode: string;
    notes: string | null;
    purchaseNotes: string | null;
    supplierId: string | null;
    supplierSku: string | null;
    supplierLatestPrice: number | null;
    packageId: string | null;
    active: boolean;
    sku: string;
    partNumbers: Array<{
        partNumber: string;
        sortOrder: number;
    }> | null;
    barcodes: Array<{
        barcode: string;
        sortOrder: number;
    }> | null;
}

interface IUnitOfMeasure {
    id: string;
    name: string;
}

interface ISupplier {
    id: string;
    name: string;
    active: boolean;
}
interface IEditResult {
    product: {
        edit: IProduct;
    };
}

interface IEditVariables {
    original: IProductInputModel;
    modified: IProductInputModel;
}

interface IDelete {
    product: {
        deleted: string;
    };
}
interface IProductObj {
    type: string;
    unitOfMeasureId: string;
    sku: string;
    partNumbers: string;
    description: string;
    barcodes: string;
    dropShipMode: string;
    notes: string;
    purchaseNotes: string;
    supplierId: string;
    supplierSku: string;
    supplierLatestPrice: string;
    packageType: string;
    storageWidth: string;
    storageHeight: string;
    storageLength: string;
    storageWeight: string;
    packageId: string;
    active: boolean;
}

const innerQuery = `
    id
    type
    active
    unitOfMeasureId
    description
    dropShipMode
    notes
    purchaseNotes
    supplierId
    supplierLatestPrice
    supplierSku
    packageType
    storageLength
    storageWidth
    storageHeight
    storageWeight
    packageId
    partNumbers {
        partNumber
        sortOrder
    }
    sku
    barcodes {
        barcode
        sortOrder
    }
`;

const ProductQuery = `
query ($id: ID!) {
    product (id:$id) {
        ${innerQuery}
    }
    unitOfMeasures {
        items {
            id
            name
        }
    }
    suppliers {
        items {
            id
            name
            active
        }
    }
    packages {
        items {
            id
            length
            width
            height
            type
            active
        }
    }
}
`;

const ProductMutation = `
mutation ($original: ProductInput!, $modified: ProductInput!) {
    product {
        edit (original: $original, modified: $modified) {
        ${innerQuery}
        }
    }
}`;

const ProductEdit = () => {
    const [saving, setSaving] = React.useState(false);

    //Product Query
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<IProductQueryResult, { id: string }>(ProductQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const [runProductEdit] = useMutation<IEditResult, IEditVariables>(ProductMutation);
    //delete mutation
    const [runDelete] = useMutation<IDelete, { id: string }>("mutation ($id: ID!) { product { delete (id: $id) } }");
    const history = useHistory();

    //display message if failed to retrieve data
    //display loading if waiting for data to load
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (!data) return <Loading />;
    if (!data.product) return <ErrorDisplay onClick={refetch}>Product ID #{id}</ErrorDisplay>;

    //Filter and sort Skus and Barcodes
    const product = data.product;
    const unitOfMeasures = data.unitOfMeasures;
    const packages = data.packages.items
        .filter((x) => x.active === true)
        .sort((a, b) => a.length * a.width * a.height - b.length * b.width * b.height);
    const suppliers = data.suppliers.items.filter((supplier) => supplier.active === true); //todo: sort suppliers by name, and only request active suppliers
    const sortedBarcodes = product.barcodes ? product.barcodes.sort((a, b) => a.sortOrder - b.sortOrder) : null;
    const sortedPartNumbers = product.partNumbers ? product.partNumbers.sort((a, b) => a.sortOrder - b.sortOrder) : null;

    const originalProductObj: IProductObj = {
        type: product.type,
        unitOfMeasureId: product.unitOfMeasureId,
        sku: product.sku,
        partNumbers: sortedPartNumbers ? sortedPartNumbers.map((x) => x.partNumber).join("\n") : "",
        description: product.description || "",
        barcodes: sortedBarcodes ? sortedBarcodes.map((x) => x.barcode).join("\n") : "",
        dropShipMode: product.dropShipMode,
        notes: product.notes || "",
        purchaseNotes: product.purchaseNotes || "",
        supplierId: product.supplierId || "",
        supplierSku: product.supplierSku || "",
        supplierLatestPrice: product.supplierLatestPrice?.toFixed(2) || "",
        packageType: product.packageType || "",
        storageWidth: product.storageWidth?.toFixed(2) || "",
        storageHeight: product.storageHeight?.toFixed(2) || "",
        storageLength: product.storageLength?.toFixed(2) || "",
        storageWeight: product.storageWeight?.toFixed(2) || "",
        packageId: product.packageId || "",
        active: product.active,
    };
    //Additional Skus tab "WIP". Waiting for VControl to be fixed
    const onSaveProductChanges = (modified: IProductObj) => {
        setSaving(true);
        const barcodes = modified.barcodes ? modified.barcodes.replace("\r", "").split("\n") : null;
        const partNumbers = modified.partNumbers ? modified.partNumbers.replace("\r", "").split("\n") : null;
        runProductEdit({
            variables: {
                original: {
                    id: product.id,
                    description: product.description,
                    unitOfMeasureId: product.unitOfMeasureId,
                    type: product.type,
                    dropShipMode: product.dropShipMode,
                    notes: product.notes,
                    purchaseNotes: product.purchaseNotes,
                    packageId: product.packageId,
                    supplierId: product.supplierId,
                    supplierSku: product.supplierSku,
                    supplierLatestPrice: product.supplierLatestPrice,
                    active: product.active,
                    barcodes: product.barcodes,
                    sku: product.sku,
                    partNumbers: product.partNumbers,
                },
                modified: {
                    id: product.id,
                    description: modified.description,
                    unitOfMeasureId: modified.unitOfMeasureId,
                    type: modified.type,
                    dropShipMode: modified.dropShipMode,
                    notes: modified.notes,
                    purchaseNotes: modified.purchaseNotes,
                    packageId: modified.packageId === "" ? null : modified.packageId,
                    supplierId: modified.supplierId === "" ? null : modified.supplierId,
                    supplierSku: modified.supplierSku,
                    supplierLatestPrice: parseFloat(modified.supplierLatestPrice),
                    active: modified.active,
                    sku: product.sku,
                    partNumbers: partNumbers
                        ? partNumbers.map((x, i) => ({
                              partNumber: x,
                              sortOrder: i,
                          }))
                        : null,
                    barcodes: barcodes
                        ? barcodes.map((x, i) => ({
                              barcode: x,
                              sortOrder: i,
                          }))
                        : null,
                },
            },
        }).then(
            (ret) => {
                data.product = ret.data.product.edit;
                setSaving(false);
            },
            (err) => {
                setSaving(false);
                console.error("Error editing product", err);
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== VOID ===
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Product")) return;
        setSaving(true);
        //start void operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //redirects to the main product page
                setSaving(false);
                let path = `/products`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Product", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    return (
        <>
            <div>
                <VForm onSubmit={onSaveProductChanges} initialValue={originalProductObj} saving={saving} key={data.product.id}>
                    <Row>
                        <Col xxl={9}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">General</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="type">
                                                <VLabel title="Type" column sm={4} />
                                                <Col sm={8}>
                                                    <VSelect fieldName="type" required>
                                                        <option value=""></option>
                                                        <option value="PRODUCT">Product</option>
                                                        <option value="SERVICE">Service</option>
                                                        <option value="KIT">Kit</option>
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="unitOfMeasureId">
                                                <VLabel title="Units" column sm={4} />
                                                <Col sm={8}>
                                                    <VSelect required>
                                                        <option value=""></option>
                                                        {unitOfMeasures.items.map((units) => (
                                                            <option key={units.id} value={units.id}>
                                                                {units.name}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="sku">
                                                <VLabel title="Sku" column sm={4} />
                                                <Col sm={8}>
                                                    <VControl required disabled />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="partNumbers">
                                                <VLabel title="Part Numbers" column sm={4} />
                                                <Col sm={8}>
                                                    <VControl type="textarea" required />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <VGroup as={Row} className="mb-2" fieldName="description">
                                                <VLabel title="Description" column sm={4} lg={2} />
                                                <Col sm={8} lg={10}>
                                                    <VControl />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="barcodes">
                                                <VLabel title="Barcodes" column sm={4} />
                                                <Col sm={8}>
                                                    <VControl rows={3} type="textarea" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="dropShipMode">
                                                <VLabel title="Drop Ship Mode" column sm={4} />
                                                <Col sm={8}>
                                                    <VSelect required>
                                                        <option value=""></option>
                                                        <option value="NEVER">Never</option>
                                                        <option value="OPTIONAL">Optional</option>
                                                        <option value="ALWAYS">Always</option>
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <VGroup as={Row} className="mb-2" fieldName="notes">
                                                <VLabel title="Notes" column sm={4} lg={2} />
                                                <Col sm={8} lg={10}>
                                                    <VControl type="textarea" rows={4} />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="supplierId">
                                                <VLabel title="Supplier" column sm={4} />
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {suppliers.map((supplier) => (
                                                            <option key={supplier.id} value={supplier.id}>
                                                                {supplier.name}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="supplierSku">
                                                <VLabel title="Supplier Sku" column sm={4} />
                                                <Col sm={8}>
                                                    <VControl />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="supplierLatestPrice">
                                                <VLabel column sm={4}>
                                                    Latest Price
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="purchaseNotes">
                                                <VLabel column sm={4} title="Purchase Notes" />
                                                <Col sm={8}>
                                                    <VControl />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group as={Row} className="mb-2">
                                                <VLabel
                                                    title="Storage Dims"
                                                    fieldName="storageLength,storageWidth,storageHeight"
                                                    column
                                                    sm={3}
                                                />
                                                <Col xs={3} className="d-flex align-items-center">
                                                    <span className="me-2">L</span>
                                                    <VControl fieldName="storageLength" disabled />
                                                </Col>
                                                <Col xs={3} className="d-flex align-items-center">
                                                    <span className="me-2">W</span>
                                                    <VControl fieldName="storageWidth" disabled />
                                                </Col>
                                                <Col xs={3} className="d-flex align-items-center">
                                                    <span className="me-2">H</span>
                                                    <VControl fieldName="storageHeight" disabled />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} className="mb-2" fieldName="storageWeight">
                                                <VLabel title="Storage Weight" column sm={4} />
                                                <Col sm={8}>
                                                    <VControl disabled />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <VGroup as={Row} fieldName="packageType">
                                                <VLabel title="Package Type" column sm={3} />
                                                <Col sm={9}>
                                                    <VControl disabled />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <VGroup as={Row} fieldName="packageId">
                                                <VLabel title="Package (stock track)" column sm={4} />
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {packages.map((x) => (
                                                            <option key={x.id} value={x.id}>
                                                                {x.length}x{x.width}x{x.height} - {x.type}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <p>
                        <VButton variant="primary" className="me-2" type="submit">
                            Save
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </p>
                </VForm>
            </div>
        </>
    );
};

export default ProductEdit;
