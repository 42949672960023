import { useQuery } from "@shane32/graphql";
import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { ZbDbGraphQLClient } from "../../..";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { Info } from "../../../components/misc/Info";
import ProductType from "../../../enums/ProductType";
import PackageType from "../../../enums/PackageType";

//Product Interface
interface IProductQueryResult {
    product: IProduct | null;
}
interface IProduct {
    id: string;
    type: ProductType;
    unitOfMeasureId: string;
    description: string | null;
    dropShipMode: string;
    notes: string | null;
    supplier: { name: string } | null;
    supplierSku: string | null;
    supplierLatestPrice: number | null;
    packageType: PackageType | null;
    purchaseNotes: string | null;
    storageLength: number | null;
    storageWidth: number | null;
    storageHeight: number | null;
    storageWeight: number | null;
    shippingLength: number | null;
    shippingWidth: number | null;
    shippingHeight: number | null;
    shippingWeight: number | null;
    active: boolean;
    partNumbers: [
        {
            partNumber: string;
            sortOrder: number;
        }
    ];
    sku: string;
    barcodes: [
        {
            barcode: string;
            sortOrder: number;
        }
    ];
    unitOfMeasure: {
        name: string;
    };
    warehouseProducts: [
        {
            stockOnHand: number;
            onOrder: number;
            available: number;
            warehouse: {
                id: string;
                name: string;
            };
        }
    ];
    supplierWarehouseProducts: [
        {
            qtyInStock: number;
            supplierWarehouse: {
                id: string;
                name: string;
            };
        }
    ];
    includedProducts: [
        {
            quantity: number;
            includedProduct: {
                sku: string;
            };
        }
    ];
    locationProducts: [
        {
            stockOnHand: number;
            location: {
                id: string;
                name: string;
                warehouse: {
                    id: string;
                    name: string;
                };
            };
        }
    ];
}

const ProductQuery = `
query ($id: ID!) {
    product (id:$id) {
        id
        type
        unitOfMeasureId
        description
        dropShipMode
        notes
        supplierLatestPrice
        supplierSku
        packageType
        purchaseNotes
        storageLength
        storageWidth
        storageHeight
        storageWeight
        shippingLength
        shippingWidth
        shippingHeight
        shippingWeight
        active
        sku
        supplier {
            name
        }
        barcodes {
            barcode
            sortOrder
        }
        unitOfMeasure {
            name
        }
        warehouseProducts {
            stockOnHand
            onOrder
            available
            warehouse {
                id
                name
            }
        }
        partNumbers {
            partNumber
            sortOrder
        }
        supplierWarehouseProducts {
          qtyInStock
          supplierWarehouse { id name }
        }
        includedProducts {
            quantity
            includedProduct {
              sku
            }
        }
        locationProducts {
            stockOnHand
            location {
                name
                id
                warehouse {
                    id
                    name
                }
            }
        }
    }
}
`;

interface IImageResponse {
    part: {
        medias: {
            sortOrder: number;
            fullscreen: string;
            original: string;
            thumbnail: string;
        }[];
    };
}

const ProductSummary = () => {
    //Product Query
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<IProductQueryResult, { id: string }>(ProductQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });

    const imageQuery = useQuery<IImageResponse, any>(
        `
query ($sku: String!) {
  part(sku: $sku) {
    sku
    medias {
      sortOrder
      fullscreen: photoUri
      original: thumbnailUri(size: 500)
      thumbnail: thumbnailUri(size: 100)
    }
  }
}`,
        {
            variables: {
                sku: data && data.product ? data.product.sku : null,
            },
            client: ZbDbGraphQLClient,
            skip: !(data && data.product),
        }
    );

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    if (!data.product) return <ErrorDisplay onClick={refetch}>Product ID #{id}</ErrorDisplay>;

    const product = data.product;
    const sortedBarcodes = product.barcodes.sort((a, b) => a.sortOrder - b.sortOrder);
    const sortedPartNumbers = product.partNumbers.sort((a, b) => a.sortOrder - b.sortOrder);
    let productType = "";
    let dropShipMode = "";
    switch (product.type) {
        case "PRODUCT":
            productType = "Product";
            break;
        case "SERVICE":
            productType = "Service";
            break;
        case "KIT":
            productType = "Kit";
            break;
    }
    switch (product.dropShipMode) {
        case "ALWAYS":
            dropShipMode = "Always";
            break;
        case "NEVER":
            dropShipMode = "Never";
            break;
        case "OPTIONAL":
            dropShipMode = "Optional";
            break;
    }
    // if any Length, Width, Height exist then display else render blank
    let storageLength = (product.storageLength?.toString() !== undefined ? product.storageLength?.toString() + '"' : "??") + " x ";
    let storageWidth = (product.storageWidth?.toString() !== undefined ? product.storageWidth?.toString() + '"' : "??") + " x ";
    let storageHeight = product.storageHeight?.toString() !== undefined ? product.storageHeight?.toString() + '"' : "??";
    let storageDimensions =
        !product.storageLength && !product.storageWidth && !product.storageHeight ? "" : storageLength + storageWidth + storageHeight;
    let shippingLength = (product.shippingLength?.toString() !== undefined ? product.shippingLength?.toString() + '"' : "??") + " x ";
    let shippingWidth = (product.shippingWidth?.toString() !== undefined ? product.shippingWidth?.toString() + '"' : "??") + " x ";
    let shippingHeight = product.shippingHeight?.toString() !== undefined ? product.shippingHeight?.toString() + '"' : "??";
    let shippingDimensions =
        !product.shippingLength && !product.shippingWidth && !product.shippingHeight ? "" : shippingLength + shippingWidth + shippingHeight;
    //Display weight or render blank depending on data
    let storageWeight = product.storageWeight?.toString() !== undefined ? product.storageWeight?.toFixed(2) + " lbs" : "";
    let shippingWeight = product.shippingWeight?.toString() !== undefined ? product.shippingWeight?.toFixed(2) + " lbs" : "";

    const images = imageQuery.data
        ? imageQuery.data.part.medias
              .filter((x) => x.sortOrder)
              .sort((x, y) => x.sortOrder - y.sortOrder)
              .concat(imageQuery.data.part.medias.filter((x) => !x.sortOrder))
        : null;

    return (
        <>
            <Row>
                <Col lg={5} xxl={4}>
                    <div style={{ marginRight: "2rem" }} className="mt-5">
                        {images && images.length > 0 ? <ImageGallery items={images} showPlayButton={false} slideInterval={0} /> : null}
                    </div>
                </Col>
                <Col lg={7} xxl={8}>
                    <Row>
                        <Col xxl={8}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">General</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Info label="Type">{productType}</Info>
                                        </Col>
                                        <Col md={6}>
                                            <Info label="Unit of Measurement">{product.unitOfMeasure.name}</Info>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Info label="Sku">{product.sku}</Info>
                                        </Col>
                                        <Col md={6}>
                                            <Info label="Part Number(s)">
                                                {sortedPartNumbers.map((partNumber) => (
                                                    <React.Fragment key={partNumber.partNumber}>
                                                        {partNumber.partNumber}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Info>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Info label="Description">{product.description || ""}</Info>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Info label="Barcode(s)">
                                                {sortedBarcodes.map((barcode) => (
                                                    <React.Fragment key={`barcode-${barcode.barcode}`}>
                                                        {barcode.barcode}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Info>
                                        </Col>
                                        <Col md={6}>
                                            <Info label="Drop Ship">{dropShipMode}</Info>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Info label="Notes">{product.notes || ""}</Info>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Supplier Information</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Info label="Supplier">{product.supplier?.name || ""}</Info>
                                            <Info label="Supplier Sku">{product.supplierSku || ""}</Info>
                                        </Col>
                                        <Col md={6}>
                                            <Info label="Supplier Latest Price">
                                                {product.supplierLatestPrice ? `$${product.supplierLatestPrice.toFixed(2)}` : ""}
                                            </Info>
                                            <Info label="Purchase Notes">{product.purchaseNotes || ""}</Info>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Dimensions</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Info label="Storage Dimensions (L x W x H)">{storageDimensions}</Info>
                                        </Col>
                                        <Col md={6}>
                                            <Info label="Storage Weight">{storageWeight}</Info>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Info label="Shipping Dimensions (L x W x H)">{shippingDimensions}</Info>
                                        </Col>
                                        <Col md={6}>
                                            <Info label="Shipping Weight">{shippingWeight}</Info>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Availability</Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Warehouse</th>
                                                <th>Available</th>
                                                <th>On Order</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.warehouseProducts.map((warehouseProduct) => {
                                                return (
                                                    <tr key={warehouseProduct.warehouse.id}>
                                                        <td>{warehouseProduct.warehouse.name}</td>
                                                        <td>{warehouseProduct.available}</td>
                                                        <td>{warehouseProduct.onOrder}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <br />
                                                    Supplier Warehouse
                                                </th>
                                                <th>Qty in Stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.product.supplierWarehouseProducts.map((supplierWarehouseProduct) => (
                                                <tr key={supplierWarehouseProduct.supplierWarehouse.id}>
                                                    <td>{supplierWarehouseProduct.supplierWarehouse.name}</td>
                                                    <td>{supplierWarehouseProduct.qtyInStock}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Inventory</Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Warehouse</th>
                                                <th>Bin</th>
                                                <th>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.locationProducts.map((productLocation) => {
                                                return (
                                                    <tr key={productLocation.location.id}>
                                                        <td>{productLocation.location.warehouse.name}</td>
                                                        <td>{productLocation.location.name}</td>
                                                        <td>{productLocation.stockOnHand}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Included Products</Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Sku</th>
                                                <th>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.includedProducts.map((includedProduct) => {
                                                return (
                                                    <tr key={includedProduct.includedProduct.sku}>
                                                        <td>{includedProduct.includedProduct.sku}</td>
                                                        <td>{includedProduct.quantity}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default ProductSummary;
