import GroupBy from "../../../../helpers/GroupBy";

interface ILocation {
    aisle: string | null;
    side: string | null;
    rack: string | null;
    shelf: string | null;
    bin: string | null;
}

interface IWithLocation {
    location: ILocation;
}

interface ISortPickListOptions {
    /**
     * If `groupAndSortSides` is set to true, the items will be grouped by their side values,
     * and the groups will be sorted alphabetically by side. The internal order of items within each side group
     * will be maintained as per the primary sorting before grouping.
     */
    groupAndSortSides?: boolean;
}

const localCompareOptions: Intl.CollatorOptions = {
    numeric: true,
    sensitivity: "base",
};

function customSort<T extends IWithLocation>(a: T, b: T, aisle: string) {
    //determine multiplier based on aisle
    const aisleNumber = parseInt(aisle, 10) || 1;
    const multiplier = Math.floor((aisleNumber - 1) / 2) % 2 === 0 ? 1 : -1;
    //first, localeCompare by bin
    const binComparison = (a.location.bin ?? "").localeCompare(b.location.bin ?? "", undefined, localCompareOptions) * multiplier;
    return binComparison === 0
        ? //if bin values are equal, localeCompare by shelf
          (a.location.shelf ?? "").localeCompare(b.location.shelf ?? "", undefined, localCompareOptions) * multiplier
        : binComparison;
}

export function sortPickList<T extends IWithLocation>(list: T[], options?: ISortPickListOptions) {
    let groupedBySide = GroupBy(list, (l) => l.location.side ?? "");
    let result: T[] = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    for (let [_, values] of Array.from(groupedBySide.entries())) {
        let groupedByAisle = GroupBy(values, (v) => v.location.aisle ?? "");
        const sortedMapEntries = Array.from(groupedByAisle.entries()).sort((a, b) => {
            return String(a[0]).localeCompare(String(b[0]), undefined, localCompareOptions);
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        for (let [aisle, aisleValues] of sortedMapEntries) {
            const sortedLocations = aisleValues.sort((a, b) => customSort(a, b, aisle));
            result = result.concat(sortedLocations);
        }
    }

    // Handle grouping and sorting of sides if the option is provided
    if (options?.groupAndSortSides) {
        // Group items by side, maintaining the internal order of each group
        const groupedBySideAgain = GroupBy(result, (l) => l.location.side ?? "");
        const sortedGroupKeys = Array.from(groupedBySideAgain.keys()).sort((a, b) => a.localeCompare(b, undefined, localCompareOptions));

        // Create a new sorted result based on alphabetically sorted side groups
        let groupedResult: T[] = [];
        for (let key of sortedGroupKeys) {
            groupedResult = groupedResult.concat(groupedBySideAgain.get(key) ?? []);
        }

        result = groupedResult;
    }

    return result;
}
