import React from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IQueryResult {
    shippingCarriers: {
        items: Array<IShippingCarrier>;
    };
}

interface IShippingCarrier {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
}

interface IShippingCarrierModel {
    id: string;
    name: string;
    sortOrder: string;
    active: boolean;
}

interface IEditResult {
    shippingCarrier: {
        edit: IShippingCarrier;
    };
}

interface IEditVariables {
    original: IShippingCarrier;
    modified: IShippingCarrier;
}

interface IAddResult {
    shippingCarrier: {
        add: IShippingCarrier;
    };
}

interface IAddVariables {
    value: IShippingCarrier;
}

interface IDeleteResult {
    shippingCarrier: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: IShippingCarrier;
}

const hiddenModal: IModal = {
    show: false,
};

//define global functions
function sortShippingCarrier(a: IShippingCarrier, b: IShippingCarrier) {
    return a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : a.name > b.name ? 1 : -1;
}

const ShippingCarrierIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);

    //=== set up queries and mutations ===
    //Shipping Carrier list
    const {
        data,
        error,
        refetch: runRefetchList,
    } = useQuery<IQueryResult, {}>("{ shippingCarriers { items { id name sortOrder active } } }", { fetchPolicy: "no-cache" });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(
        "mutation ($original: ShippingCarrierInput!, $modified: ShippingCarrierInput!) { shippingCarrier { edit (original: $original, modified: $modified) { id name sortOrder active } } }"
    );
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(
        "mutation ($value: ShippingCarrierInput!) { shippingCarrier { add (value: $value) { id name sortOrder active } } }"
    );
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { shippingCarrier { delete (id: $id) } }");

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    //run when the save button is pressed
    const onSaveChanges = (modified: IShippingCarrierModel) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        name: modified.name,
                        sortOrder: parseFloat(modified.sortOrder),
                        active: modified.active,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    if (data) {
                        var newValue = ret.data.shippingCarrier.add;
                        data.shippingCarriers.items.push(newValue);
                    }
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding Shipping Carrier", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: modal.original,
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        name: modified.name,
                        sortOrder: parseFloat(modified.sortOrder),
                        active: modified.active,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    if (data) {
                        const newValue = ret.data.shippingCarrier.edit;
                        const oldIndex = data.shippingCarriers.items.findIndex((x) => x.id === newValue.id);
                        if (oldIndex >= 0) data.shippingCarriers.items[oldIndex] = newValue;
                    }
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing Shipping Carrier", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };
    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Shipping Carrier?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                if (data) {
                    //delete the entry from the local list
                    const oldIndex = data.shippingCarriers.items.findIndex((x) => x.id === id);
                    if (oldIndex >= 0) {
                        data.shippingCarriers.items.splice(oldIndex, 1);
                    }
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                }
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Shipping Carrier", err);
                //display the error message
                alert(err.message);
            }
        );
    };
    //=== set up any other variables needed for page render ===
    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IShippingCarrierModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              sortOrder: modal.original.sortOrder + "",
              active: modal.original.active,
          }
        : {
              id: "0",
              name: "",
              sortOrder: "",
              active: true,
          };
    const history = useHistory();
    const navTo = (e: React.MouseEvent, id: string) => {
        e.preventDefault();
        if (e.currentTarget === e.target) history.push("/setup/shippingcarriers/" + encodeURIComponent(id));
    };
    //this code displays a card and is nearly the same for active and inactive
    const card = (active: boolean) => {
        // search data where active or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        const shippingCarriers = data!.shippingCarriers.items
            .filter((shippingCarrier) => shippingCarrier.active === active)
            .sort(sortShippingCarrier);

        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} Shipping Carrier</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Shipping Carrier</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {shippingCarriers.map((shippingCarrier) => (
                                <tr key={shippingCarrier.id}>
                                    <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, shippingCarrier.id)}>
                                        <Link to={`/setup/shippingcarriers/${shippingCarrier.id}`}>{shippingCarrier.name}</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={runRefetchList}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    //render
    return (
        <>
            <PageHeader>Shipping Carriers</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new shipping carrier
                </Button>
            </p>

            {/* Active Shipping Carrier */}
            {card(true)}

            {/* Inactive Shipping Carrier */}
            {card(false)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Shipping Carrier</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel>Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="sortOrder">
                                    <VLabel>Sort Order</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" />
                                </VGroup>
                                <VGroup className="mb-3" fieldName="active">
                                    <VCheck label="Active" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default ShippingCarrierIndex;
