enum PurchaseOrderStatus {
    Draft = "DRAFT",
    Authorized = "AUTHORIZED",
    Ordered = "ORDERED",
    Invoiced = "INVOICED",
    Receiving = "RECEIVING",
    Completed = "COMPLETED",
    Void = "VOID",
    PendingApproval = "PENDING_APPROVAL",
    Received = "RECEIVED",
}

export default PurchaseOrderStatus;
