import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import React from "react";
import { useMutation, useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";

interface IQueryResult {
    salesChannel: ISalesChannel;
}

interface IShippingServiceQueryResult {
    shippingServices: {
        items: Array<IShippingService>;
    };
}

interface IShippingService {
    id: string;
    name: string;
    shippingCarrierId: string;
    shippingCarrier: {
        name: string;
    };
    active: boolean;
}

interface ISalesChannel {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    shippingServices: Array<ISalesChannelShippingServiceModel>;
}

interface ISalesChannelShippingServiceModel {
    shippingServiceId: string;
    shippingCarrierCode: string;
    shippingServiceCode: string;
    shouldMatch: boolean;
}

interface IEditResult {
    salesChannel: {
        edit: ISalesChannel;
    };
}

interface IEditVariables {
    original: ISalesChannel;
    modified: ISalesChannel;
}

interface IServiceModal {
    show: boolean;
    original?: ISalesChannelShippingServiceModel;
}

const hiddenServiceModal: IServiceModal = {
    show: false,
};

const ShippingServiceQuery = `
{
  shippingServices {
    items {
      id
      name
      shippingCarrierId
      shippingCarrier {
        name
      }
      active
    }
  }
}
`;

const salesChannelQuery = `
query ($id: ID!){
  salesChannel(id: $id){
    name
    id
    sortOrder
    active
    shippingServices {
      shippingServiceId
      shippingCarrierCode
      shippingServiceCode
      shouldMatch
    }
  }
}
`;

const salesChannelMutation = `
mutation ($original: SalesChannelInput!, $modified: SalesChannelInput!) {
  salesChannel {
    edit(original: $original, modified: $modified) {
      id
      name
      active
      sortOrder
      shippingServices {
        shippingServiceId
        shippingCarrierCode
        shippingServiceCode
        shouldMatch
      }
    }
  }
}`;

const SalesChannelSummary = () => {
    const [serviceModal, setServiceModal] = React.useState<IServiceModal>(hiddenServiceModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();

    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(salesChannelQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: shippingServiceData,
        error: shippingServiceError,
        refetch: shippingServiceRefetch,
    } = useQuery<IShippingServiceQueryResult, {}>(ShippingServiceQuery, { fetchPolicy: "no-cache" });

    const [runEdit] = useMutation<IEditResult, IEditVariables>(salesChannelMutation);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (shippingServiceError) return <ErrorDisplay onClick={shippingServiceRefetch}>{shippingServiceError.message}</ErrorDisplay>;

    if (!data || !shippingServiceData) return <Loading />;

    if (!data.salesChannel) return <ErrorDisplay onClick={refetch}>Shipping Carrier ID #{id}</ErrorDisplay>;

    const onShowEditServiceModal = (value: ISalesChannelShippingServiceModel) => {
        setServiceModal({
            show: true,
            original: value,
        });
    };

    const onShowAddServiceModal = () => {
        setServiceModal({ ...hiddenServiceModal, show: true });
    };

    const onHideServiceModal = () => {
        if (!saving) setServiceModal(hiddenServiceModal);
    };

    const originalServiceModel: ISalesChannelShippingServiceModel = serviceModal.original
        ? {
              shippingServiceId: serviceModal.original.shippingServiceId,
              shippingCarrierCode: serviceModal.original.shippingCarrierCode,
              shippingServiceCode: serviceModal.original.shippingServiceCode,
              shouldMatch: serviceModal.original.shouldMatch,
          }
        : {
              shippingServiceId: "",
              shippingCarrierCode: "",
              shippingServiceCode: "",
              shouldMatch: true,
          };

    const runSave = (
        originalService: ISalesChannelShippingServiceModel | null,
        modifiedService: ISalesChannelShippingServiceModel | null
    ) => {
        setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: data.salesChannel.id,
                    name: data.salesChannel.name,
                    sortOrder: data.salesChannel.sortOrder,
                    active: data.salesChannel.active,
                    shippingServices: originalService ? [originalService] : [],
                },
                modified: {
                    id: data.salesChannel.id,
                    name: data.salesChannel.name,
                    sortOrder: data.salesChannel.sortOrder,
                    active: data.salesChannel.active,
                    shippingServices: modifiedService ? [modifiedService] : [],
                },
            },
        }).then(
            (ret) => {
                setSaving(false);
                data.salesChannel = ret.data.salesChannel.edit;
                setServiceModal(hiddenServiceModal);
            },
            (err) => {
                setSaving(false);
                console.error("Error updating shipping service or shipping carrier", err);
                alert(err.message);
            }
        );
    };

    const onSaveServiceChanges = (modifiedService: ISalesChannelShippingServiceModel) => {
        runSave(serviceModal.original || null, {
            shippingServiceId: modifiedService.shippingServiceId,
            shippingCarrierCode: modifiedService.shippingCarrierCode,
            shippingServiceCode: modifiedService.shippingServiceCode,
            shouldMatch: modifiedService.shouldMatch,
        });
    };

    const onDeleteService = () => {
        if (!serviceModal.original) return;
        if (!window.confirm("Are you sure you want to delete this sales channel shipping service?")) return;
        runSave(serviceModal.original, null);
    };

    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddServiceModal}>
                    Add new sales channel shipping service
                </Button>
            </p>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Sales Channel Shipping Services</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Service Name</th>
                                <th>Sales Channel Shipping Carrier Code</th>
                                <th>Sales Channel Shipping Service Code</th>
                                <th>Should Match</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.salesChannel.shippingServices.map((service) => {
                                const shippingService = shippingServiceData.shippingServices.items.find(
                                    (x) => x.id === service.shippingServiceId && x.active === true
                                );
                                return (
                                    <tr key={service.shippingServiceId}>
                                        <td>{`${shippingService?.shippingCarrier.name} - ${shippingService?.name}`}</td>
                                        <td>{service.shippingCarrierCode}</td>
                                        <td>{service.shippingServiceCode}</td>
                                        <td>{service.shouldMatch}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                variant="white"
                                                className="ms-4"
                                                style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                onClick={() => onShowEditServiceModal(service)}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={serviceModal.show} onHide={onHideServiceModal}>
                <VForm onSubmit={onSaveServiceChanges} initialValue={originalServiceModel} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!serviceModal.original ? "Add" : "Edit"} Sales Channel Shipping Service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="shippingServiceId">
                                    <VLabel>Shipping Service</VLabel>
                                    <VSelect>
                                        <option value=""></option>
                                        {shippingServiceData.shippingServices.items.map((shippingService) => (
                                            <option key={shippingService.id} value={shippingService.id}>
                                                {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                            </option>
                                        ))}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-4" fieldName="shippingCarrierCode">
                                    <VLabel>Sales Channel Shipping Carrier Code</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="shippingServiceCode">
                                    <VLabel>Sales Channel Shipping Service Code</VLabel>
                                    <VControl type="text" />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="shouldMatch">
                                    <VCheck label="Should Match" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideServiceModal} className="me-auto">
                            Cancel
                        </VButton>
                        {serviceModal.original ? (
                            <VButton type="button" variant="danger" onClick={onDeleteService}>
                                Delete
                            </VButton>
                        ) : null}
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SalesChannelSummary;
