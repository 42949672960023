import { Link, useHistory, useLocation } from "react-router-dom";
import React from "react";
import { Button, Col, Form, InputGroup, Modal, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PageHeader from "../../components/pageheader/PageHeader";
import { AgGridReact } from "ag-grid-react";
import { GraphQLContext, useMutation, useQuery } from "@shane32/graphql";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import { GridApi, GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import { VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import StringHelper from "../../helpers/StringHelper";
import PurchaseOrderStatus from "../../enums/PurchaseOrderStatus";
import PurchaseOrderType from "../../enums/PurchaseOrderType";
import FormatDate from "../../helpers/FormatDate";

interface IPurchaseOrderQueryResult {
    purchaseOrders: {
        totalCount: number;
        items: Array<IPurchaseOrder>;
    };
    tabDraft: ITabCount;
    tabStock: ITabCount;
    tabDropShip: ITabCount;
    tabOrderedStock: ITabCount;
    tabOrderedDropShip: ITabCount;
    tabInvoiced: ITabCount;
    tabReceiving: ITabCount;
    tabCompleted: ITabCount;
    tabVoid: ITabCount;
    tabSearchResult: ITabCount;
}

interface ITabCount {
    totalCount: number;
}

interface ISuppliersQueryResult {
    suppliers: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            contacts: Array<{
                id: string;
                name: string;
            }>;
            addresses: Array<{
                sortOrder: number;
                address: {
                    id: string;
                    city: string | null;
                    state: string | null;
                };
            }>;
        }>;
    };
}

interface IWareHouseQueryResult {
    warehouses: {
        items: Array<IWarehouse>;
    };
}

interface IWarehouse {
    id: string;
    addressId: string;
    name: string;
    active: boolean;
}

//Interface for the purchaseOrder to display
interface IPurchaseOrder {
    id: string;
    supplier: {
        id: string;
        name: string;
    };
    status: PurchaseOrderStatus;
    orderDate: string | null;
    total: number;
    shippingAddressId: string;
    invoices: Array<{
        lineItems: Array<{
            expectedQuantity: number;
            actualQuantity: number;
        }>;
    }>;
    lineItems: Array<{
        quantity: number;
    }>;
}

//Model of the purchaseOrder mutation
interface IPurchaseOrderMutation {
    id: string;
    supplierId: string;
    supplierContactId: string | null;
    supplierAddressId: string | null;
    orderDate: string | null;
    blindReceipt: boolean;
    billingAddress: {
        id: string;
    };
    shippingAddress: {
        id: string;
    };
    status: PurchaseOrderStatus;
    subtotal: number;
    tax: number;
    total: number;
    reference: string | null;
    notes: string | null;
    isReturn: boolean;
    isDropShip: boolean;
}

//interface for only the modal that will be inputted by the user
interface IAddPurchaseOrder {
    supplierId: string;
    supplierContactId: string | null;
    supplierAddressId: string | null;
    addressId: string;
    reference: string;
    notes: string | null;
    orderDate: string;
}
interface IAddVariables {
    value: IPurchaseOrderMutation;
}
interface IAddResult {
    purchaseOrder: {
        add: IPurchaseOrderMutation;
    };
}

//interface for the drop ship modal
interface IDropShipExport {
    supplierId: string;
    purchaseOrderId: string | null;
}
interface IDropShipExportVariables {
    supplierId: string;
    purchaseOrderId: string | null;
}
interface IDropShipExportResult {
    api: {
        importExport: {
            export: {
                dropShipOrders: {
                    fulfillableOrdersCsv: string;
                    unfulfillableExcelString: string;
                };
            };
        };
    };
}

const SuppliersQuery = `
{
  suppliers {
    items {
      id
      name
      active
      contacts {
        id
        name
      }
      addresses {
        sortOrder
        address {
          id
          city
          state
          countryId
        }
      }
    }
  }
}
`;

const TabsCountQuery = `
  tabDraft:purchaseOrders(statuses: [DRAFT], type: STOCK_OR_DROP_SHIP) {
    totalCount
  }
  tabStock:purchaseOrders(statuses: [AUTHORIZED], type: STOCK) {
    totalCount
  }
  tabDropShip:purchaseOrders(statuses: [AUTHORIZED], type: DROP_SHIP) {
    totalCount
  }
  tabOrderedStock:purchaseOrders(statuses: [ORDERED], type: STOCK) {
    totalCount
  }
  tabOrderedDropShip:purchaseOrders(statuses: [ORDERED], type: DROP_SHIP) {
    totalCount
  }
  tabInvoiced:purchaseOrders(statuses: [INVOICED], type: STOCK_OR_DROP_SHIP) {
    totalCount
  }
  tabReceiving:purchaseOrders(statuses: [RECEIVING], type: STOCK_OR_DROP_SHIP) {
    totalCount 
  }
  tabCompleted:purchaseOrders(statuses: [COMPLETED], type: STOCK_OR_DROP_SHIP) {
    totalCount
  }
  tabVoid:purchaseOrders(statuses: [VOID], type: STOCK_OR_DROP_SHIP) {
    totalCount
  }
  tabSearchResult:purchaseOrders(statuses: $statuses, search: $search, type: null) {
    totalCount
  }`;

const PurchaseOrderQuery = `
query ($after: String, $search: String, $statuses: [PurchaseOrderStatus!], $type: PurchaseOrderType, $sortOrder: PurchaseOrdersSortOrder!) {
  purchaseOrders(after: $after, search: $search, statuses: $statuses, type: $type, sortOrder: $sortOrder) {
    totalCount
    items {
      id
      status
      orderDate
      supplier {
        id
        name
      }
      total
      shippingAddressId
      invoices {
        lineItems {
          expectedQuantity
          actualQuantity
        }
      }
      lineItems {
        quantity
      }
    }
  }
  ${TabsCountQuery}
}
`;

interface IPurchaseOrderQueryVariables {
    after: string | null;
    statuses: PurchaseOrderStatus[] | null;
    search: string | null;
    type: PurchaseOrderType | null;
    sortOrder: PurchaseOrdersSortOrder;
}

const enum PurchaseOrdersSortOrder {
    IdAscending = "ID_ASCENDING",
    IdDescending = "ID_DESCENDING",
    OrderDateAscending = "ORDER_DATE_ASCENDING",
    OrderDateDescending = "ORDER_DATE_DESCENDING",
    SupplierAscending = "SUPPLIER_ASCENDING",
    SupplierDescending = "SUPPLIER_DESCENDING",
    StatusAscending = "STATUS_ASCENDING",
    StatusDescending = "STATUS_DESCENDING",
    TotalAscending = "TOTAL_ASCENDING",
    TotalDescending = "TOTAL_DESCENDING",
    ReceivedAscending = "RECEIVED_ASCENDING",
    ReceivedDescending = "RECEIVED_DESCENDING",
    ExpectedAscending = "EXPECTED_ASCENDING",
    ExpectedDescending = "EXPECTED_DESCENDING",
}

const PurchaseOrderMutation = `
mutation ($value: PurchaseOrderInput!) {
    purchaseOrder {
        add (value: $value) {
            id
            supplierId
            supplierContactId
            supplierAddressId
            blindReceipt
            billingAddress {
                id
            }
            shippingAddress {
                id
            }
            reference
            orderDate
            notes
            subtotal
            tax
            total
        }
    }
}`;

const DropShipExportMutation = `
mutation ($supplierId: ID!, $purchaseOrderId: ID) {
  api{
    importExport{
      export{
        dropShipOrders (supplierId: $supplierId, purchaseOrderId: $purchaseOrderId){
          fulfillableOrdersCsv,
          unfulfillableExcelString
        }
      }
    }
  }
}`;

const PurchaseOrdersIndex = () => {
    const graphQLContext = React.useContext(GraphQLContext);
    const [modal, setModal] = React.useState(false);
    const [dropShipModal, setDropShipModal] = React.useState(false);
    const [gridApi, setGridApi] = React.useState<GridApi | null>(null);
    const [runAdd] = useMutation<IAddResult, IAddVariables>(PurchaseOrderMutation);
    const [runDropShipExport] = useMutation<IDropShipExportResult, IDropShipExportVariables>(DropShipExportMutation);
    const [saving, setSaving] = React.useState(false);

    //hold the state of the whatever suppplier is choosen and is saved to supplierId
    const [supplierId, setSupplierId] = React.useState<string | null>(null);
    const [dropShipExportAmount, setDropShipExportAmount] = React.useState<string>("all");
    const {
        data: warehousesData,
        error: warehousesError,
        refetch: warehousesRefetch,
    } = useQuery<IWareHouseQueryResult, {}>("{ warehouses { items { id name addressId active } } }", { fetchPolicy: "no-cache" });
    const {
        data: suppliersData,
        error: suppliersError,
        refetch: suppliersRefetch,
    } = useQuery<ISuppliersQueryResult, {}>(SuppliersQuery, { fetchPolicy: "no-cache" });
    const { data, error, refetch } = useQuery<IPurchaseOrderQueryResult, IPurchaseOrderQueryVariables>(PurchaseOrderQuery, {
        fetchPolicy: "no-cache",
        variables: { after: null, statuses: null, search: null, type: null, sortOrder: PurchaseOrdersSortOrder.IdAscending },
    });
    const location = useLocation();
    const history = useHistory();
    const search = React.useRef<string>("");
    const statusFilter = React.useRef<string>(location.pathname ?? "/purchaseorders");
    const onHideModal = () => {
        setModal(false);
        setSupplierId(null);
    };
    const onShowModal = () => setModal(true);
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (warehousesError) return <ErrorDisplay onClick={warehousesRefetch}>{warehousesError.message}</ErrorDisplay>;
    if (suppliersError) return <ErrorDisplay onClick={suppliersRefetch}>{suppliersError.message}</ErrorDisplay>;
    if (!data || !warehousesData || !suppliersData) return <Loading />;

    const dropShipSuppliers = suppliersData.suppliers.items
        .filter((x) => x.active && (x.name === "A&I Products" || x.name === "Costex Tractor Parts" || x.name === "Paint Valley Equipment"))
        .sort((x, y) => (x.name > y.name ? 1 : -1));
    const onHideDropShipModal = () => {
        setDropShipModal(false);
        setDropShipExportAmount("all");
    };
    const onShowDropShipModal = () => {
        setDropShipModal(true);
    };

    let purchaseOrderExpectedQuantity = new Map<string, number>();
    data.purchaseOrders.items.forEach((purchaseOrder) => {
        let totalExpectedQuantity = purchaseOrder.lineItems.reduce((accum2, lineItem) => accum2 + lineItem.quantity, 0);
        purchaseOrderExpectedQuantity.set(purchaseOrder.id, totalExpectedQuantity);
    });

    let purchaseOrderReceivedQuantity = new Map<string, number>();
    data.purchaseOrders.items.forEach((purchaseOrder) => {
        let totalReceivedQuantity = purchaseOrder.invoices.reduce(
            (accum, invoice) => accum + invoice.lineItems.reduce((accum2, lineItem) => accum2 + lineItem.actualQuantity, 0),
            0
        );
        purchaseOrderReceivedQuantity.set(purchaseOrder.id, totalReceivedQuantity);
    });

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);

        //gridApi!.onFilterChanged();
        let dataSource: IDatasource = {
            getRows: (params: IGetRowsParams) => {
                let statuses: Array<PurchaseOrderStatus> | null = null;
                let purchaseOrderType: PurchaseOrderType | null = null;
                let sortOrder: PurchaseOrdersSortOrder = PurchaseOrdersSortOrder.IdAscending;
                switch (statusFilter.current) {
                    case "/purchaseorders/draft":
                        statuses = [PurchaseOrderStatus.Draft];
                        purchaseOrderType = PurchaseOrderType.StockOrDropShip;
                        break;
                    case "/purchaseorders":
                        statuses = [PurchaseOrderStatus.Authorized];
                        purchaseOrderType = PurchaseOrderType.StockOrDropShip;
                        break;
                    case "/purchaseorders/stock":
                        statuses = [PurchaseOrderStatus.Authorized];
                        purchaseOrderType = PurchaseOrderType.Stock;
                        break;
                    case "/purchaseorders/dropship":
                        statuses = [PurchaseOrderStatus.Authorized];
                        purchaseOrderType = PurchaseOrderType.DropShip;
                        break;
                    case "/purchaseorders/ordered/stock":
                        statuses = [PurchaseOrderStatus.Ordered];
                        purchaseOrderType = PurchaseOrderType.Stock;
                        break;
                    case "/purchaseorders/ordered/dropship":
                        statuses = [PurchaseOrderStatus.Ordered];
                        purchaseOrderType = PurchaseOrderType.DropShip;
                        break;
                    case "/purchaseorders/invoiced":
                        statuses = [PurchaseOrderStatus.Invoiced];
                        purchaseOrderType = PurchaseOrderType.StockOrDropShip;
                        break;
                    case "/purchaseorders/receiving":
                        statuses = [PurchaseOrderStatus.Receiving];
                        purchaseOrderType = PurchaseOrderType.StockOrDropShip;
                        break;
                    case "/purchaseorders/completed":
                        statuses = [PurchaseOrderStatus.Completed];
                        purchaseOrderType = PurchaseOrderType.StockOrDropShip;
                        break;
                    case "/purchaseorders/void":
                        statuses = [PurchaseOrderStatus.Void];
                        purchaseOrderType = PurchaseOrderType.StockOrDropShip;
                        break;
                    case "/purchaseorders/searchresults":
                        statuses = null;
                        purchaseOrderType = null;
                        break;
                }

                let isAscending = true;
                if (params.sortModel.length > 0) {
                    if (params.sortModel[0].sort === "asc") {
                        isAscending = true;
                    } else if (params.sortModel[0].sort === "desc") {
                        isAscending = false;
                    }
                    switch (params.sortModel[0].colId) {
                        case "status":
                            sortOrder = isAscending ? PurchaseOrdersSortOrder.StatusAscending : PurchaseOrdersSortOrder.StatusDescending;
                            break;
                        case "purchaseOrderId":
                            sortOrder = isAscending ? PurchaseOrdersSortOrder.IdAscending : PurchaseOrdersSortOrder.IdDescending;
                            break;
                        case "orderDate":
                            sortOrder = isAscending
                                ? PurchaseOrdersSortOrder.OrderDateAscending
                                : PurchaseOrdersSortOrder.OrderDateDescending;
                            break;
                        case "supplier":
                            sortOrder = isAscending
                                ? PurchaseOrdersSortOrder.SupplierAscending
                                : PurchaseOrdersSortOrder.SupplierDescending;
                            break;
                        case "total":
                            sortOrder = isAscending ? PurchaseOrdersSortOrder.TotalAscending : PurchaseOrdersSortOrder.TotalDescending;
                            break;
                        case "received":
                            sortOrder = isAscending
                                ? PurchaseOrdersSortOrder.ReceivedAscending
                                : PurchaseOrdersSortOrder.ReceivedDescending;
                            break;
                        case "expected":
                            sortOrder = isAscending
                                ? PurchaseOrdersSortOrder.ExpectedAscending
                                : PurchaseOrdersSortOrder.ExpectedDescending;
                            break;
                    }
                }

                let after = params.startRow === 0 ? null : params.startRow.toString();

                let ret = graphQLContext.client.ExecuteQueryRaw<IPurchaseOrderQueryResult, IPurchaseOrderQueryVariables>({
                    query: PurchaseOrderQuery,
                    variables: {
                        after: after,
                        statuses: statuses,
                        type: purchaseOrderType,
                        search: search.current,
                        sortOrder: sortOrder,
                    },
                });

                ret.result.then(
                    //success
                    (result) => {
                        //failure
                        if (result.errors) {
                            console.log("Error fetching data: 1", result.errors);
                            return;
                        }
                        //feed data into AG Grid
                        params.successCallback(result.data!.purchaseOrders.items, result.data!.purchaseOrders.totalCount);
                    },
                    //failure
                    (result) => {
                        params.failCallback();
                        console.log("Error fetching data: 2", result);
                    }
                );
            },
        };
        params.api.setDatasource(dataSource);
    };
    //Columns for the ag grid
    const columns = [
        {
            field: "status",
            flex: 1,
        },
        {
            field: "purchaseOrderId",
            headerName: "Order #",
            cellRenderer: (params: ICellRendererParams) => {
                return <Link to={`/purchaseorders/${params?.data?.id}`}>ZPO-{params?.data?.id}</Link>;
            },
            flex: 1,
        },
        {
            field: "orderDate",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <>
                        {" "}
                        {StringHelper.IsNullOrWhitespace(params?.data?.orderDate)
                            ? null
                            : FormatDate.ConvertDateToLocalDateMMDDYYYY(params!.data!.orderDate)}
                    </>
                );
            },
            flex: 1,
        },
        {
            field: "supplier",
            headerName: "Supplier",
            cellRenderer: (params: ICellRendererParams) => {
                return <>{params?.data?.supplier?.name}</>;
            },
            flex: 1,
        },
        {
            field: "total",
            cellRenderer: (params: ICellRendererParams) => {
                return <>${params?.data?.total.toFixed(2)}</>;
            },
            flex: 1,
        },
        {
            field: "received",
            headerName: "Received",
            cellRenderer: (params: ICellRendererParams) => {
                return <>{purchaseOrderReceivedQuantity.get(params?.data?.id)}</>;
            },
            flex: 1,
        },
        {
            field: "expected",
            headerName: "Expected",
            cellRenderer: (params: ICellRendererParams) => {
                return <>{purchaseOrderExpectedQuantity.get(params?.data?.id)}</>;
            },
            flex: 1,
        },
    ];

    const originalModel: IAddPurchaseOrder = {
        supplierId: "",
        supplierContactId: "",
        supplierAddressId: "",
        addressId: "",
        reference: "",
        notes: "",
        orderDate: FormatDate.ConvertDateToLocalDatePicker(new Date()),
    };

    const originalDropShipModel: IDropShipExport = {
        supplierId: dropShipSuppliers.find((x) => x.name === "A&I Products")?.id || "",
        purchaseOrderId: null,
    };

    const onSavechanges = (value: IAddPurchaseOrder) => {
        setSaving(false);
        runAdd({
            variables: {
                value: {
                    id: "0",
                    supplierId: value.supplierId,
                    supplierContactId: StringHelper.IsNullOrWhitespace(value.supplierContactId) ? null : value.supplierContactId,
                    supplierAddressId: StringHelper.IsNullOrWhitespace(value.supplierAddressId) ? null : value.supplierAddressId,
                    blindReceipt: false,
                    shippingAddress: {
                        id: value.addressId,
                    },
                    billingAddress: {
                        id: value.addressId,
                    },
                    orderDate: StringHelper.IsNullOrWhitespace(value.orderDate) ? null : FormatDate.ConvertDateToUTC(value.orderDate),
                    status: PurchaseOrderStatus.Draft,
                    subtotal: 0,
                    tax: 0,
                    total: 0,
                    reference: StringHelper.IsNullOrWhitespace(value.reference) ? null : value.reference,
                    notes: StringHelper.IsNullOrWhitespace(value.notes) ? null : value.notes,
                    isDropShip: false,
                    isReturn: false,
                },
            },
        }).then(
            (ret) => {
                //set path to new sale ID that is created
                const path = `/purchaseorders/${ret.data?.purchaseOrder.add.id}/edit`;
                //push ID to history to redirect to new sale page
                history.push(path);
            },
            (err) => {
                setSaving(false);
                console.error("Error adding purchaseOrder", err);
                alert(err.message);
            }
        );
    };

    const onSaveDropShipChanges = (value: IDropShipExport) => {
        setSaving(false);

        runDropShipExport({
            variables: {
                supplierId: value.supplierId,
                purchaseOrderId: dropShipExportAmount === "all" ? null : value.purchaseOrderId,
            },
        }).then(
            (ret) => {
                if (
                    ret.data.api.importExport.export.dropShipOrders.fulfillableOrdersCsv.length === 0 &&
                    ret.data.api.importExport.export.dropShipOrders.unfulfillableExcelString.length === 0
                ) {
                    console.error("Drop Ship Purchase Order Export didn't return any data");
                    alert("Drop Ship Purchase Order Export didn't return any data");
                } else {
                    if (ret.data.api.importExport.export.dropShipOrders.fulfillableOrdersCsv.length !== 0) {
                        var fulfillableByteCharacters = atob(ret.data.api.importExport.export.dropShipOrders.fulfillableOrdersCsv);
                        var fulfillableByteNumbers = new Array(fulfillableByteCharacters.length);
                        for (var i = 0; i < fulfillableByteCharacters.length; i++) {
                            fulfillableByteNumbers[i] = fulfillableByteCharacters.charCodeAt(i);
                        }
                        var fulfillableByteArray = new Uint8Array(fulfillableByteNumbers);
                        var fulfillableFile = new Blob([fulfillableByteArray], { type: "text/csv" });
                        var fulfillableFileUrl = URL.createObjectURL(fulfillableFile);
                        var fulfillableFileLink = document.createElement(`a`);
                        fulfillableFileLink.href = fulfillableFileUrl;
                        fulfillableFileLink.download = `Fulfillable ${
                            dropShipSuppliers.find((x) => x.id === value.supplierId)?.name
                        } Drop Ship Orders`;
                        fulfillableFileLink.click();
                    }
                    if (ret.data.api.importExport.export.dropShipOrders.unfulfillableExcelString.length !== 0) {
                        var byteCharacters = atob(ret.data.api.importExport.export.dropShipOrders.unfulfillableExcelString);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var j = 0; j < byteCharacters.length; j++) {
                            byteNumbers[j] = byteCharacters.charCodeAt(j);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        var fileURL = URL.createObjectURL(file);
                        var fileLink = document.createElement(`a`);
                        fileLink.href = fileURL;
                        fileLink.download = `Unfulfillable ${
                            dropShipSuppliers.find((x) => x.id === value.supplierId)?.name
                        } Drop Ship Orders`;
                        fileLink.click();
                    }
                }
                onHideDropShipModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error exporting drop ship purchase orders", err);
                alert(err.message);
            }
        );
    };

    const onClickLinkContainer = (event: React.BaseSyntheticEvent, path: string) => {
        event.preventDefault();
        search.current = "";
        statusFilter.current = path;
        history.push(path);
        gridApi!.onFilterChanged();
    };

    const supplierForModal = suppliersData.suppliers.items.find((x) => x.id === supplierId);

    return (
        <>
            <PageHeader>Purchase Orders</PageHeader>
            <Row>
                <Col>
                    <Form.Label>Search</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Form
                        onSubmit={(event: React.BaseSyntheticEvent) => {
                            event.preventDefault();
                            search.current = event.currentTarget.querySelector("#searchValue").value;
                            statusFilter.current = "/purchaseorders/searchresults";
                            let path = "/purchaseorders/searchresults";
                            history.push(path);
                            gridApi!.onFilterChanged();
                        }}
                    >
                        <InputGroup>
                            <Form.Control id="searchValue" type="text" />
                            <Button type="submit" variant="primary">
                                Search
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md="3">
                    <Button onClick={onShowModal} variant="white">
                        Add purchase order
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="3">
                    <Button onClick={onShowDropShipModal} variant="primary">
                        Export drop ship orders
                    </Button>
                </Col>
            </Row>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3 mt-3">
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/draft"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/draft")}
                    >
                        <Nav.Link>Draft ({data.tabDraft.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders")}
                    >
                        <Nav.Link>Stock ({data.tabStock.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/dropship"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/dropship")}
                    >
                        <Nav.Link>Drop Ship ({data.tabDropShip.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/ordered/stock"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/ordered/stock")}
                    >
                        <Nav.Link>Ordered Stock ({data.tabOrderedStock.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/ordered/dropship"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/ordered/dropship")}
                    >
                        <Nav.Link>Ordered Drop Ship ({data.tabOrderedDropShip.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/invoiced"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/invoiced")}
                    >
                        <Nav.Link>Invoiced ({data.tabInvoiced.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/receiving"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/receiving")}
                    >
                        <Nav.Link>Receiving ({data.tabReceiving.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/completed"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/completed")}
                    >
                        <Nav.Link>Completed ({data.tabCompleted.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/void"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/void")}
                    >
                        <Nav.Link>Void ({data.tabVoid.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer
                        to="/purchaseorders/searchresults"
                        exact
                        onClick={(e: React.BaseSyntheticEvent) => onClickLinkContainer(e, "/purchaseorders/searchresults")}
                    >
                        <Nav.Link>Search Results ({data.tabSearchResult.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    //rowData={rowData}
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: function (params: ICellRendererParams) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
                            }
                        },
                    }}
                    onGridReady={onGridReady}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={30}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                ></AgGridReact>
            </div>
            <Modal show={modal} onHide={onHideModal} size={"lg" as any}>
                <VForm onSubmit={onSavechanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Purchase Order - Stock</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={6}>
                                <VGroup as={Row} className="mb-2" fieldName="supplierId">
                                    <Form.Label column sm={4}>
                                        Supplier
                                    </Form.Label>
                                    <Col sm={8}>
                                        <VSelect
                                            required
                                            onChange={(e) => {
                                                setSupplierId(e.currentTarget.value);
                                            }}
                                        >
                                            <option value=""></option>
                                            {suppliersData.suppliers.items.map((supplier) => (
                                                <option key={supplier.id} value={supplier.id}>
                                                    {supplier.name}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </Col>
                                </VGroup>
                                <VGroup as={Row} className="mb-2" fieldName="supplierContactId">
                                    <Form.Label column sm={4}>
                                        Contact
                                    </Form.Label>
                                    <Col sm={8}>
                                        <VSelect disabled={!supplierId}>
                                            <option value=""></option>
                                            {
                                                //There is no supplierId set since you are adding a purchaseOrder supplier can be undefined
                                                supplierForModal?.contacts.map((contact) => (
                                                    <option key={contact.id} value={contact.id}>
                                                        {contact.name}
                                                    </option>
                                                )) || null
                                            }
                                        </VSelect>
                                    </Col>
                                </VGroup>
                                <VGroup as={Row} className="mb-2" fieldName="supplierAddressId">
                                    <Form.Label column sm={4}>
                                        Vendor Address
                                    </Form.Label>
                                    <Col sm={8}>
                                        <VSelect disabled={!supplierId}>
                                            <option value=""></option>
                                            {
                                                //There is no supplierId set since you are adding a purchaseOrder supplier can be undefined
                                                supplierForModal?.addresses.map((address) => (
                                                    <option key={address.address.id} value={address.address.id}>
                                                        {address.address.city}, {address.address.state}
                                                    </option>
                                                )) || null
                                            }
                                        </VSelect>
                                    </Col>
                                </VGroup>
                            </Col>
                            <Col lg={6}>
                                <VGroup as={Row} className="mb-2" fieldName="orderDate">
                                    <VLabel column sm={4}>
                                        Order Date
                                    </VLabel>
                                    <Col sm={8}>
                                        <VControl type="date" />
                                    </Col>
                                </VGroup>
                                <VGroup as={Row} className="mb-2" fieldName="reference">
                                    <VLabel column sm={4}>
                                        Reference
                                    </VLabel>
                                    <Col sm={8}>
                                        <VControl type="text" />
                                    </Col>
                                </VGroup>
                                <VGroup as={Row} className="mb-2" fieldName="addressId">
                                    <VLabel column sm={4}>
                                        Warehouse
                                    </VLabel>
                                    <Col sm={8}>
                                        <VSelect required>
                                            <option value=""></option>
                                            {warehousesData.warehouses.items.map((warehouse) => (
                                                <option key={warehouse.id} value={warehouse.addressId}>
                                                    {warehouse.name}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </Col>
                                </VGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <VGroup as={Row} className="mb-2" fieldName="notes">
                                    <VLabel column sm={4} lg={2}>
                                        Notes
                                    </VLabel>
                                    <Col sm={8} lg={10}>
                                        <VControl type="textarea" rows={3} />
                                    </Col>
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save Changes
                        </Button>
                        <Button variant="secondary" onClick={onHideModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>
            <Modal show={dropShipModal} onHide={onHideDropShipModal}>
                <VForm onSubmit={onSaveDropShipChanges} initialValue={originalDropShipModel} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Export Drop Ship Orders</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="supplierId">
                                    <VLabel>Supplier</VLabel>
                                    <VSelect required>
                                        {dropShipSuppliers.map((supplier) => (
                                            <option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-4" fieldName="purchaseOrderId">
                                    <Col xs="auto">
                                        <Form.Check
                                            type="radio"
                                            name="exportAmount"
                                            value="single"
                                            label="Export single purchase order"
                                            checked={dropShipExportAmount === "single"}
                                            onClick={(event) => setDropShipExportAmount("single")}
                                        />
                                    </Col>
                                    <Col>
                                        <VControl
                                            type="text"
                                            disabled={dropShipExportAmount === "all"}
                                            required={dropShipExportAmount === "single"}
                                        />
                                    </Col>
                                </VGroup>
                                <Form.Group className="mb-4">
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            name="exportAmount"
                                            value="all"
                                            label="Export all purchase orders"
                                            id="exportAll"
                                            checked={dropShipExportAmount === "all"}
                                            onClick={(event) => setDropShipExportAmount("all")}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Export
                        </Button>
                        <Button variant="secondary" onClick={onHideDropShipModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};
export default PurchaseOrdersIndex;
