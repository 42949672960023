import { Card, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";
import PurchaseOrderStatus from "../../../enums/PurchaseOrderStatus";
import FormatDate from "../../../helpers/FormatDate";

//Product Interface
interface IProductQueryResult {
    product: IProduct | null;
}

interface IProduct {
    id: string;
    warehouseProducts: [
        {
            minimumBeforeReorder: number | null;
            reorderQuantity: number | null;
            stockOnHand: number;
            allocated: number;
            onOrder: number;
            available: number;
            warehouse: {
                id: string;
                name: string;
            };
        }
    ];
    supplierWarehouseProducts: [
        {
            qtyInStock: number;
            supplierWarehouse: {
                id: string;
                name: string;
            };
        }
    ];
    locationProducts: [
        {
            stockOnHand: number;
            location: {
                id: string;
                name: string;
                warehouse: {
                    id: string;
                    name: string;
                };
            };
        }
    ];
    unitOfMeasure: {
        id: string;
        name: string;
    };
}

//PurchaseOrderLineItem Interface
interface IPurchaseOrderQueryResult {
    purchaseOrders: {
        items: Array<IPurchaseOrders>;
    };
}

interface IPurchaseOrders {
    id: string;
    status: PurchaseOrderStatus;
    orderDate: string | null;
    lineItems: Array<{
        id: string;
        productId: string | null;
        quantity: number;
        total: number;
        product: {
            supplier: {
                id: string | null;
                name: string;
            };
        };
    }>;
}

const ProductQuery = `
query ($id: ID!) {
    product (id:$id) {
        id
        warehouseProducts {
            minimumBeforeReorder
            reorderQuantity 
            stockOnHand 
            allocated 
            onOrder 
            available
            warehouse { id name }
        }
        supplierWarehouseProducts {
          qtyInStock
          supplierWarehouse { id name }
        }
        locationProducts {
            stockOnHand
            location {
                name id
                warehouse { id name }
            }
        }
        unitOfMeasure { id name }
    }
}
`;
const purchaseOrderQuery = `

query ($productId: ID!) {
    purchaseOrders(productId: $productId) {
        items {
            id
            status
            orderDate
            lineItems {
                id
                quantity
                total
                productId
                product {
                    supplier {
                        id
                        name
                    }
                }
            }
        }
    }
}

`;
const ProductInventory = () => {
    //set useParams to get id for specific product
    const { id } = useParams<{ id: string }>();
    //Product query to get data
    const { data, error, refetch } = useQuery<IProductQueryResult, { id: string }>(ProductQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    //Product query to get data
    const {
        data: dataPurchaseOrder,
        error: errorPurchaseOrder,
        refetch: refetchPurchaseOrder,
    } = useQuery<IPurchaseOrderQueryResult, { productId: string }>(purchaseOrderQuery, {
        variables: { productId: id },
        fetchPolicy: "no-cache",
    });

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorPurchaseOrder) return <ErrorDisplay onClick={refetchPurchaseOrder}>{errorPurchaseOrder.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;
    if (!dataPurchaseOrder) return <Loading />;

    if (!data.product) return <ErrorDisplay onClick={refetch}>Product ID #{id}</ErrorDisplay>;

    return (
        <>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Warehouses</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Zbox Warehouse</th>
                                <th>Min Before Reorder</th>
                                <th>Reorder Qty</th>
                                <th>In Stock</th>
                                <th>Allocated</th>
                                <th>On Order</th>
                                <th>Available</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.product.warehouseProducts.map((warehouseProduct) => (
                                <tr key={warehouseProduct.warehouse.id}>
                                    <td width="25%">{warehouseProduct.warehouse.name}</td>
                                    <td>{warehouseProduct.minimumBeforeReorder}</td>
                                    <td>{warehouseProduct.reorderQuantity}</td>
                                    <td>{warehouseProduct.stockOnHand}</td>
                                    <td>{warehouseProduct.allocated}</td>
                                    <td>{warehouseProduct.onOrder}</td>
                                    <td>{warehouseProduct.available}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Table hover className="w-50">
                        <thead>
                            <tr>
                                <th>
                                    <br />
                                    Supplier Warehouse
                                </th>
                                <th>Qty in Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.product.supplierWarehouseProducts.map((supplierWarehouseProduct) => (
                                <tr key={supplierWarehouseProduct.supplierWarehouse.id}>
                                    <td width="25%">{supplierWarehouseProduct.supplierWarehouse.name}</td>
                                    <td>{supplierWarehouseProduct.qtyInStock}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Inventory Status</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Warehouse</th>
                                <th>Bin</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.product.locationProducts.map((locationProduct) => {
                                return (
                                    <tr key={locationProduct.location.id}>
                                        <td width="25%">{locationProduct.location.warehouse.name}</td>
                                        <td>{locationProduct.location.name}</td>
                                        <td>{locationProduct.stockOnHand}</td>
                                        <td>{data.product?.unitOfMeasure.name}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Pending Orders</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Order Date</th>
                                <th>Order #</th>
                                <th>Supplier</th>
                                <th>Quantity</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataPurchaseOrder.purchaseOrders.items
                                .filter((x) => x.status !== PurchaseOrderStatus.Void)
                                .map((purchaseOrder) => {
                                    return purchaseOrder.lineItems
                                        .filter((x) => x.productId === id)
                                        .map((lineItem) => (
                                            <tr key={lineItem.id}>
                                                <td>{FormatDate.ConvertDateToLocalDateMMDDYYYY(purchaseOrder.orderDate)}</td>
                                                <td>
                                                    <Link to={`/purchaseorders/${purchaseOrder.id}`}>ZPO-{purchaseOrder.id}</Link>
                                                </td>
                                                <td>{lineItem.product?.supplier?.name}</td>
                                                <td>{lineItem.quantity}</td>
                                                <td>{`$${lineItem.total.toFixed(2)}`}</td>
                                            </tr>
                                        ));
                                })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default ProductInventory;
