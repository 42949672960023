export default class StringHelper {
    public static IsNullOrWhitespace(str: string | null | undefined) {
        return str === null || str === undefined || /^\s*$/.test(str);
    }

    public static SnakeCaseToTitleCase(str: string) {
        return str
            .toLowerCase()
            .split("_")
            .filter((x) => x.length > 0)
            .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
            .join(" ");
    }
}
