import { useState } from "react";
import useTimer from "../../hooks/useTimer";
import styles from "./Loading.module.scss";

interface IProps {
    mobileSm?: boolean;
    shoppingCart?: boolean;
    hostedField?: boolean;
}

const Loading = (props: IProps) => {
    //don't display the loading graphic for the first 500ms
    const [display, setDisplay] = useState(false);
    useTimer(
        () => {
            setDisplay(true);
        },
        500,
        false
    );
    if (!display) return null;

    //some of this is leftover from Zbox.com and can be removed
    return (
        <div
            className={
                props.hostedField
                    ? `${styles.container} ${styles["hosted-field"]}`
                    : props.mobileSm
                    ? props.shoppingCart
                        ? `${styles.container} ${styles.cart} ${styles["mobile-sm"]}`
                        : `${styles.container} ${styles["mobile-sm"]}`
                    : props.shoppingCart
                    ? `${styles.container} ${styles.cart} loading`
                    : `${styles.container} loading`
            }
        />
    );
};

export default Loading;
