import { Col, Modal, Nav, Row } from "react-bootstrap";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../components/pageheader/PageHeader";
import { LinkContainer } from "react-router-bootstrap";
import { VButton, VCheck, VControl, VForm, VGroup, VLabel } from "@shane32/vform";
import React from "react";
import { useMutation, useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import ShippingCarrierSummary from "../../setup/shipping_carrier/ShippingCarrierSummary";

//define query and mutation models
interface IQueryResult {
    shippingCarrier: IShippingCarrier;
}

interface IShippingCarrier {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
}

interface IShippingCarrierModel {
    id: string;
    name: string;
    sortOrder: string;
    active: boolean;
}

interface IEditResult {
    shippingCarrier: {
        edit: IShippingCarrier;
    };
}
interface IEditVariables {
    original: IShippingCarrier;
    modified: IShippingCarrier;
}
interface IDeleteResult {
    shippingCarrier: {
        delete: string;
    };
}
//define modal state & default state
interface IModal {
    show: boolean;
    original?: IShippingCarrier;
}
const hiddenModal: IModal = {
    show: false,
};

const ShippingCarrierQuery = `
query ($id: ID!) {
    shippingCarrier (id: $id) {
            id 
            name 
            sortOrder 
            active
    }
}
`;

const ShippingCarrierMutation = `
mutation ($original: ShippingCarrierInput!, $modified: ShippingCarrierInput!) {
    shippingCarrier {
        edit (original: $original, modified: $modified) {
                id 
                name 
                sortOrder 
                active
        }
    }
}`;

const ShippingCarrierDetails = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    //=== set up queries and mutations ===
    //Shipping Carrier list
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(ShippingCarrierQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(ShippingCarrierMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { shippingCarrier { delete (id: $id) } }");

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;
    if (!data.shippingCarrier) return <ErrorDisplay onClick={refetch}>Shipping Carrier ID #{id}</ErrorDisplay>;

    //show modal
    const onShowModal = () => {
        setModal({
            show: true,
        });
    };

    //hide modal
    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };

    //=== set up any other variables needed for page render ===
    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IShippingCarrierModel = {
        id: data.shippingCarrier.id,
        name: data.shippingCarrier.name,
        sortOrder: data.shippingCarrier.sortOrder.toString(),
        active: data.shippingCarrier.active,
    };

    const onSaveChanges = (modified: IShippingCarrierModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalModel.id,
                    name: originalModel.name,
                    sortOrder: parseFloat(originalModel.sortOrder),
                    active: originalModel.active,
                },
                //pass in modified data
                modified: {
                    id: modified.id,
                    name: modified.name,
                    sortOrder: parseFloat(modified.sortOrder),
                    active: modified.active,
                },
            },
        }).then(
            (ret) => {
                data.shippingCarrier = ret.data.shippingCarrier.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing shipping carrier", err);
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== DELETE ===
        const id = originalModel.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this shipping carrier?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //redirects to the main supplier page
                setSaving(false);
                let path = `/setup/shippingcarriers`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting shipping carrier", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    return (
        <>
            <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                {data.shippingCarrier.name}
            </PageHeader>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/setup/shippingcarriers/${id}`} exact>
                        <Nav.Link>Summary</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/setup/shippingcarriers/:id" exact component={ShippingCarrierSummary} />
            </Switch>
            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Shipping Carrier</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="name">
                                    <VLabel>Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="sortOrder">
                                    <VLabel>Sort Order</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" />
                                </VGroup>
                                <VGroup className="mb-3" fieldName="active">
                                    <VCheck label="Active" />
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};
export default ShippingCarrierDetails;
