import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { VButton, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

interface IQueryResult {
    packFilters: {
        items: Array<IPackFilter>;
    };
}
interface IPackFilter {
    id: string;
    size: string;
    package: string;
    lengthMin: number;
    lengthMax: number;
    widthMin: number;
    widthMax: number;
    heightMin: number;
    heightMax: number;
    weightMin: number;
    weightMax: number;
    prepack: boolean | null;
    sortOrder: number;
}

interface IPackFilterModel {
    id: string;
    size: string;
    package: string;
    lengthMin: string;
    lengthMax: string;
    widthMin: string;
    widthMax: string;
    heightMin: string;
    heightMax: string;
    weightMin: string;
    weightMax: string;
    prepack: string | null;
    sortOrder: string;
}

interface IEditResult {
    packFilter: {
        edit: IPackFilter;
    };
}

interface IEditVariables {
    original: IPackFilter;
    modified: IPackFilter;
}

interface IAddResult {
    packFilter: {
        add: IPackFilter;
    };
}

interface IAddVariables {
    value: IPackFilter;
}

interface IDeleteResult {
    packFilter: {
        delete: string;
    };
}

interface IModal {
    show: boolean;
    original?: IPackFilter;
}

const hiddenModal: IModal = {
    show: false,
};

//define global functions
function sortPackFilters(a: IPackFilter, b: IPackFilter) {
    return a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : a.package > b.package ? 1 : -1;
}

const PackFilterQuery = `
{
    packFilters {
        items{
            id
            size
            package
            lengthMin
            lengthMax
            widthMin
            widthMax
            heightMin
            heightMax
            weightMin
            weightMax
            prepack
            sortOrder
        }
    }
}
`;

const EditMutation = `
mutation ($original: PackFilterInput!, $modified: PackFilterInput!) {
    packFilter {
        edit(original: $original, modified: $modified) {
            id
            size
            package
            lengthMin
            lengthMax
            widthMin
            widthMax
            heightMin
            heightMax
            weightMin
            weightMax
            prepack
            sortOrder
        }
    }
}
`;

const AddMutation = `
mutation ($value: PackFilterInput!) {
    packFilter {
        add(value: $value) {
            id
            size
            package
            lengthMin
            lengthMax
            widthMin
            widthMax
            heightMin
            heightMax
            weightMin
            weightMax
            prepack
            sortOrder

        }
    }
}
`;

const DeleteMutation = `
mutation ($id: ID!) {
    packFilter{
        delete (id: $id)
    }
}
`;

const PackFilterIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    //=== set up queries and mutations ===
    //packFilter list
    const { data, error, refetch } = useQuery<IQueryResult, {}>(PackFilterQuery, { fetchPolicy: "no-cache" });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditMutation);
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteMutation);
    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    //=== set up local functions ===

    //run when the edit button is pressed
    const onShowEditModal = (value: IPackFilter) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    let pack: boolean | null = null;

    const modifiedPack = (modified: IPackFilterModel) => {
        switch (modified.prepack) {
            case "Packaged":
                pack = true;
                break;
            case "Not Packaged":
                pack = false;
                break;
            case "Either":
                pack = null;
                break;
        }
        return pack;
    };

    const onSaveChanges = (modified: IPackFilterModel) => {
        modifiedPack(modified);
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        size: modified.size,
                        package: modified.package,
                        lengthMin: parseFloat(modified.lengthMin),
                        lengthMax: parseFloat(modified.lengthMax),
                        widthMin: parseFloat(modified.widthMin),
                        widthMax: parseFloat(modified.widthMax),
                        heightMin: parseFloat(modified.heightMin),
                        heightMax: parseFloat(modified.heightMax),
                        weightMin: parseFloat(modified.weightMin),
                        weightMax: parseFloat(modified.weightMax),
                        prepack: pack,
                        sortOrder: parseFloat(modified.sortOrder),
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.packFilter.add;
                    data.packFilters.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding pack filter", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: modal.original,
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        size: modified.size,
                        package: modified.package,
                        lengthMin: parseFloat(modified.lengthMin),
                        lengthMax: parseFloat(modified.lengthMax),
                        widthMin: parseFloat(modified.widthMin),
                        widthMax: parseFloat(modified.widthMax),
                        heightMin: parseFloat(modified.heightMin),
                        heightMax: parseFloat(modified.heightMax),
                        weightMin: parseFloat(modified.weightMin),
                        weightMax: parseFloat(modified.weightMax),
                        prepack: pack,
                        sortOrder: parseFloat(modified.sortOrder),
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    const newValue = ret.data.packFilter.edit;
                    const oldIndex = data.packFilters.items.findIndex((x) => x.id === newValue.id);
                    if (oldIndex >= 0) data.packFilters.items[oldIndex] = newValue;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing pack filter", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this pack filter?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.packFilters.items.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.packFilters.items.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting pack filter", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== set up any other variables needed for page render ===

    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IPackFilterModel = modal.original
        ? {
              id: modal.original.id,
              size: modal.original.size,
              package: modal.original.package,

              lengthMin: modal.original.lengthMin.toString(),
              lengthMax: modal.original.lengthMax.toString(),
              widthMin: modal.original.widthMin.toString(),
              widthMax: modal.original.widthMax.toString(),
              heightMin: modal.original.heightMin.toString(),
              heightMax: modal.original.heightMax.toString(),
              weightMin: modal.original.weightMin.toString(),
              weightMax: modal.original.weightMax.toString(),
              prepack: modal.original.prepack === null ? "Either" : modal.original.prepack === true ? "Packaged" : "Not Packaged",
              sortOrder: modal.original.sortOrder.toString(),
          }
        : {
              id: "0",
              size: "",
              package: "",
              lengthMin: "",
              lengthMax: "",
              widthMin: "",
              widthMax: "",
              heightMin: "",
              heightMax: "",
              weightMin: "",
              weightMax: "",
              prepack: "Either",
              sortOrder: "",
          };

    const card = () => {
        // search data where setting or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        const packFilters = data!.packFilters.items.sort(sortPackFilters);
        return (
            <Card className="border-primary">
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">Pack Filter</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Sort Order</th>
                                <th>Size</th>
                                <th>Package</th>
                                <th>Width Min-Max (in)</th>
                                <th>Length Min-Max (in)</th>
                                <th>Height Min-Max (in)</th>
                                <th>Weight Min-Max (lbs)</th>
                                <th>Prepack</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {packFilters.map((packFilter) => (
                                <tr key={packFilter.id}>
                                    <td>{packFilter.sortOrder}</td>
                                    <td>{packFilter.size}</td>
                                    <td>{packFilter.package}</td>
                                    <td>
                                        {packFilter.widthMin} - {packFilter.widthMax}
                                    </td>
                                    <td>
                                        {packFilter.lengthMin} - {packFilter.lengthMax}
                                    </td>
                                    <td>
                                        {packFilter.heightMin} - {packFilter.heightMax}
                                    </td>
                                    <td>
                                        {packFilter.weightMin} - {packFilter.weightMax}
                                    </td>
                                    <td>
                                        {packFilter.prepack === null ? "Either" : packFilter.prepack === true ? "Packaged" : "Not Packaged"}
                                    </td>
                                    <td></td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditModal(packFilter)}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };
    //render
    return (
        <>
            <PageHeader>Pack Filters</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new pack filter
                </Button>
            </p>
            {/* Active Station Sets */}
            {card()}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Pack Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="size">
                                    <VLabel>Package</VLabel>
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="package">
                                    <VLabel>Package</VLabel>
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup className="mb-4" fieldName="sortOrder">
                                    <VLabel>Sort Order</VLabel>
                                    <VControl type="text" required />
                                </VGroup>
                                <VGroup as={Row} className="mb-4" fieldName="prepack">
                                    <VLabel>Prepack</VLabel>
                                    <Col>
                                        <VSelect>
                                            <option value="Either">Either</option>
                                            <option value="Packaged">Packaged</option>
                                            <option value="Not Packaged">Not Packaged</option>
                                        </VSelect>
                                    </Col>
                                </VGroup>
                                <Form.Group as={Row} className="mb-4">
                                    <VLabel title="Width (in) Min-Max" fieldName="widthMin,widthMax" column sm={5} />
                                    <Col sm={12}>
                                        <Row>
                                            <Col xs={5}>
                                                <VControl required fieldName="widthMin" />
                                            </Col>
                                            <Col className="d-flex align-items-center justify-content-center">-</Col>
                                            <Col xs={5}>
                                                <VControl required fieldName="widthMax" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-4">
                                    <VLabel title="Length (in) Min-Max" fieldName="lengthMin,lengthMax" column sm={5} />
                                    <Col sm={12}>
                                        <Row>
                                            <Col xs={5}>
                                                <VControl required fieldName="lengthMin" />
                                            </Col>
                                            <Col className="d-flex align-items-center justify-content-center">-</Col>
                                            <Col xs={5}>
                                                <VControl required fieldName="lengthMax" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-4">
                                    <VLabel title="Height (in) Min-Max" fieldName="heightMin,heightMax" column sm={5} />
                                    <Col sm={12}>
                                        <Row>
                                            <Col xs={5}>
                                                <VControl required fieldName="heightMin" />
                                            </Col>
                                            <Col className="d-flex align-items-center justify-content-center">-</Col>
                                            <Col xs={5}>
                                                <VControl required fieldName="heightMax" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-4">
                                    <VLabel title="Weight (in) Min-Max" fieldName="weightMin,weightMax" column sm={5} />
                                    <Col sm={12}>
                                        <Row>
                                            <Col xs={5}>
                                                <VControl required fieldName="weightMin" />
                                            </Col>
                                            <Col className="d-flex align-items-center justify-content-center">-</Col>
                                            <Col xs={5}>
                                                <VControl required fieldName="weightMax" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};
export default PackFilterIndex;
