import * as React from "react";

interface IGlobalContext {
    fluid: boolean;
    setFluid: (value: boolean) => void;
}

const GlobalContext = React.createContext<IGlobalContext>({ setFluid: () => {}, fluid: false });

export default GlobalContext;

export const GlobalController = (props: React.PropsWithChildren<{}>) => {
    const [fluid, setFluid] = React.useState(false);
    const contextState: IGlobalContext = {
        fluid,
        setFluid,
    };
    return <GlobalContext.Provider value={contextState}>{props.children}</GlobalContext.Provider>;
};
