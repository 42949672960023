import { GraphQLContext } from "@shane32/graphql";
import { GridApi, ICellRendererParams, IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FormatDate from "../../../helpers/FormatDate";
import StringHelper from "../../../helpers/StringHelper";

interface IMovementQueryResult {
    locationHistories: {
        totalCount: number;
        items: Array<IMovement>;
    };
}

interface IMovement {
    id: string;
    date: string;
    type: string;
    oldQuantity: number;
    newQuantity: number;
    location: {
        id: string;
        name: string;
    };
    referenceLocation: {
        id: string;
        name: string;
    };
    referencePurchaseInvoice: {
        id: string;
    };
    referenceSaleFulfillment: {
        saleId: string;
    };
    completedByUser: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

const locationHistoryQuery = `
query ($productId: ID, $first: Int!, $after: String) {
    locationHistories(productId: $productId, first: $first, after: $after) {
      totalCount
      items {
        id
        date
        type
        oldQuantity
        newQuantity
        location {
          id
          name
        }
        referenceLocation {
          id
          name
          warehouseId
        }
        referencePurchaseInvoice {
          id
          purchaseOrderId
        }
        referenceSaleFulfillment {
          id
          saleId
        }
        completedByUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

interface IMovementQueryVariables {
    first: number;
    after: string | null;
    productId: string;
}

const ProductMovements = () => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const graphQLContext = React.useContext(GraphQLContext);
    const { id: productId } = useParams<{ id: string }>();

    useEffect(() => {
        if (gridApi) {
            gridApi.showLoadingOverlay();
            const datasource: IDatasource = {
                getRows(params) {
                    const after = params.startRow === 0 ? null : (params.startRow - 1).toString();
                    graphQLContext.client
                        .ExecuteQueryRaw<IMovementQueryResult, IMovementQueryVariables>({
                            query: locationHistoryQuery,
                            variables: {
                                after: after,
                                first: 100,
                                productId: productId,
                            },
                        })
                        .result.then(
                            (response) => {
                                gridApi.hideOverlay();
                                if ((response.errors ?? []).length > 1) {
                                    console.log("Error fetching data: 1", response.errors);
                                    return;
                                }
                                params.successCallback(
                                    response.data?.locationHistories?.items ?? [],
                                    response.data?.locationHistories?.totalCount ?? 0
                                );
                            },
                            (error) => {
                                gridApi.hideOverlay();
                                params.failCallback();
                                console.log("Error fetching data: 2", error);
                            }
                        );
                },
            };
            gridApi.setDatasource(datasource);
        }
    }, [gridApi, graphQLContext.client, productId]);

    //AG Grid column structure
    const columns = [
        {
            field: "date",
            headerName: "Date",
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <>
                        {StringHelper.IsNullOrWhitespace(params?.data?.date)
                            ? null
                            : FormatDate.ConvertDateToLocalDateMMDDYYYY(params?.data?.date)}
                    </>
                );
            },
        },
        {
            field: "type",
            headerName: "Type",
            cellRenderer: (params: ICellRendererParams) => {
                let type = "";
                switch (params?.data?.type) {
                    case "MOVE_TO_SALE_FULFILLMENT":
                        type = "Move To Sale Fulfillment";
                        break;
                    case "MOVE_FROM_PURCHASE_INVOICE":
                        type = "Move From Purchase Invoice";
                        break;
                    case "MOVE_PRODUCT_TO_BIN":
                        type = "Move Product To Bin";
                        break;
                    case "BIN_ADJUSTMENT":
                        type = "Bin Adjustment";
                        break;
                    case "STOCK_TAKE":
                        type = "Stock Take";
                        break;
                }
                return <>{type}</>;
            },
            flex: 2,
        },
        {
            field: "location",
            headerName: "Location",
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                return <>{params?.data?.location?.name} </>;
            },
        },
        {
            field: "oldQuantity",
            headerName: "Old Quantity",

            flex: 1,
        },
        {
            field: "newQuantity",
            headerName: "New Quantity",
            flex: 1,
        },
        {
            field: "reference",
            headerName: "Reference",
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data) {
                    let type = "";
                    let link = "";
                    switch (params?.data?.type) {
                        ///     SO-4
                        case "MOVE_TO_SALE_FULFILLMENT":
                            type = `SO-${params.data.referenceSaleFulfillment.saleId}`;
                            link = `/sales/${params.data.referenceSaleFulfillment.saleId}`;
                            break;
                        //    PO-4
                        case "MOVE_FROM_PURCHASE_INVOICE":
                            type = `ZPO-${params.data.referencePurchaseInvoice.id}`;
                            link = `/purchaseorders/${params.data.referencePurchaseInvoice.purchaseOrderId}/invoices/${params.data.referencePurchaseInvoice.id}`;
                            break;
                        //     location
                        case "MOVE_PRODUCT_TO_BIN":
                            type = `${params.data.referenceLocation?.name ?? ""}`;
                            link = `/warehouses/${params.data.referenceLocation?.warehouseId}/locations/`;
                            break;
                        case "BIN_ADJUSTMENT":
                            type = "";
                            link = "s";
                            break;
                        case "STOCK_TAKE":
                            type = "";
                            link = "";
                            break;
                    }
                    return <Link to={link}>{type}</Link>;
                } else {
                    return <></>;
                }
            },
        },
        {
            field: "completedBy",
            flex: 1,
            headerName: "Completed By",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <>
                        {params?.data?.completedByUser?.firstName} {params?.data?.completedByUser?.lastName}
                    </>
                );
            },
        },
    ];
    return (
        <>
            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: function (params: ICellRendererParams) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                            }
                        },
                    }}
                    onGridReady={(params) => setGridApi(params.api)}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={10}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                ></AgGridReact>
            </div>
        </>
    );
};

export default ProductMovements;
