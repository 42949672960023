import React from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../../components/loading/Loading";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";

interface IPickZoneQueryResult {
    pickZone: IPickZoneQueryResultData;
}

interface IPickEquipmentsQueryResult {
    pickEquipments: IQueryResultItems;
}

interface IPickFiltersQueryResult {
    pickFilters: IQueryResultItems;
}

interface IQueryResultItems {
    items: Array<{
        id: string;
        name: string;
    }>;
}

interface IPickZoneQueryResultData {
    warehouseId: string;
    name: string;
    active: boolean;
    pickable: boolean;
    isCart: boolean;
    priority: number;
    pickFilters: Array<IPickZoneMapping>;
}

interface IPickZoneMapping {
    pickFilterId: string;
    pickEquipmentId: string;
}

interface IPickZoneModel {
    id: string;
    warehouseId: string;
    active: boolean;
    name: string;
    isCart: boolean;
    pickable: boolean;
    priority: number;
    pickFilters: Array<IPickZoneMapping>;
}

interface IMutationResult {
    id: string;
}

interface IMutationVariables {
    original: IPickZoneModel;
    modified: IPickZoneModel;
}

interface IModal {
    show: boolean;
    original?: IPickZoneMapping;
}

const hiddenModal: IModal = {
    show: false,
};

const PickZoneQuery = `
query ($id: ID!) {
  pickZone(id: $id) {
    warehouseId
    name
    active
    pickable
    isCart
    priority
    pickFilters{
      pickFilterId
      pickEquipmentId
    }
  }
}
`;

const PickFiltersQuery = `
query {
  pickFilters {
    items {
      id
      name
    }
  }
}
`;

const PickEquipmentsQuery = `
query {
  pickEquipments {
    items {
      id
      name
    }
  }
}
`;

const PickFilterMappingMutation = `
mutation ($original: PickZoneInput!, $modified: PickZoneInput!) {
  pickZone {
    edit(original: $original, modified: $modified) {
      id
    }
  }
}
`;

const PickFilterMappings = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { pickZoneId } = useParams<{ pickZoneId: string }>();

    const {
        data: pickZoneData,
        error: pickZoneError,
        refetch: pickZoneRefetch,
    } = useQuery<IPickZoneQueryResult, { id: string }>(PickZoneQuery, { variables: { id: pickZoneId }, fetchPolicy: "no-cache" });
    const {
        data: pickEquipmentsData,
        error: pickEquipmentsError,
        refetch: pickEquipmentsRefetch,
    } = useQuery<IPickEquipmentsQueryResult>(PickEquipmentsQuery, { fetchPolicy: "no-cache" });
    const {
        data: pickFiltersData,
        error: pickFiltersError,
        refetch: pickFiltersRefetch,
    } = useQuery<IPickFiltersQueryResult>(PickFiltersQuery, { fetchPolicy: "no-cache" });
    //mutation to add, edit, or delete pick filters mapped to this pick zone
    const [runMutation] = useMutation<IMutationResult, IMutationVariables>(PickFilterMappingMutation);

    if (pickZoneError) return <ErrorDisplay onClick={pickZoneRefetch}>{pickZoneError.message}</ErrorDisplay>;
    if (pickEquipmentsError) return <ErrorDisplay onClick={pickEquipmentsRefetch}>{pickEquipmentsError.message}</ErrorDisplay>;
    if (pickFiltersError) return <ErrorDisplay onClick={pickFiltersRefetch}>{pickFiltersError.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!pickEquipmentsData || !pickFiltersData || !pickZoneData) return <Loading />;

    //=== set up local functions ===
    //run when the edit button is pressed
    const onShowEditModal = (value: IPickZoneMapping) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const originalModel: IPickZoneMapping = {
        pickFilterId: modal.original?.pickFilterId ?? "0",
        pickEquipmentId: modal.original?.pickEquipmentId ?? "0",
    };

    const onSaveChanges = (modified: IPickZoneMapping) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);

            runMutation({
                variables: {
                    original: {
                        id: pickZoneId,
                        warehouseId: pickZoneData.pickZone.warehouseId,
                        active: pickZoneData.pickZone.active,
                        name: pickZoneData.pickZone.name,
                        isCart: pickZoneData.pickZone.isCart,
                        pickable: pickZoneData.pickZone.pickable,
                        priority: pickZoneData.pickZone.priority,
                        pickFilters: pickZoneData.pickZone.pickFilters,
                    },
                    modified: {
                        id: pickZoneId,
                        warehouseId: pickZoneData.pickZone.warehouseId,
                        active: pickZoneData.pickZone.active,
                        name: pickZoneData.pickZone.name,
                        isCart: pickZoneData.pickZone.isCart,
                        pickable: pickZoneData.pickZone.pickable,
                        priority: pickZoneData.pickZone.priority,
                        pickFilters: pickZoneData.pickZone.pickFilters.concat(modified),
                    },
                },
            }).then(
                // success
                (ret) => {
                    setSaving(false);
                    onHideModal();
                    pickZoneRefetch();
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding pick filter mapping to pick zone", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            setSaving(true);

            runMutation({
                variables: {
                    original: {
                        id: pickZoneId,
                        warehouseId: pickZoneData.pickZone.warehouseId,
                        active: pickZoneData.pickZone.active,
                        name: pickZoneData.pickZone.name,
                        isCart: pickZoneData.pickZone.isCart,
                        pickable: pickZoneData.pickZone.pickable,
                        priority: pickZoneData.pickZone.priority,
                        pickFilters: pickZoneData.pickZone.pickFilters,
                    },
                    modified: {
                        id: pickZoneId,
                        warehouseId: pickZoneData.pickZone.warehouseId,
                        active: pickZoneData.pickZone.active,
                        name: pickZoneData.pickZone.name,
                        isCart: pickZoneData.pickZone.isCart,
                        pickable: pickZoneData.pickZone.pickable,
                        priority: pickZoneData.pickZone.priority,
                        pickFilters: pickZoneData.pickZone.pickFilters
                            .filter(
                                (element, index) =>
                                    index !== pickZoneData.pickZone.pickFilters.findIndex((x) => x.pickFilterId === modified.pickFilterId)
                            )
                            .concat(modified),
                    },
                },
            }).then(
                // success
                (ret) => {
                    setSaving(false);
                    onHideModal();
                    pickZoneRefetch();
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing pick filter mapping", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        const pickFilterId = modal.original?.pickFilterId;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Pick Filter Mapping?")) return;
        //disable form controls
        setSaving(true);
        runMutation({
            variables: {
                original: {
                    id: pickZoneId,
                    warehouseId: pickZoneData.pickZone.warehouseId,
                    active: pickZoneData.pickZone.active,
                    name: pickZoneData.pickZone.name,
                    isCart: pickZoneData.pickZone.isCart,
                    pickable: pickZoneData.pickZone.pickable,
                    priority: pickZoneData.pickZone.priority,
                    pickFilters: pickZoneData.pickZone.pickFilters,
                },
                modified: {
                    id: pickZoneId,
                    warehouseId: pickZoneData.pickZone.warehouseId,
                    active: pickZoneData.pickZone.active,
                    name: pickZoneData.pickZone.name,
                    isCart: pickZoneData.pickZone.isCart,
                    pickable: pickZoneData.pickZone.pickable,
                    priority: pickZoneData.pickZone.priority,
                    pickFilters: pickZoneData.pickZone.pickFilters.filter((element, index) => element.pickFilterId !== pickFilterId),
                },
            },
        }).then(
            () => {
                setSaving(false);
                onHideModal();
                pickZoneRefetch();
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Pick Filter Mapping", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new pick filter mapping
                </Button>
            </p>

            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Pick Filter Mappings</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Pick Filter Name</th>
                                <th>Equipment Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pickZoneData!.pickZone!.pickFilters!.map((pickFilterPickZoneMapping) => (
                                <tr key={pickFilterPickZoneMapping.pickFilterId}>
                                    <td>
                                        {
                                            pickFiltersData.pickFilters.items.find((x) => x.id === pickFilterPickZoneMapping.pickFilterId)
                                                ?.name
                                        }
                                    </td>
                                    <td>
                                        {
                                            pickEquipmentsData.pickEquipments.items.find(
                                                (x) => x.id === pickFilterPickZoneMapping.pickEquipmentId
                                            )?.name
                                        }
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditModal(pickFilterPickZoneMapping)}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.pickFilterId}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Pick Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <VGroup className="mb-4" fieldName="pickFilterId" hidden={modal.original ? true : false}>
                                    <VLabel>Pick Filter</VLabel>
                                    <VSelect required={modal.original ? false : true}>
                                        <option value=""></option>
                                        {pickFiltersData.pickFilters.items.map((pickFilter) => {
                                            if (
                                                !pickZoneData.pickZone.pickFilters.some((element) => element.pickFilterId === pickFilter.id)
                                            ) {
                                                return <option value={pickFilter.id}>{pickFilter.name}</option>;
                                            }
                                            return null;
                                        })}
                                    </VSelect>
                                </VGroup>
                                <VGroup className="mb-4" fieldName="pickEquipmentId">
                                    <VLabel>Pick Equipment</VLabel>
                                    <VSelect required>
                                        <option value="" hidden={modal.original ? true : false}></option>
                                        {pickEquipmentsData.pickEquipments.items.map((pickEquipment) => (
                                            <option value={pickEquipment.id}>{pickEquipment.name}</option>
                                        ))}
                                    </VSelect>
                                </VGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default PickFilterMappings;
