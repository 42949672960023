import React from "react";
import { GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { GraphQLContext } from "@shane32/graphql";
import StringHelper from "../helpers/StringHelper";
import FormatDate from "../helpers/FormatDate";

interface IActivityLogQueryResult {
    activityLogs: {
        totalCount: number;
        items: Array<IActivityLog>;
    };
}
interface IActivityLog {
    id: string;
    rowId: string;
    dateTime: string;
    changeDescription: string;
    tableName: string;
    user: {
        firstName: string | null;
        lastName: string | null;
        emailAddress: string | null;
    } | null;
}
const activityLogsQuery = `
    
query($table: TableEnum!,$rowId: ID) {
    activityLogs(table: $table,rowId: $rowId)  {
        totalCount
        items {
            id
            rowId
            dateTime
            changeDescription
            tableName
            user {
                firstName
                lastName
                emailAddress
            }
        }
    }
}
`;

interface IActivityLogQueryVariables {
    table: string;
    rowId: string;
    after: string | null;
}

const ActivityLog = (tableName: string, rowId: string) => {
    const graphQLContext = React.useContext(GraphQLContext);
    const onGridReady = (params: GridReadyEvent) => {
        let dataSource: IDatasource = {
            getRows: (params: IGetRowsParams) => {
                let after = params.startRow === 0 ? null : params.startRow.toString();
                let ret = graphQLContext.client.ExecuteQueryRaw<IActivityLogQueryResult, IActivityLogQueryVariables>({
                    query: activityLogsQuery,
                    variables: {
                        table: tableName,
                        rowId: rowId,
                        after: after,
                    },
                });

                ret.result.then(
                    //success
                    (result) => {
                        //failure
                        if (result.errors) {
                            console.log("Error fetching data: 1", result.errors);
                            params.failCallback();
                            return;
                        }
                        //feed data into AG Grid
                        params.successCallback(result.data!.activityLogs.items, result.data!.activityLogs.totalCount);
                    },
                    //failure
                    (result) => {
                        params.failCallback();
                        console.log("Error fetching data: 2", result);
                    }
                );
            },
        };
        params.api.setDatasource(dataSource);
    };

    const columns = [
        {
            field: "user",
            headerName: "Username",
            cellRenderer: (params: ICellRendererParams) => {
                const email = params?.data?.user?.emailAddress || "";
                const firstName = params?.data?.user?.firstName || "";
                const lastName = params?.data?.user?.lastName || "";
                const name = `${firstName} ${lastName}`;
                const display = StringHelper.IsNullOrWhitespace(name) ? email : name;
                return <>{display}</>;
            },
            flex: 1,
        },
        {
            field: "dateTime",
            headerName: "Date",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <>
                        {StringHelper.IsNullOrWhitespace(params?.data?.dateTime)
                            ? null
                            : FormatDate.ConvertDateTimeTo12HrLocalDateTime(params!.data!.dateTime)}
                    </>
                );
            },

            flex: 1,
        },
        {
            field: "changeDescription",
            flex: 3,
        },
    ];

    return (
        <>
            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: function (params: ICellRendererParams) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                            }
                        },
                    }}
                    onGridReady={onGridReady}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                ></AgGridReact>
            </div>
        </>
    );
};

export default ActivityLog;
